import { Tooltip, Typography } from '@mui/material';
import FileSaver from 'file-saver';
import { getAllListData } from 'services/CommonServices';
import Pdf from 'assets/png/pngimg.png';

const PdfExport = ({ url, setLoader }: any) => {
  function base64ToArrayBuffer(base64: string): ArrayBuffer {
    const binaryString = atob(base64);
    const length = binaryString.length;
    const bytes = new Uint8Array(length);
    for (let i = 0; i < length; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes.buffer;
  }
  const exportPng = () => {
    setLoader(true);
    getAllListData(url, true)
      .then((res) => {
        const arrayBuffer = base64ToArrayBuffer(res);
        const blob = new Blob([arrayBuffer], { type: 'application/pdf' }); // Create a Blob
        FileSaver.saveAs(blob);
        setLoader(false);
      })
      .catch((err) => {
        console.error(err);
        setLoader(false);
      });
  };
  return (
    <Typography onClick={exportPng} className='pointer' style={{ cursor: 'pointer' }}>
      <Tooltip title='Export to Pdf'>
        <img src={Pdf} alt={'Export to Pdf'} style={{ width: '30px', marginLeft: '20px' }} />
      </Tooltip>
    </Typography>
  );
};

export default PdfExport;
