import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  LabelList,
} from 'recharts';

// Define types for props
interface DataPoint {
  name: string; // The name of the month or label
  value: number; // The percentage value
}

interface AverageLine {
  value: number; // The Y-axis value for the average line
  label: string; // The label to display for the average line
}

interface LineChartProps {
  data: DataPoint[]; // Array of data points
  averageLine?: AverageLine; // Optional average line
  height?: number; // Optional height of the chart
  margin?: { top: number; right: number; left: number; bottom: number }; // Optional margin
}

// Custom Tooltip for Percentages
const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div style={{ backgroundColor: '#fff', padding: 10, border: '1px solid #ccc' }}>
        <p style={{ margin: 0 }}>{`${payload[0].payload.name}: ${payload[0].value}%`}</p>
      </div>
    );
  }
  return null;
};

const ProductivityGraph: React.FC<LineChartProps> = ({
  data,
  averageLine,
  height = 300,
  margin = { top: 20, right: 30, left: 20, bottom: 5 },
}) => {
  // Dynamically calculate Y-axis domain
  const minValue = Math.min(...data.map((item) => item.value), averageLine?.value || 0);
  const maxValue = Math.max(...data.map((item) => item.value), averageLine?.value || 100);

  return (
    <div style={{ width: '95%', height: height + 50 }}>
      <ResponsiveContainer width='100%' height={height}>
        <LineChart data={data} margin={margin}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis
            domain={[Math.min(0, minValue), Math.max(100, maxValue)]}
            tickFormatter={(value) => `${value}%`}
          />
          <Tooltip content={<CustomTooltip />} />
          <Line
            type='monotone'
            dataKey='value'
            stroke='#8884d8'
            strokeWidth={3}
            dot={{ r: 5, fill: '#8884d8' }}
            activeDot={{ r: 8 }}>
            {/* Add labels above each data point */}
            <LabelList dataKey='value' position='top' formatter={(val: number) => `${val}%`} />
          </Line>
          {averageLine && (
            <ReferenceLine
              y={averageLine.value}
              stroke='#00C49F'
              strokeDasharray='4 4'
              label={{
                value: averageLine.label,
                position: 'right',
                fill: '#00C49F',
                fontWeight: 'bold',
              }}
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default ProductivityGraph;
