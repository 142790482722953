import { Grid, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
// import AddIcon from 'assets/iconcomponents/addicon';
// import Deleteimgicon from 'assets/png/Deleteblack.svg';
import { Controller } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { getAllListData } from 'services/CommonServices';
import { SETTING_ENDPOINT } from 'utils/Constant';
import Add from 'assets/png/AddEducation.png';
import Delete from 'assets/png/DeleteExp.png';

export const EducationDetails = ({
  fields,
  append,
  remove,
  //   replace,
  control,
  setValue,
  //   watch,
  getValues,
  errors,
  //   trigger,
  setRemoveId,
  removeId,
}: any) => {
  const [reload, setreload] = useState<any>(false);
  const [degree, setDegree] = useState<any>([]);
  const [specialization, setSpecialization] = useState<any>([]);
  const [qualification, setQualification] = useState([]);

  const removeSpecialization = (index: number, id: string) => {
    if (id) {
      removeId.push(id);
      setRemoveId([...removeId]);
    }
    remove(index);
  };
  useEffect(() => {
    getAllListData(SETTING_ENDPOINT.Qualification)
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({ label: e.name, value: e.id, qualificationData: e.qualification });
          });
        setQualification(options);
      })
      .catch((e) => {
        console.log(e);
      });
    getAllListData('qualification/degree')
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({
              label: e.degree,
              value: e.id,
              degreeData: e.qualificationsDegree,
              ...e,
            });
          });
        setDegree(options);
      })
      .catch((e: any) => {
        console.log(e);
      });
    getAllListData('qualification/specialization')
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({
              label: e.specialization,
              value: e.id,
              ...e,
            });
          });
        setSpecialization(options);
      })
      .catch((e: any) => {
        console.log(e);
      });
  }, []);

  return (
    <div className='mt-15'>
      {' '}
      {fields && fields.length >= 0
        ? fields.map((item: any, index: number) => {
            return (
              <Grid container columnSpacing={4.25} key={item.id}>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Qualification <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      name={`educationData.${index}.qualificationId`}
                      // name={'qualificationId'}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={qualification}
                            name={name}
                            isClearable
                            placeHolder='Qualification'
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                              setreload(!reload);
                              setValue(`educationData.${index}.degreeId`, '');
                              setValue(`educationData.${index}.specializationId`, '');
                              if (
                                [5, 6, 7].includes(
                                  Number(getValues(`educationData.${index}.qualificationId`)),
                                )
                              ) {
                                setValue(`educationData.${index}.modeOfEducation`, '');
                                setValue(`educationData.${index}.degreeId`, '');
                                setValue(`educationData.${index}.specializationId`, '');
                                setValue(`educationData.${index}.college`, '');
                                setValue(`educationData.${index}.university`, '');
                              } else if (
                                ![5, 6, 7].includes(
                                  Number(getValues(`educationData.${index}.qualificationId`)),
                                )
                              ) {
                                setValue(`educationData.${index}.school`, '');
                              }
                            }}
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.qualificationId?.message
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Marks / Grade</Typography>
                    <Controller
                      control={control}
                      name={`educationData.${index}.marks`}
                      // name={'markGrade'}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter marks / grade'
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.marks?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Mode of Education</Typography>
                    <Controller
                      control={control}
                      name={`educationData.${index}.modeOfEducation`}
                      // name={'modeOfEducation'}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={[
                              { label: 'Regular', value: 'regular' },
                              { label: 'Correspondence', value: 'correspondence' },
                            ]}
                            name={name}
                            placeHolder='Enter mode of education'
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                            }}
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.modeOfEducation?.message
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Degree</Typography>
                    <Controller
                      control={control}
                      // name={'degreeId'}
                      name={`educationData.${index}.degreeId`}
                      render={({ field: { onChange, value, name } }) => {
                        let selectData: any = [];
                        if (getValues('educationData')[index].qualificationId) {
                          selectData =
                            degree &&
                            degree?.filter(
                              (re: any) =>
                                re.qualificationId ==
                                getValues('educationData')[index].qualificationId,
                            );
                        }

                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={selectData}
                            name={name}
                            placeHolder='Degree'
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                              setValue(`educationData.${index}.specializationId`, '');
                              setreload(!reload);
                            }}
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.degreeId?.message
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Year of Passing <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      // name={'year'}
                      name={`educationData.${index}.year`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            type={'number'}
                            className='custom-input'
                            placeHolder={`Enter year of passing (e.g: ${new Date().getFullYear()})`}
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.year?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>Specialization</Typography>
                    <Controller
                      control={control}
                      // name={'specializationId'}
                      name={`educationData.${index}.specializationId`}
                      render={({ field: { onChange, value, name } }) => {
                        let selectData: any = [];
                        if (getValues('educationData')[index].degreeId) {
                          selectData =
                            specialization &&
                            specialization?.filter(
                              (re: any) =>
                                re?.degreeId == getValues('educationData')[index]?.degreeId,
                            );
                          // if (
                          //   findSpecial &&
                          //   findSpecial?.degreeData &&
                          //   findSpecial?.degreeData?.length
                          // ) {
                          //   selectData = specialization?.filter(
                          //     (es: any) =>
                          //       es.qualificationId ===
                          //       findSpecial?.degreeData[0]?.qualificationId,
                          //   );
                          // }
                        }
                        return (
                          <CustomSelect
                            labelKey={'label'}
                            valueKey={'value'}
                            options={selectData}
                            name={name}
                            placeHolder='Specialization'
                            value={value ? value : null}
                            onChange={(e) => {
                              onChange(e);
                              setreload(!reload);
                            }}
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.specializationId?.message
                            }
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Institute <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      // name={'college'}
                      name={`educationData.${index}.institute`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Institute'
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.institute?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>University</Typography>
                    <Controller
                      control={control}
                      name={`educationData.${index}.university`}
                      // name={'university'}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter University'
                            error={
                              errors?.educationData &&
                              errors?.educationData?.[index] &&
                              errors?.educationData?.[index]?.university?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  sx={{ display: 'flex', marginTop: '10px', marginBottom: '30px' }}>
                  <span
                    style={{
                      cursor: 'pointer',
                      marginRight: '24px',
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '14px',
                    }}
                    onClick={() => {
                      append({
                        qualificationId: '',
                        marks: '',
                        modeOfEducation: '',
                        year: '',
                        university: '',
                        degreeId: '',
                        specializationId: '',
                        college: '',
                        rowId: '',
                        school: '',
                      });
                    }}>
                    <img
                      src={Add}
                      alt='Add'
                      style={{
                        marginRight: '5px',
                        marginBottom: '2px',
                      }}
                    />
                    Add Education
                  </span>
                  {index !== 0 ? (
                    <div>
                      <span
                        style={{
                          cursor: 'pointer',
                          marginRight: '24px',
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '14px',
                        }}
                        onClick={() => {
                          removeSpecialization(index, item.rowId);
                        }}>
                        <img
                          src={Delete}
                          alt='delete'
                          style={{
                            marginRight: '5px',
                            marginBottom: '5px',
                          }}
                        />
                        Delete Education
                      </span>
                    </div>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            );
          })
        : ''}
    </div>
  );
};
