import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  NativeSelect,
  Radio,
  RadioGroup,
  TextField,
  styled,
  Typography,
  Modal,
} from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import EmailIcon from 'assets/png/email.svg';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import moment from 'moment';
import { SETTING_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { noticePeriod } from 'utils/Config';
import { DesignationAdd } from '../Add/ResumePersonal/designationAdd';
import CustomTagInput from 'components/Taginput/Taginput';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});
export const PersonalDetails = ({
  control,
  setValue,
  watch,
  getValues,
  errors,
  trigger,
  append,
}: any) => {
  const {
    userInfo: { organizationId: orgdy },
  }: any = useAppSelector((store: any) => store.userLogin);
  const [designation, setDesignation] = useState<any>();
  const [designationModelopen, setDesignationModelOpen] = useState(false);
  const [loader, setLoader] = useState<any>(false);
  const currentDate = moment().subtract(18, 'years').format('YYYY-MM-DD');
  const handleDesignationOpen = () => {
    setDesignationModelOpen(true);
  };
  const handleDesignationClose = () => {
    setValue('canditateCurrentDesignation', '');
    setDesignationModelOpen(false);
  };
  const designationList = () => {
    getAllListData(`${SETTING_ENDPOINT.designation}?organizationId=${orgdy}&type=candidate`, true)
      .then((res: any) => {
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setDesignation(options);
        setLoader(false);
      })
      .catch(() => {
        setLoader(false);
      });
  };
  React.useEffect(() => {
    designationList();
  }, []);
  return (
    <Grid container columnSpacing={4.25}>
      {loader && <Loader />}
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Position For <span>*</span>
          </Typography>

          <Controller
            control={control}
            defaultValue={null}
            name='positionFor'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  disabled
                  className='custom-input'
                  placeHolder='Position For'
                  error={errors.positionFor?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Candidate First Name <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='canditateFirstName'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  className='custom-input'
                  placeHolder='First Name'
                  error={errors.canditateFirstName?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Candidate Last Name <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='canditateLastName'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  className='custom-input'
                  placeHolder='Last Name'
                  error={errors.canditateLastName?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Email <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='canditateEmail'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  inputProps={{
                    startAdornment: (
                      <InputAdornment position='start' style={{ marginRight: '-6px' }}>
                        <img src={EmailIcon} alt='email' />
                      </InputAdornment>
                    ),
                  }}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  error={errors.canditateEmail?.message}
                  className='custom-input'
                  placeHolder='Enter Email'
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Phone No <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='formatteValue'
            render={({ field: { value } }) => {
              return (
                <CustomPhoneInput
                  placeholder='(000) 000-0000'
                  containerClass={errors.phoneNumber?.message ? 'phone-input-error' : ''}
                  inputClass={
                    errors.phoneNumber?.message
                      ? 'phone-input phone-input-no-border'
                      : 'phone-input'
                  }
                  initialCountry={'in'}
                  specialLabel={''}
                  searchClass={'search-custom-class'}
                  value={value}
                  enableSearch={true}
                  searchNotFound={'No Country Found'}
                  inputStyle={{
                    width: '100%',
                    padding: '9px 11px',
                    paddingLeft: '60px',
                    borderRadius: '6px',
                    color: value && String(value).length > 0 ? 'black' : '#bdbdbd',
                    fontSize: '16px !important',
                    fontFamily: 'Poppins-Regular',
                    boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                  }}
                  onChange={(value, data, event, formattedValue) => {
                    const { countryCode, dialCode } = data;
                    setValue('countryCode', dialCode);
                    setValue('countryCodeText', countryCode);
                    setValue('canditatePhoneNo', value);
                    setValue('formatteValue', formattedValue);
                    trigger('formatteValue');
                  }}
                  error={errors.formatteValue?.message ? errors.formatteValue?.message : ''}
                  inputProps={{ tabIndex: 11 }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Date of Birth <span>*</span>
          </Typography>
          <Controller
            control={control}
            name={'canditateDob'}
            render={({ field: { onChange, value, name } }) => {
              return (
                <>
                  <TextField
                    value={value}
                    name={name}
                    id='date'
                    type='date'
                    placeholder={'Select Date of Birth'}
                    className={`clientfilter-time-from-to ${
                      errors.canditateDob && errors.canditateDob.message
                        ? 'clientfilter-time-from-to-error'
                        : ''
                    }`}
                    InputProps={{
                      inputProps: {
                        max: currentDate,
                      },
                    }}
                    onChange={(e) => {
                      onChange(e);
                    }}
                  />
                  {errors && errors.canditateDob && errors?.canditateDob?.message && (
                    <div className='Custom-error '>{String(errors?.canditateDob?.message)}</div>
                  )}
                </>
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Current Designation <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='canditateCurrentDesignation'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  labelKey={'label'}
                  valueKey={'value'}
                  options={
                    designation?.length
                      ? [
                          {
                            label: '+ Add Designation',
                            value: '',
                            isDisabled: false,
                            isFixed: true,
                            isSelectable: false,
                            custmdwn: true,
                          },
                          ...designation,
                        ]
                      : [
                          {
                            label: '+ Add Designation',
                            value: '',
                            isDisabled: false,
                            isFixed: true,
                            isSelectable: false,
                            custmdwn: true,
                          },
                        ]
                  }
                  name={name}
                  value={value ? value : null}
                  custmfn={handleDesignationOpen}
                  onChange={(e) => {
                    onChange(e);
                  }}
                  placeHolder='Current Designation'
                  error={errors.canditateCurrentDesignation?.message}
                />

                // <CustomInput
                //   value={value}
                //   name={name}
                //   className='custom-input'
                //   placeHolder='Current Designation'
                //   error={errors.canditateCurrentDesignation?.message}
                //   onChange={(e) => {
                //     onChange(e);
                //   }}
                // />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Total Years of Exp <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={''}
            name='canditateTotalExperienceYear'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  // labelKey={'label'}
                  // valueKey={'value'}
                  // options={experienceYear}
                  className='custom-input'
                  type={'number'}
                  name={name}
                  value={value}
                  onChange={(e) => {
                    if ((value || value === 0) && e.target.value != 0) {
                      append({
                        companyName: '',
                        designation: '',
                        fromDate: '',
                        toDate: '',
                        location: '',
                        reportingTo: '',
                        specialization: '',
                        projectAndCertifications: '',
                        vitalInformation: '',
                        tillDate: false,
                      });
                    }
                    const vals = e.target.value.replace(/\D/g, ''); // Remove any non-digit characters
                    setValue('canditateTotalExperienceYear', e.target.value);
                    trigger('canditateTotalExperienceYear');
                    if (getValues('canditateTotalExperienceYear') === 0) {
                      setValue('canditateTotalExperienceMonth', '');
                      setValue('canditateAnnualCtc', '');
                      setValue('canditateExpectation', '');
                      setValue('canditateNoticePeriod', '');
                      setValue('isWorking', false);
                    }

                    onChange(vals);
                  }}
                  placeHolder='Select'
                  error={errors.canditateTotalExperienceYear?.message}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Month <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={''}
            name='canditateTotalExperienceMonth'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  // labelKey={'label'}
                  // valueKey={'value'}
                  type={'number'}
                  className='custom-input'
                  disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                  // options={experienceMonth}
                  name={name}
                  value={value}
                  onChange={(e) => {
                    const vals = e.target.value.replace(/\D/g, '');
                    onChange(vals);
                  }}
                  placeHolder='Month'
                  error={errors.canditateTotalExperienceMonth?.message}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Annual CTC <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={''}
            name='canditateAnnualCtc'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  className='custom-input'
                  name={name}
                  value={value}
                  type={'number'}
                  onChange={(e) => {
                    const vals = e.target.value.replace(/\D/g, '');
                    return onChange(vals);
                  }}
                  disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                  placeHolder='Annual CTC'
                  error={errors.canditateAnnualCtc?.message}
                  customComponent={
                    <Box
                      sx={{
                        minWidth: 120,
                        position: 'absolute',
                        right: '-40px',
                        transform: 'scale(0.9)',
                      }}>
                      <NativeSelect
                        // defaultValue={'inr'}
                        className='position-currency-option'
                        IconComponent={ExpandMoreRoundedIcon}
                        sx={{
                          border: 'none !important',
                          minHeight: '20px !important',
                          '&::before': { borderBottom: 'transparent !important' },
                          '& > select': {
                            minWidth: '40px !important',
                            border: 'none !important',
                            outline: 'none !important',
                            marginRight: '8px',
                          },
                        }}>
                        <option value={'inr'}>INR</option>
                        <option value={'usd'}>USD</option>
                        <option value={'eu'}>EU</option>
                      </NativeSelect>
                    </Box>
                  }
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Expected CTC <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={''}
            name='canditateExpectation'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  className='custom-input'
                  name={name}
                  value={value}
                  type={'number'}
                  onChange={(e) => {
                    const vals = e.target.value.replace(/\D/g, '');
                    return onChange(vals);
                  }}
                  disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                  placeHolder='Expected CTC'
                  error={errors.canditateExpectation?.message}
                  customComponent={
                    <Box
                      sx={{
                        minWidth: 120,
                        position: 'absolute',
                        right: '-40px',
                        transform: 'scale(0.9)',
                      }}>
                      <NativeSelect
                        // defaultValue={'inr'}
                        className='position-currency-option'
                        IconComponent={ExpandMoreRoundedIcon}
                        sx={{
                          border: 'none !important',
                          minHeight: '20px !important',
                          '&::before': { borderBottom: 'transparent !important' },
                          '& > select': {
                            minWidth: '40px !important',
                            border: 'none !important',
                            outline: 'none !important',
                            marginRight: '8px',
                          },
                        }}>
                        <option value={'inr'}>INR</option>
                        <option value={'usd'}>USD</option>
                        <option value={'eu'}>EU</option>
                      </NativeSelect>
                    </Box>
                  }
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Notice Period <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={''}
            name='canditateNoticePeriod'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomSelect
                  labelKey={'label'}
                  valueKey={'value'}
                  options={noticePeriod}
                  name={name}
                  value={value}
                  disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                  onChange={(e) => {
                    onChange(e);
                    trigger('canditateTotalExperienceYear');
                  }}
                  placeHolder='Select'
                  error={errors.canditateNoticePeriod?.message}
                />
              );
            }}
          />
          <div style={{ display: 'flex', marginTop: '5px' }}>
            <Controller
              control={control}
              name='isWorking'
              render={({ field: { onChange, value, name } }) => {
                return (
                  <Checkbox
                    className='custom-checkbox'
                    sx={{
                      '&:hover': { bgcolor: 'transparent' },
                    }}
                    checked={value}
                    disabled={watch('canditateTotalExperienceYear') == 0 ? true : false}
                    name={name}
                    value={value}
                    onChange={(e) => {
                      onChange(e);
                    }}
                    color='default'
                    checkedIcon={<CheckedIcon />}
                    icon={<CheckIcon />}
                  />
                );
              }}
            />
            <Typography component={'p'} sx={{ marginTop: '7px', marginLeft: '10px' }}>
              Presently Working
            </Typography>
          </div>
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Gender <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue=''
            name='canditateGender'
            render={({ field: { onChange, value, name } }) => {
              return (
                <RadioGroup
                  className={`custom-radio ${
                    errors.canditateGender && errors.canditateGender.message ? 'custom-radio' : ''
                  }`}
                  row
                  aria-labelledby=''
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}>
                  <FormControlLabel
                    value='male'
                    control={<Radio />}
                    label='Male'
                    checked={value === 'male'}
                  />
                  <FormControlLabel
                    value='female'
                    control={<Radio />}
                    label='Female'
                    checked={value === 'female'}
                  />
                </RadioGroup>
              );
            }}
          />{' '}
          {errors && errors.canditateGender && errors?.canditateGender?.message && (
            <div className='Custom-error '>{String(errors?.canditateGender?.message)}</div>
          )}
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>Marital Status</Typography>
          <Controller
            control={control}
            defaultValue=''
            name='canditateMaritalStatus'
            render={({ field: { onChange, value, name } }) => {
              return (
                <RadioGroup
                  className={`custom-radio ${
                    errors.canditateMaritalStatus && errors.canditateMaritalStatus.message
                      ? 'custom-radio'
                      : ''
                  }`}
                  row
                  aria-labelledby=''
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                  }}>
                  <FormControlLabel
                    value='single'
                    control={<Radio />}
                    label='Single'
                    checked={value === 'single'}
                  />
                  <FormControlLabel
                    value='married'
                    control={<Radio />}
                    label='Married'
                    checked={value === 'married'}
                  />
                  {/* <FormControlLabel
                  value='separated'
                  control={<Radio />}
                  label='Separated'
                  checked={value === 'separated'}
                /> */}
                </RadioGroup>
              );
            }}
          />
          {/* {errors && errors.canditateMaritalStatus && errors?.canditateMaritalStatus?.message && (
            <div className='Custom-error '>{String(errors?.canditateMaritalStatus?.message)}</div>
          )} */}
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Passport and VISA <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue=''
            name='passportAndVisa'
            render={({ field: { onChange, value, name } }) => {
              return (
                <RadioGroup
                  className={`custom-radio ${
                    errors.passportAndVisa && errors.passportAndVisa.message ? 'custom-radio' : ''
                  }`}
                  row
                  aria-labelledby=''
                  name={name}
                  value={value}
                  onChange={(e) => {
                    onChange(e);
                    if (watch('passportAndVisa') === 'false') {
                      trigger('passportNumber');
                    }
                  }}>
                  <FormControlLabel
                    value={'true'}
                    control={<Radio />}
                    label='Yes'
                    checked={value === 'true'}
                  />
                  <FormControlLabel
                    value={'false'}
                    control={<Radio />}
                    label='No'
                    checked={value === 'false'}
                  />
                </RadioGroup>
              );
            }}
          />
          {errors && errors.passportAndVisa && errors?.passportAndVisa?.message && (
            <div className='Custom-error '>{String(errors?.passportAndVisa?.message)}</div>
          )}
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Passport No{watch('passportAndVisa') === 'true' ? <span>*</span> : ''}
          </Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='passportNumber'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  className='custom-input'
                  placeHolder='Passport No'
                  disabled={watch('passportAndVisa') === 'true' ? false : true}
                  error={errors.passportNumber?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>VISA No</Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='visaNumber'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  className='custom-input'
                  disabled={watch('passportAndVisa') === 'true' ? false : true}
                  placeHolder='VISA No'
                  error={errors.visaNumber?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>
            Present Location <span>*</span>
          </Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='canditatePresentLocation'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  className='custom-input'
                  placeHolder='Present Location'
                  error={errors.canditatePresentLocation?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={8} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>Family (Dependents)</Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='canditateFamily'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  placeHolder='Family (Dependents)'
                  // isTextArea={true}
                  className='custom-input'
                  error={errors.canditateFamily?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={4} sm={12} xs={12} className='mt-15'>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>Native Location</Typography>
          <Controller
            control={control}
            defaultValue={null}
            name='canditateNative'
            render={({ field: { onChange, value, name } }) => {
              return (
                <CustomInput
                  value={value}
                  name={name}
                  className='custom-input'
                  placeHolder='Native Location'
                  error={errors.canditateNative?.message}
                  onChange={(e) => {
                    onChange(e);
                  }}
                />
              );
            }}
          />
        </Typography>
      </Grid>
      <Grid item md={8} sm={12} xs={12}>
        <Typography component={'div'} className='custom-field'>
          <Typography component={'p'}>Language</Typography>
          <Controller
            control={control}
            defaultValue={[]}
            name={'language'}
            render={({ field: { onChange, value, name } }) => {
              return (
                <>
                  <CustomTagInput
                    value={value}
                    name={name}
                    placeHolder={'Language'}
                    error={errors.language ? true : false}
                    onChange={(e: any) => {
                      onChange(e);
                    }}
                  />
                  <div className='errorposition'>
                    {errors.language ? errors?.language?.message : ''}
                  </div>
                </>
              );
            }}
          />
        </Typography>
      </Grid>
      {designationModelopen && (
        <Modal
          sx={{ zIndex: 12000 }}
          open={designationModelopen}
          onClose={handleDesignationClose}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <DesignationAdd
            handleDesignationClose={handleDesignationClose}
            designationList={designationList}
            setValue={setValue}
          />
        </Modal>
      )}
    </Grid>
  );
};
