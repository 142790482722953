/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Breadcrumbs,
  Divider,
  Grid,
  Link,
  TableContainer,
  TableHead,
  Typography,
  Modal,
  Box,
} from '@mui/material';
// import { AdminPrivateRoutes } from 'routes/routeConstans';
import ReportIcon from 'assets/iconcomponents/reporticon';
// import CustomBarChart from '../CommonGraph/sideBar';
import Fulllogo from 'assets/png/cthiring_new_logo.png';
import Chart from 'assets/png/chart .svg';
import Photo from 'assets/png/photo.svg';
import Pdf from 'assets/png/pngimg.png';
import { useDebounce } from 'hooks';
import { getAllListData } from 'services/CommonServices';
import { REPORT_ENDPOINT } from 'utils/Constant';
import StackedBarChart from '../Common/barGraph';
import { useAppSelector } from 'store/hooks';
import { ReportFilter } from '../Common/filter';
import CustomBarChart from '../Common/sideBar';
import moment from 'moment';
import zoomIn from 'assets/png/zoomIn.svg';
import zoomOut from 'assets/png/zoom-out.svg';
import domtoimage from 'dom-to-image';
import { saveAs as fileDownload } from 'file-saver';
import jsPDF from 'jspdf';
import Print from 'assets/png/print-blue.svg';
import CloseIcon from 'assets/png/close.svg';
import ExcelIcon from 'assets/iconcomponents/excelicon';
import Loader from 'components/loader';
import ProductivityGraph from '../Common/ProductivityGraph';
import TaktimeGraph from '../Common/taktimeGraph';
import PngIcon from 'assets/iconcomponents/pngIcon';
import PdfExport from 'assets/iconcomponents/pdfExport';

const Defaultparam = {
  fromDate: null,
  toDate: null,
  userId: null,
  branchId: null,
  type: null,
  report: null,
  order: null,
  skip: 0,
  sortby: null,
  status: null,
  take: 10,
  organizationId: null,
  createdBy: null,
};
export const BusinessConversion = () => {
  const { userInfo: userDetail }: any = useAppSelector((store: any) => {
    return store.userLogin;
  });
  const rangeToColor: any = {
    '0-10': 'blue',
    '10-30': 'red',
    '30-50': 'green',
    '50-100': 'purple',
    'above 100': 'yellow',
  };
  const [loader, setLoader] = useState<any>(false);
  const [previewOpen, setPreviewOpen] = useState<any>(false);
  const downloadRef0 = useRef<HTMLDivElement>(null);
  // const zoomRef: any = useRef<any>(null);
  const downloadRef1 = useRef<HTMLDivElement>(null);
  const [branchData, setBranchData] = useState<any>();
  const [graphTotal, setGraphTotal] = useState<any>();
  const [tableTotal, setTableTotal] = useState<any>();
  const [employeeData, setEmployeeData] = useState<any>();
  const [employeeName, setEmployeeName] = useState<any>([]);
  // const [zoomLevel, setZoomLevel] = useState(100);
  const [scale, setScale] = useState(1); // Initial zoom level
  const handleZoomIn = () => setScale((prevScale) => Math.min(prevScale + 0.1, 3)); // Max zoom level
  const handleZoomOut = () => setScale((prevScale) => Math.max(prevScale - 0.1, 0.5));
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [tableData, setTableData] = useState<any>();
  const [graphData, setGraphData] = useState<any>();
  const [roleData, setRoleData] = useState<any>();
  const [averageLine, setAverageLine] = React.useState<any>(null);
  const listdataapi = () => {
    const searcharr = [
      `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
      `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
      `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
      `${debouncedParams.type ? `type=${'employee'}` : null}`,
      `${debouncedParams.report ? `report=${debouncedParams.report}` : null}`,
      `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
      //   `${
      //     debouncedParams.skip
      //       ? `skip=${debouncedParams.skip}`
      //       : debouncedParams.take
      //         ? 'skip=0'
      //         : null
      //   }`,
      //   `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLoader(true);
    getAllListData(
      `${REPORT_ENDPOINT.businessConversion}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        setTableData(val.data);
        setGraphTotal(val.data.graphTotal);
        setTableTotal(val.data.tableTotal);
        const graph = val.data.graphData;
        const Data = graph.find((re: any) => re.name === 'overallAvg');
        const final = Data.data.map((item: { month: any; percentage: any }) => ({
          name: item.month,
          value: Number(item.percentage),
        }));
        setGraphData(final);
        setAverageLine(averageLine);
        setLoader(false);
      })
      .catch((err: any) => {
        console.log(err);
        setLoader(false);
      });
  };

  const branch = () => {
    getAllListData(`${REPORT_ENDPOINT.branch}`, true)
      .then((res: any) => {
        setLoader(false);
        const options: any = [];
        res.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setBranchData(options);
      })
      .catch((e) => {
        setLoader(false);
        console.log(e);
      });
  };

  const handleClose = () => {
    setPreviewOpen(false);
  };
  const PrintScreen = () => {
    window.print();
  };

  const handlePag = (sk: number, tk: number) => {
    setParams((prev: any) => ({
      ...prev,
      tableSkip: sk,
      tableTake: tk,
    }));
  };
  useEffect(() => {
    branch();
    listdataapi();
  }, [debouncedParams]);
  useEffect(() => {
    if (roleData) {
      setLoader(true);
      getAllListData(`${REPORT_ENDPOINT.employee}?reportRoles=${roleData}`, true)
        .then((res: any) => {
          const options: any = [];
          res.data.map((e: any) => {
            options.push({ label: e.name, value: e.id, fullName: e.nameWithRole });
          });
          if (roleData != 'recruiter') {
            options.push(
              roleData != 'recruiter' && res?.data?.length > 0
                ? { label: 'All', value: 'all', fullName: 'All' }
                : {},
            );
          }
          setEmployeeData(options);
          setLoader(false);
        })
        .catch((e) => {
          setLoader(false);
          console.log(e);
        });
    }
  }, [roleData]);
  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Typography color='text.primary'>
              <img src={ArrowIcon} alt='arrow' className='arrows' /> Reports
            </Typography>
            <Typography color='text.primary'>
              {' '}
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              Business Conversion
            </Typography>
          </Breadcrumbs>
        </Typography>
        <ReportFilter
          setParams={setParams}
          setRoleData={setRoleData}
          branchData={branchData}
          employeeData={employeeData}
          setEmployeeName={setEmployeeName}
          setPreviewOpen={setPreviewOpen}
          params={params}
          tableData={setTableData}
          graphData={setGraphData}
        />
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            {!tableData || !graphData ? (
              <ReportIcon />
            ) : (
              <>
                {params.type === 'employee' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              Employee Business Conversion
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <PngIcon
                              url={`reports/business-conversion/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=image',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                            <PdfExport
                              url={`reports/business-conversion/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=pdf',
                                'report=graph',
                                `names=${employeeName.map((item: any) =>
                                  item.replace(/^\(|\)$/g, ''),
                                )}`,
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <ProductivityGraph data={graphData} averageLine={averageLine} />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {params.type === 'client' &&
                  (params.report === 'graph' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='d-flex flex-sb'>
                          <img
                            src={Fulllogo}
                            alt='Carrertree'
                            style={{ width: '142px', height: '21.25px' }}
                          />
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              Client Business Conversion
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography
                            component={'span'}
                            className='page-title d-flex-cc'
                            style={{ marginRight: '25px' }}>
                            <img
                              src={Chart}
                              alt='Chart'
                              style={{
                                width: '25px',
                                height: '25px',
                                marginRight: '10px',
                                marginBottom: '10px',
                              }}
                            />
                            Graph & Table View
                            <img
                              src={Photo}
                              alt='Photo'
                              style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                            />
                            <img
                              src={Pdf}
                              alt='Pdf'
                              style={{ width: '25px', height: '25px', marginLeft: '25px' }}
                            />
                          </Typography>
                        </Typography>
                        <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                      </Grid>
                      <TaktimeGraph
                        data={graphData}
                        graphTotal={graphTotal}
                        setParams={setParams}
                      />
                      <Grid item md={12} sm={12} xs={12}>
                        <Divider
                          sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                        />
                      </Grid>
                    </>
                  )}
                {(params.report === 'table' || params.report === 'all') && (
                  <>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography
                        component={'div'}
                        className='d-flex flex-sb'
                        sx={{ marginTop: '25px' }}>
                        <Typography
                          component={'div'}
                          sx={{ display: 'flex', flexDirection: 'column' }}>
                          <Typography component={'span'} className='report-title'>
                            Employee Business Conversion
                          </Typography>
                          <Typography
                            component={'span'}
                            className='report-title-name d-flex-cc'
                            sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                            {employeeName}
                          </Typography>
                        </Typography>
                        <Typography component={'span'} className='report-title-name d-flex-cc'>
                          Timeline :{' '}
                          <Typography
                            component={'span'}
                            className='report-title'
                            sx={{ marginRight: '30px' }}>
                            {moment(params.fromDate).format('DD/MM/YYYY')} -
                            {moment(params.toDate).format('DD/MM/YYYY')}
                          </Typography>
                          <ExcelIcon
                            url={`reports/business-conversion/export${
                              Object.values({ ...params }).length > 0 && '?'
                            }${[
                              `${
                                debouncedParams.fromDate
                                  ? `fromDate=${debouncedParams.fromDate}`
                                  : null
                              }`,
                              `${
                                debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                              }`,
                              `${
                                debouncedParams.branchId
                                  ? `branchId=${debouncedParams.branchId}`
                                  : null
                              }`,
                              `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                              `${
                                debouncedParams.report ? `report=${debouncedParams.report}` : null
                              }`,
                              `${
                                debouncedParams.employee
                                  ? `userId=${debouncedParams.employee}`
                                  : null
                              }`,
                              'exportType=csv',
                              'type=table',
                            ]
                              .filter((ea: any) => ea !== 'null')
                              .join('&')}`}
                            fileName={'Business Conversion'}
                            setLoader={setLoader}
                          />
                        </Typography>
                      </Typography>
                    </Grid>
                    <Typography component={'div'} className='mt-32'>
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 650 }}
                          className='custom-table'
                          aria-label='simple table'>
                          <TableHead>
                            <TableRow>
                              {tableData?.tableHeaders?.map((header: any) => (
                                <TableCell sx={{ textTransform: 'capitalize' }} key={header}>
                                  {header === 'month' ? 'Name / Month' : header}
                                </TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData?.tableData && tableData?.tableData?.length ? (
                              <>
                                {/* Data Rows excluding avgProductivity */}
                                {tableData?.tableData
                                  ?.filter((row: any) => row.name !== 'overallAvg')
                                  ?.map((row: any, rowIndex: number) => (
                                    <TableRow key={row.name || `row-${rowIndex}`}>
                                      {/* Name Cell */}
                                      <TableCell>{row.name}</TableCell>

                                      {/* Data Cells */}
                                      {tableData.tableHeaders.slice(1).map((header: any) => {
                                        const cellData = row.data.find(
                                          (item: any) => item.month === header,
                                        );
                                        return (
                                          <TableCell key={`${rowIndex}-${header}`}>
                                            {cellData ? cellData.percentage : '-'}
                                          </TableCell>
                                        );
                                      })}
                                    </TableRow>
                                  ))}

                                {/* Avg Productivity Row */}
                                <TableRow>
                                  <TableCell>Avg Productivity</TableCell>
                                  {tableData.tableHeaders.slice(1).map((header: any) => {
                                    const avgRow = tableData.tableData.find(
                                      (row: any) => row.name === 'overallAvg',
                                    );
                                    const avgData = avgRow?.data.find(
                                      (item: any) => item.month === header,
                                    );
                                    return (
                                      <TableCell key={`avg-${header}`}>
                                        {avgData ? avgData.percentage : '-'}
                                      </TableCell>
                                    );
                                  })}
                                </TableRow>
                              </>
                            ) : (
                              <TableRow>
                                <TableCell colSpan={tableData?.tableHeaders?.length} align='center'>
                                  No Record(s) Found.
                                </TableCell>
                              </TableRow>
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Typography>
                  </>
                )}
              </>
            )}
          </Typography>
        </Typography>
      </Grid>
      <Modal
        sx={{ zIndex: 1300 }}
        open={previewOpen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          className='email-modal'
          sx={{
            width: '100% !important',
            minHeight: '100vh !important',
            padding: '0px !important',
          }}>
          <Typography
            className='d-flex-a flex-sb'
            sx={{ backgroundColor: '#F2F4F7', height: '53px', padding: '20px' }}>
            <Typography component={'span'} className='report-title'>
              {'Business Conversion'}
            </Typography>
            <Typography component={'div'} className='d-flex-cc'>
              <img
                src={zoomIn}
                alt='zoomIn'
                style={{ width: '35px', height: '35px', marginRight: '25px', cursor: 'pointer' }}
                onClick={handleZoomIn}
              />
              <div
                style={{
                  padding: '10px 20px',
                  backgroundColor: '#f0f0f0',
                  border: '1px solid #ddd',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}>
                {Math.round(scale * 100)}%
              </div>
              <img
                src={zoomOut}
                alt='zoomIn'
                style={{ width: '35px', height: '35px', marginLeft: '25px', cursor: 'pointer' }}
                onClick={handleZoomOut}
              />
            </Typography>
            <Typography component={'div'} className='d-flex'>
              <Typography component={'div'} className='d-flex'>
                <PngIcon
                  url={`reports/business-conversion/export${
                    Object.values({ ...params }).length > 0 && '?'
                  }${[
                    `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
                    `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
                    `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
                    `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                    `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                    `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
                    'exportType=image',
                    'report=graph',
                    `names=${employeeName.map((item: any) => item.replace(/^\(|\)$/g, ''))}`,
                  ]
                    .filter((ea: any) => ea !== 'null')
                    .join('&')}`}
                  setLoader={setLoader}
                />
                <PdfExport
                  url={`reports/business-conversion/export${
                    Object.values({ ...params }).length > 0 && '?'
                  }${[
                    `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
                    `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
                    `${debouncedParams.branchId ? `branchId=${debouncedParams.branchId}` : null}`,
                    `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                    `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
                    `${debouncedParams.employee ? `userId=${debouncedParams.employee}` : null}`,
                    'exportType=pdf',
                    'report=graph',
                    `names=${employeeName.map((item: any) => item.replace(/^\(|\)$/g, ''))}`,
                  ]
                    .filter((ea: any) => ea !== 'null')
                    .join('&')}`}
                  setLoader={setLoader}
                />
                <img
                  src={Print}
                  alt='Print'
                  style={{ width: '35px', height: '35px', marginRight: '25px', marginLeft: '20px' }}
                  onClick={PrintScreen}
                />
              </Typography>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleClose}
              />
            </Typography>
          </Typography>
          <Typography
            component={'div'}
            className='main-content'
            sx={{
              padding: '0px',
              overflow: 'auto',
              position: 'relative',
            }}>
            <Typography component={'div'} className='white-box'>
              {!tableData || !graphData ? (
                <ReportIcon />
              ) : (
                <>
                  {params.type === 'employee' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',

                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography
                              component={'div'}
                              sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography component={'span'} className='report-title'>
                                Employee Business Conversion
                              </Typography>
                              <Typography
                                component={'span'}
                                className='report-title-name d-flex-cc'
                                sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                                {employeeName}
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <ProductivityGraph data={graphData} averageLine={averageLine} />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {params.type === 'client' &&
                    (params.report === 'graph' || params.report === 'all') && (
                      <div
                        style={{
                          top: 0,
                          left: 0,
                          width: '100%',

                          overflow: 'auto',
                          margin: '0 auto',
                          transform: `scale(${scale})`,
                          transition: 'transform 0.3s ease',
                        }}>
                        <Grid item md={12} sm={12} xs={12}>
                          <Typography component={'div'} className='d-flex flex-sb'>
                            <img
                              src={Fulllogo}
                              alt='Carrertree'
                              style={{ width: '142px', height: '21.25px' }}
                            />
                            <Typography
                              component={'div'}
                              sx={{ display: 'flex', flexDirection: 'column' }}>
                              <Typography component={'span'} className='report-title'>
                                Client Business Conversion
                              </Typography>
                              <Typography
                                component={'span'}
                                className='report-title-name d-flex-cc'
                                sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                                {employeeName}
                              </Typography>
                            </Typography>
                            <Typography
                              component={'span'}
                              className='page-title d-flex-cc'
                              style={{ marginRight: '25px' }}>
                              <img
                                src={Chart}
                                alt='Chart'
                                style={{
                                  width: '25px',
                                  height: '25px',
                                  marginRight: '10px',
                                  marginBottom: '10px',
                                }}
                              />
                              Graph & Table View
                            </Typography>
                          </Typography>
                          <Divider sx={{ marginBottom: '30px', marginTop: '8px' }} />
                        </Grid>
                        <TaktimeGraph
                          data={graphData}
                          graphTotal={graphTotal}
                          setParams={setParams}
                        />
                        <Grid item md={12} sm={12} xs={12}>
                          <Divider
                            sx={{ borderBottomWidth: 5, marginBottom: '30px', marginTop: '30px' }}
                          />
                        </Grid>
                      </div>
                    )}
                  {(params.report === 'table' || params.report === 'all') && (
                    <>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography
                          component={'div'}
                          className='d-flex flex-sb'
                          sx={{ marginTop: '25px' }}>
                          <Typography
                            component={'div'}
                            sx={{ display: 'flex', flexDirection: 'column' }}>
                            <Typography component={'span'} className='report-title'>
                              Employee Business Conversion
                            </Typography>
                            <Typography
                              component={'span'}
                              className='report-title-name d-flex-cc'
                              sx={{ fontSize: '16px !important', marginTop: '10px' }}>
                              {employeeName}
                            </Typography>
                          </Typography>
                          <Typography component={'span'} className='report-title-name d-flex-cc'>
                            Timeline :{' '}
                            <Typography
                              component={'span'}
                              className='report-title'
                              sx={{ marginRight: '30px' }}>
                              {moment(params.fromDate).format('DD/MM/YYYY')} -
                              {moment(params.toDate).format('DD/MM/YYYY')}
                            </Typography>
                            <ExcelIcon
                              url={`reports/business-conversion/export${
                                Object.values({ ...params }).length > 0 && '?'
                              }${[
                                `${
                                  debouncedParams.fromDate
                                    ? `fromDate=${debouncedParams.fromDate}`
                                    : null
                                }`,
                                `${
                                  debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null
                                }`,
                                `${
                                  debouncedParams.branchId
                                    ? `branchId=${debouncedParams.branchId}`
                                    : null
                                }`,
                                `${debouncedParams.role ? `role=${debouncedParams.role}` : null}`,
                                `${
                                  debouncedParams.report ? `report=${debouncedParams.report}` : null
                                }`,
                                `${
                                  debouncedParams.employee
                                    ? `userId=${debouncedParams.employee}`
                                    : null
                                }`,
                                'exportType=csv',
                                'type=table',
                              ]
                                .filter((ea: any) => ea !== 'null')
                                .join('&')}`}
                              fileName={'Business Conversion'}
                              setLoader={setLoader}
                            />
                          </Typography>
                        </Typography>
                      </Grid>
                      <Typography component={'div'} className='mt-32'>
                        <TableContainer component={Paper}>
                          <Table
                            sx={{ minWidth: 650 }}
                            className='custom-table'
                            aria-label='simple table'>
                            <TableHead>
                              <TableRow>
                                {tableData?.tableHeaders?.map((header: any) => (
                                  <TableCell sx={{ textTransform: 'capitalize' }} key={header}>
                                    {header === 'month' ? 'Name / Month' : header}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {tableData?.tableData && tableData?.tableData?.length ? (
                                <>
                                  {/* Data Rows excluding avgProductivity */}
                                  {tableData?.tableData
                                    ?.filter((row: any) => row.name !== 'overallAvg')
                                    ?.map((row: any, rowIndex: number) => (
                                      <TableRow key={row.name || `row-${rowIndex}`}>
                                        {/* Name Cell */}
                                        <TableCell>{row.name}</TableCell>

                                        {/* Data Cells */}
                                        {tableData.tableHeaders.slice(1).map((header: any) => {
                                          const cellData = row.data.find(
                                            (item: any) => item.month === header,
                                          );
                                          return (
                                            <TableCell key={`${rowIndex}-${header}`}>
                                              {cellData ? cellData.percentage : '-'}
                                            </TableCell>
                                          );
                                        })}
                                      </TableRow>
                                    ))}

                                  {/* Avg Productivity Row */}
                                  <TableRow>
                                    <TableCell>Avg Productivity</TableCell>
                                    {tableData.tableHeaders.slice(1).map((header: any) => {
                                      const avgRow = tableData.tableData.find(
                                        (row: any) => row.name === 'overallAvg',
                                      );
                                      const avgData = avgRow?.data.find(
                                        (item: any) => item.month === header,
                                      );
                                      return (
                                        <TableCell key={`avg-${header}`}>
                                          {avgData ? avgData.percentage : '-'}
                                        </TableCell>
                                      );
                                    })}
                                  </TableRow>
                                </>
                              ) : (
                                <TableRow>
                                  <TableCell
                                    colSpan={tableData?.tableHeaders?.length}
                                    align='center'>
                                    No Record(s) Found.
                                  </TableCell>
                                </TableRow>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Typography>
                    </>
                  )}
                </>
              )}
            </Typography>
          </Typography>
        </Box>
      </Modal>
    </Grid>
  );
};
