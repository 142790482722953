import { Drawer } from '@mui/material';
import React, { useCallback, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { DashboardFilterSchema } from 'utils/ValidatorSchema';
import { DashboradFilterType } from 'types';
import { DashboardFilterForm } from './DashboardFilterForm';

interface ClientFilterDrawerProps {
  isOpen: boolean;
  closeDrawer: () => void;
  setParams?: any;
  setcommonstate?: any;
  commonState?: any;
  setLoading?: any;
  isLoading?: any;
  setpagetotal?: any;
  setVisible?: any;
  option?: any;
}

export const DashboardFilterDrawer: React.FC<ClientFilterDrawerProps> = ({
  isOpen,
  closeDrawer,
  setParams,
  // setcommonstate,
  // setLoading,
  // setpagetotal,
  // listdataapi,
  setVisible,
  option,
}) => {
  const [key, setKey] = useState(0);
  const { control, handleSubmit, reset, formState, watch, trigger } = useForm<DashboradFilterType>({
    criteriaMode: 'all',
    defaultValues: {
      fromDate: undefined,
      toDate: undefined,
      reportType: undefined,
      clientId: undefined,
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(DashboardFilterSchema),
  });

  const closeSideDrawer = useCallback(() => {
    closeDrawer();
  }, [closeDrawer]);

  const resetFilters = useCallback(() => {
    reset();
    setParams((prev: any) => ({
      ...prev,
      type: option.toLowerCase(),
      fromDate: '',
      toDate: '',
      reportType: '',
      clientId: '',
    }));
    setVisible(true);
    setKey((prev: number) => prev + 1);
  }, [reset]);

  const onSubmit = (data: any) => {
    if (data.fromDate || data.toDate || data.reportType || data.clientId) {
      setParams((prev: any) => ({
        ...prev,
        fromDate: data.fromDate ? data.fromDate : null,
        toDate: data.toDate ? data.toDate : null,
        reportType: data.reportType ? data.reportType : null,
        clientId: data.clientId ? data.clientId : null,
      }));
      setVisible(false);
    }
  };

  return (
    <Drawer
      anchor='right'
      open={isOpen}
      onClose={closeDrawer}
      sx={{
        flexShrink: 0,
        zIndex: 1200,
        ['& .MuiDrawer-paper']: {
          boxSizing: 'border-box',
          marginTop: '72px',
          width: '300px',
          padding: '30px',
          paddingTop: '0px',
        },
        ['& .MuiModal-backdrop ']: { backgroundColor: 'transparent' },
      }}>
      <header className='clientfilter-header'>
        <img
          src={CloseIcon}
          alt='closeicon'
          className='clientfilter-close-ico'
          onClick={closeSideDrawer}
        />
        <div className='heading'>Filters</div>
        <span
          className='clearall'
          style={{
            ...(!(watch('fromDate') || watch('toDate') || watch('clientId') || watch('reportType'))
              ? { opacity: '0.5', cursor: 'not-allowed' }
              : {}),
          }}
          onClick={() =>
            watch('fromDate') || watch('toDate') || watch('clientId') || watch('reportType')
              ? resetFilters()
              : ''
          }>
          Clear All
        </span>
      </header>
      <div className='clientfilter-divider'></div>
      <DashboardFilterForm
        setParams={setParams}
        key={key}
        control={control}
        handleSubmit={handleSubmit}
        formState={formState}
        onSubmit={onSubmit}
        watch={watch}
        trigger={trigger}
      />
    </Drawer>
  );
};
