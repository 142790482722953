import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid } from 'recharts';

// Custom label for each bar
const CustomLabel = (props: any) => {
  const { x, y, value, name, width } = props;

  return (
    <text
      x={x + width / 2}
      y={y - 10}
      dy={-4}
      textAnchor='middle'
      fill={'#000'}
      fontFamily='Arial'
      fontWeight='bold'>
      {`${value} - ${name}`}
    </text>
  );
};

// Custom bar component
const CustomBar = (props: any) => {
  const { x, y, width, height, color } = props;
  return (
    <g>
      <rect x={x} y={y} width={width} height={height} fill={color} />
    </g>
  );
};

function HorizontalBarChart({ DailyActivityCardData }: any) {
  const minHeight = 300; // Set a minimum height
  const chartHeight = Math.max(minHeight, 57 * DailyActivityCardData.length);

  return (
    <ResponsiveContainer width='100%' height={chartHeight}>
      <BarChart data={DailyActivityCardData} margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis type='category' dataKey='name' axisLine={true} tick={{ fill: '#D0D5DD' }} />
        <YAxis type='number' axisLine={true} tick={{ fill: '#D0D5DD' }} />
        <Tooltip />
        <Bar
          dataKey='value'
          fill='#B37FEB'
          barSize={Math.max(20, 26)} // Ensure bars are not too small
          shape={<CustomBar />}
          label={<CustomLabel />}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default HorizontalBarChart;
