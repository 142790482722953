/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
import { Badge, Typography } from '@mui/material';
import React, { useState } from 'react';
import Funnelimg from 'assets/png/FilterFunnel.svg';

import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, Area } from 'recharts';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { AccessDenied } from 'utils/helper';
import { toast } from 'react-toastify';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { getAllListData } from 'services/CommonServices';
import { useDebounce } from 'hooks';
import { CommomFilter } from './CommomFilter';

// const data = [
//   { name: 'Jan', value: 20000 },
//   { name: 'Feb', value: 30000 },
//   { name: 'Mar', value: 25000 },
//   { name: 'Apr', value: 45000 },
//   { name: 'May', value: 54650 },
// ];

const CustomTooltip = ({ active, payload }: any) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '5px',
          borderRadius: '5px',
          border: '1px solid #ccc',
        }}>
        <p style={{ color: '#FF5722', fontSize: '16px', fontWeight: 'bold' }}>
          ₹ {payload[0].value.toLocaleString()}
        </p>
      </div>
    );
  }
  return null;
};

const FinacialData = ({ setLd, option }: any) => {
  const [visible, setVisible] = useState(true);
  const Today = new Date();
  const getFirstDateThreeMonthsAgo = () => {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 6);
    return currentDate.toISOString().split('T')[0];
  };
  const Defaultparam = {
    from: getFirstDateThreeMonthsAgo(),
    to: moment(Today).format('YYYY-MM-DD'),
    type: option.toLowerCase(),
  };
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const debouncedParams = useDebounce(params, 500);
  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  const [financialData, setFinancialData] = useState<any>([]);
  const [financialAmount, setFinancialAmount] = useState<any>('');
  const navigate = useNavigate();

  const FinancialData = () => {
    const searcharr = [
      `${debouncedParams.type ? `type=${debouncedParams.type}` : null}`,
      `${debouncedParams.from ? `fromDate=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `toDate=${debouncedParams.to}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');

    setLd(true);
    getAllListData(
      `${DASHBOARD_ENDPOINT.CalculationTable}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}`,
      true,
    )
      .then((val: any) => {
        setFinancialAmount(val.data[0].billingValue || 0);
        const combinedData = val.data.map(
          (
            item: {
              billingValue: number;
              month: { split: (arg0: string) => [any, any] };
            },
            index: any,
          ) => {
            const [year, month] = item.month.split('-');
            const date = new Date(Number(year), Number(month) - 1);
            const formattedMonth = new Intl.DateTimeFormat('en-US', {
              month: 'short',
            }).format(date);

            return {
              month: formattedMonth,
              billingValue: item.billingValue || 0, // Set 0 if there's no corresponding value in `data`
            };
          },
        );
        setFinancialData(combinedData);
        dwerclose();
        setLd(false);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        dwerclose();
        setLd(false);
      });
  };
  React.useEffect(() => {
    FinancialData();
  }, [option, debouncedParams]);
  return (
    <div className='childcon2'>
      <header>
        <div className='title'>Your Financial Details</div>
        <Typography
          component={'div'}
          className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
          onClick={dweropn}>
          <Badge color='error' variant='dot' invisible={visible}>
            <img src={Funnelimg} alt='filter funnel icon' />
          </Badge>
        </Typography>
      </header>
      <header style={{ display: 'flex', flexDirection: 'column', padding: '30px 10px 30px 10px' }}>
        <div className='title' style={{ fontSize: '35px' }}>
          {' '}
          {`₹ ${Math.floor(financialAmount).toLocaleString('en-US', {
            maximumFractionDigits: 0,
          })}`}
        </div>
        <div
          style={{
            fontStyle: 'normal',
            fontWeight: '500',
            fontSize: '12px',
            color: 'rgb(128, 128, 128)',
            marginTop: '5px',
          }}>
          Your Financial Details
        </div>
      </header>
      <main>
        <div
          className='donutchartout'
          style={{
            display: 'flex',
            justifyContent: 'center',
            marginLeft: '50px',
            height: '300px',
          }}>
          <ResponsiveContainer width='100%' height={400}>
            <LineChart
              data={financialData}
              style={{ position: 'absolute' }}
              margin={{ top: 20, right: 30, left: 20, bottom: 20 }}>
              <XAxis dataKey='month' />
              <YAxis hide domain={[0, (dataMax: number) => dataMax + dataMax * 0.1]} />
              <Tooltip content={<CustomTooltip />} />
              <Area type='monotone' dataKey='billingValue' fill='rgba(255, 87, 34, 0.2)' />
              <Line
                type='monotone'
                dataKey='billingValue'
                stroke='#FF5722'
                strokeWidth={2}
                dot={{ stroke: '#FF5722', strokeWidth: 3, r: 5 }}
                activeDot={{ r: 8 }}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>
      </main>
      {dweropen && (
        <CommomFilter
          handleOpen={dweropen}
          handleClose={dwerclose}
          setParams={setParams}
          params={params}
          getApi={FinancialData}
          setVisible={setVisible}
          type={'financial'}
        />
      )}
    </div>
  );
};

export default FinacialData;
