import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

// Custom Legend Component
const CustomLegend = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {[
        { color: '#82ca9d', label: 'CV Sent Shortlisted %' },
        { color: '#8884d8', label: 'CV Sent Interviewed %' },
        { color: '#673147', label: 'Opening Worked to Billed %' },
      ].map((item) => (
        <div
          key={item.label}
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: 20,
            borderRadius: '5px',
            padding: '10px',
            backgroundColor: '#F2F2F7',
          }}>
          <div
            style={{
              width: 12,
              height: 12,
              backgroundColor: item.color,
              marginRight: 5,
              borderRadius: '20%',
            }}
          />
          <span style={{ color: '#000', fontWeight: 'bold', marginRight: 5 }}>{item.label}</span>
        </div>
      ))}
    </div>
  );
};

// Props for ReusableLineChart
interface LineChartProps {
  data: any[];
  xAxisKey?: string;
  lineKeys: { key: string; color: string; label: string }[]; // Added 'label'
  height?: number;
  margin?: { top: number; right: number; left: number; bottom: number };
}

const ReusableLineChart: React.FC<LineChartProps> = ({
  data,
  lineKeys,
  height = 300,
  margin = { top: 20, right: 30, left: 20, bottom: 5 },
}) => {
  return (
    <div style={{ width: '100%', height: height + 150 }}>
      <ResponsiveContainer width='100%' height={height}>
        <LineChart data={data} margin={margin}>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis
            label={{
              value: '% against CVs Sent',
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
            }}
          />
          <Tooltip />
          {lineKeys.map(({ key, color, label }) => (
            <Line
              key={key}
              dataKey={key}
              stroke={color}
              name={label} // This changes the tooltip label
            />
          ))}
        </LineChart>
      </ResponsiveContainer>
      <div style={{ display: 'flex', marginTop: '50px', justifyContent: 'center' }}>
        <CustomLegend />
      </div>
    </div>
  );
};

export default ReusableLineChart;
