import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import Paginate from 'components/Pagination/Pagination';

const CustomLegend = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        padding: '10px 0',
      }}>
      {/* Legend Items */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
          marginRight: 20,
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#357AF6',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>Above 100</span>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
          marginRight: 20,
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#0af0e4',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>50-100</span>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
          marginRight: 20,
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#673147',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>30-50</span>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
          marginRight: 20,
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#d9480f',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>10-30</span>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#12b886',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>0-10</span>
      </div>
    </div>
  );
};

const StackedBarChart = ({ data, graphTotal, setParams }: any) => {
  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      graphSkip: sk,
      graphTake: tk,
    }));
  }
  return (
    <div style={{ width: '100%', height: 450 }}>
      <ResponsiveContainer width='100%' height={360}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 10,
            bottom: 30,
          }}
          barCategoryGap='5%'>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' />
          <YAxis
            label={{
              value: 'No. of Openings',
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
            }}
          />
          <Tooltip />
          <Bar dataKey='Above100' stackId='a' fill='#357AF6' barSize={30}>
            <LabelList dataKey='Above100' position='center' style={{ fill: 'white' }} />
          </Bar>
          <Bar dataKey='50-100' stackId='a' fill='#0af0e4' barSize={30}>
            <LabelList dataKey='50-100' position='center' style={{ fill: 'white' }} />
          </Bar>
          <Bar dataKey='30-50' stackId='a' fill='#673147' barSize={30}>
            <LabelList dataKey='30-50' position='center' style={{ fill: 'white' }} />
          </Bar>
          <Bar dataKey='10-30' stackId='a' fill='#d9480f' barSize={30}>
            <LabelList dataKey='10-30' position='center' style={{ fill: 'white' }} />
          </Bar>
          <Bar dataKey='0-10' stackId='a' fill='#12b886' barSize={30}>
            <LabelList dataKey='0-10' position='center' style={{ fill: 'white' }} />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div>
        <CustomLegend /> {/* Custom legend */}
        <Paginate total={graphTotal} DefaultPerPage={10} report={true} setfn={handlePag} />
      </div>
    </div>
  );
};

export default StackedBarChart;
