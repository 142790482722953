import {
  Grid,
  Typography,
  Breadcrumbs,
  Button,
  Link,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Popover,
  Box,
  NativeSelect,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Modal,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import _ from 'lodash';
import moment from 'moment';
import PreIcon from 'assets/png/pre.svg';
import ThreedotIcon from 'assets/png/threedot.svg';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import View from 'assets/png/View.png';
import Copy from 'assets/png/CopyIcon.svg';
import CloseIcon from 'assets/png/close.svg';
import Download from 'assets/png/Download.png';
import CustomInput from 'components/CustomInput/customInput';
import CustomSelect from 'components/CustomSelect/customSelect';
import CustomPhoneInput from 'components/CustomPhoneInput/customPhoneInput';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { cvBankSchema, copyResumeSchema } from 'utils/ValidatorSchema';
import Loader from 'components/loader';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import { addNewData, getAllListData } from 'services/CommonServices';
import {
  CVBANKENDPOINT,
  ClientsENDPOINT,
  PositionENDPOINT,
  ResumesENDPOINT,
  SETTING_ENDPOINT,
} from 'utils/Constant';
import { age, alertOptions, experienceYear, noticePeriod } from 'utils/Config';
import { useAppSelector } from 'store/hooks';
import saveAs from 'file-saver';
import { useNavigate, To } from 'react-router-dom';
import {
  AccessDenied,
  RejetedStatus,
  ResumeApprovalStatus,
  currentResumeStatus,
} from 'utils/helper';
import { toast } from 'react-toastify';

const CvBank = () => {
  const currentDate = moment().format('YYYY-MM-DD');
  const navigate = useNavigate();
  const {
    userInfo: { organizationId: orgdy, id: userId },
    menuList,
  }: any = useAppSelector((store: any) => store.userLogin);
  const [params, setParams] = useState<any>({
    order: null,
    sortby: null,
  });
  const gotoNavigate = (link: To) => {
    navigate(link);
  };
  const [expand, setExpand] = useState<any>({
    basicSearch: false,
    employment: false,
    education: false,
    additional: false,
  });
  const [commonstate, setCommonstate] = useState<any>([]);
  const [selectedResume, setSelectedResume] = useState<any>();
  const [functionalArea, setFunctionalArea] = useState<any>([]);
  const [designation, setDesignation] = useState<any>([]);
  const [ugQualification, setUgQualification] = useState<any>([]);
  const [pgQualification, setPgQualification] = useState<any>([]);
  const [specialization, setSpecialization] = useState<any>([]);
  const [ClientData, setClientData] = useState<any>([]);
  const [positionData, setPositionData] = useState<any>([]);
  const [selectedClientId, setSelectedClientId] = useState<any>();
  const [iscopyModalOpen, setCopyModalOpen] = React.useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [loader, setLoader] = useState(false);
  const containerRef = useRef(null) as any;
  const [panchorEl, setPAnchorEl] = React.useState<HTMLAnchorElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLAnchorElement>, data: any) => {
    setSelectedResume(data);
    setPAnchorEl(event.currentTarget);
  };
  const handlePoperClose = () => {
    setPAnchorEl(null);
  };
  const openPop = Boolean(panchorEl);
  const paid = openPop ? 'simple-popover' : undefined;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const expandAll = (value: any) => {
    setExpand((prev: any) => ({
      ...prev,
      ...value,
    }));
  };
  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    getValues,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      keyword: '',
      keywordType: '',
      mobile: '',
      countryCode: '',
      experienceMin: '',
      experienceMax: '',
      ctcMin: '',
      ctcMax: '',
      ctcCurrency: '',
      currentLocation: '',
      functionalArea: '',
      designation: '',
      noticePeriod: '',
      ugQualificationType: '',
      pgQualificationType: '',
      ugQualification: '',
      pgQualification: '',
      ugSpecialization: '',
      pgSpecialization: '',
      ugCourseType: '',
      pgCourseType: '',
      ugYear: '',
      pgYear: '',
      gender: '',
      maritalStatus: '',
      candidateAgeMin: '',
      candidateAgeMax: '',
      createdFrom: '',
      createdTo: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(cvBankSchema),
  });
  const {
    control: controlCopy,
    handleSubmit: handleCopySubmit,
    reset: resetCopy,
    setValue: setValuesCopy,
    formState: { errors: errorsCopy },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      clientId: '',
      positionId: '',
      oldResumeId: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(copyResumeSchema),
  });
  const handleCopyModalClose = () => {
    setCopyModalOpen(false);
    resetCopy();
  };
  const resetFilters = useCallback(() => {
    setCommonstate([]);
    reset();
  }, [reset]);

  const downloadResume = () => {
    setLoader(true);
    getAllListData(`${ResumesENDPOINT.download}/${selectedResume?.id}`, true)
      .then((res) => {
        saveAs(
          'data:application/pdf;base64,' + res,
          (selectedResume?.personal
            ? `${selectedResume?.personal?.firstName} ${selectedResume?.personal?.lastName}`
            : selectedResume?.file?.fileName) + '.pdf',
          { autoBom: true },
        );
        handlePoperClose();
        setLoader(false);
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const viewResume = () => {
    setLoader(true);
    getAllListData(`${ResumesENDPOINT.download}/${selectedResume?.id}`, true)
      .then((res) => {
        if (res) {
          const baseUrl = res;
          const winHtml = `<!DOCTYPE html>
          <html>
            <body>
              <iframe
                style="width: 100%; height: 100vh"
                src="data:application/pdf;base64,${baseUrl}"
              />
            </body>
          </html>
          `;
          const winUrl = URL.createObjectURL(new Blob([winHtml], { type: 'text/html' }));
          window.open(
            winUrl,
            'win',
            'directories=no,titlebar=no,toolbar=no,location=no,status=no,menubar=no,scrollbars=no,resizable=no,width=700,height=500',
          );
          handlePoperClose();
          setLoader(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setLoader(false);
      });
  };
  const onSubmitForm = (data: any) => {
    setCommonstate([]);
    const searcharr = [
      `${data.keyword ? `keywords=${data.keyword}` : ''}`,
      `${data.keywordType ? `keywordType=${data.keywordType}` : ''}`,
      `${data.mobile ? `countryCode=${data.countryCode}` : ''}`,
      `${data.mobile ? `phoneNumber=${String(data.mobile).slice(data.countryCode.length)}` : ''}`,
      `${data.experienceMin ? `minExperience=${data.experienceMin}` : ''}`,
      `${data.experienceMax ? `maxExperience=${data.experienceMax}` : ''}`,
      `${data.ctcMin ? `minCtc=${data.ctcMin}` : ''}`,
      `${data.ctcMax ? `maxCtc=${data.ctcMax}` : ''}`,
      `${data.ctcCurrency ? `minCurrency=${data.ctcCurrency}` : ''}`,
      `${data.currentLocation ? `currentLocation=${data.currentLocation}` : ''}`,
      `${data.functionalArea ? `functionalAreaId=${data.functionalArea}` : ''}`,
      `${data.designation ? `designation=${data.designation}` : ''}`,
      `${data.noticePeriod ? `noticePeriod=${data.noticePeriod}` : ''}`,
      `${data.ugQualificationType ? `ugType=${data.ugQualificationType}` : ''}`,
      `${data.ugQualification ? `ugQualificationId=${data.ugQualification}` : ''}`,
      `${data.ugSpecialization ? `ugSpecilizationId=${data.ugSpecialization}` : ''}`,
      `${data.ugCourseType ? `ugCourseType=${data.ugCourseType}` : ''}`,
      `${data.ugYear ? `ugYearOfGraduation=${data.ugYear}` : ''}`,
      `${data.pgQualificationType ? `pgType=${data.pgQualificationType}` : ''}`,
      `${data.pgQualification ? `pgQualificationId=${data.pgQualification}` : ''}`,
      `${data.pgSpecialization ? `pgSpecilizationId=${data.pgSpecialization}` : ''}`,
      `${data.pgCourseType ? `pgCourseType=${data.pgCourseType}` : ''}`,
      `${data.pgYear ? `pgYearOfGraduation=${data.pgYear}` : ''}`,
      `${data.gender ? `gender=${data.gender}` : ''}`,
      `${data.maritalStatus ? `maritalStatus=${data.maritalStatus}` : ''}`,
      `${data.candidateAgeMin ? `minAge=${data.candidateAgeMin}` : ''}`,
      `${data.candidateAgeMax ? `maxAge=${data.candidateAgeMax}` : ''}`,
      `${data.createdFrom ? `createdFrom=${data.createdFrom}` : ''}`,
      `${data.createdTo ? `createdTo=${data.createdTo}` : ''}`,
      `${data.order ? `order=${'asc'}` : ''}`,
      `${data.sortby ? `sortby=${'name'}` : ''}`,
    ]
      .filter((ea: any) => ea !== '')
      .join('&');
    if (searcharr && searcharr.length) {
      setLoader(true);
      getAllListData(`${CVBANKENDPOINT.cvBank}?${searcharr}`, true)
        .then((res: any) => {
          if (res.data && res.data.length) {
            setCommonstate(res.data);
            setLoader(false);
          } else {
            setCommonstate([]);
            toast.error('No matching record.', alertOptions);
            setLoader(false);
          }
        })
        .catch((err) => {
          if (err === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    }
  };
  const onCopySubmit = (data: any) => {
    setLoader(true);
    const Obj: any = {
      resumeId: data.oldResumeId,
      clientId: data.clientId,
      positionId: data.positionId,
    };
    addNewData(Obj, CVBANKENDPOINT.cvBank, {}, true)
      .then(() => {
        setLoader(false);
        navigate(AdminPrivateRoutes.RESUMES.RESUMESLIST);
      })
      .catch((e: any) => {
        setLoader(false);
        if (e === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
      });
  };

  useEffect(() => {
    getAllListData(`${SETTING_ENDPOINT.functionalArea}?${orgdy ? orgdy : ''}`, true).then(
      (val: any) => {
        const options: any = [];
        val.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setFunctionalArea(options);
      },
    );
    getAllListData(`${SETTING_ENDPOINT.designation}?${orgdy ? orgdy : ''}`, true).then(
      (val: any) => {
        const options: any = [];
        val.data.map((e: any) => {
          options.push({ label: e.name, value: e.id });
        });
        setDesignation(options);
      },
    );
    getAllListData(`${SETTING_ENDPOINT.Degreedetails}?qualificationId=3`, true).then((re: any) => {
      const options: any = [];
      re.data.map((e: any) => {
        options.push({ label: e.degree, value: e.id });
      });
      setUgQualification(options);
    });
    getAllListData('qualification/specialization?qualificationDetailsId=3', true).then(
      (re: any) => {
        const options: any = [];
        re.data.map((e: any) => {
          options.push({ label: e.specialization, value: e.id, degreeId: e.degreeId });
        });
        setSpecialization(options);
      },
    );
    getAllListData(`${SETTING_ENDPOINT.Degreedetails}?qualificationId=2`, true)
      .then((re: any) => {
        const options: any = [];
        re.data.map((e: any) => {
          options.push({ label: e.degree, value: e.id });
        });
        setPgQualification(options);
      })
      .catch(() => {});
  }, []);
  React.useEffect(() => {
    getAllListData(
      `${ClientsENDPOINT.Client}?organizationId=${orgdy}&${
        menuList
          ?.find((e: any) => e.moduleName === 'My Plan')
          ?.screens?.find((a: any) => a.screenName === 'Create Plan')
          ? `taskUserId=${userId}`
          : `recruiterId=${userId}`
      }&approvalStatus=approved`,
    )
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({ label: e.corporateName, value: e.id });
          });
        setClientData(options);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        // console.log(e);
      });
  }, [selectedResume]);
  React.useEffect(() => {
    if (selectedClientId) {
      getAllListData(
        `${PositionENDPOINT.position}?organizationId=${orgdy}&clientId=${selectedClientId}&${
          menuList
            ?.find((e: any) => e.moduleName === 'My Plan')
            ?.screens?.find((a: any) => a.screenName === 'Create Plan')
            ? `taskUserId=${userId}`
            : `recruiterId=${userId}`
        }`,
      )
        .then((res: any) => {
          const options: any = [];
          res &&
            res.data.map((e: any) => {
              options.push({ label: e.title, value: e.id });
            });
          setPositionData(options);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [selectedClientId]);
  React.useEffect(() => {
    const searcharr = [
      `${getValues('keyword') ? `keywords=${getValues('keyword')}` : ''}`,
      `${getValues('keywordType') ? `keywordType=${getValues('keywordType')}` : ''}`,
      `${getValues('countryCode') ? `countryCode=${getValues('countryCode')}` : ''}`,
      `${
        getValues('mobile')
          ? `phoneNumber=${String(getValues('mobile')).slice(getValues('countryCode').length)}`
          : ''
      }`,
      `${getValues('experienceMin') ? `minExperience=${getValues('experienceMin')}` : ''}`,
      `${getValues('experienceMax') ? `maxExperience=${getValues('experienceMax')}` : ''}`,
      `${getValues('ctcMin') ? `minCtc=${getValues('ctcMin')}` : ''}`,
      `${getValues('ctcMax') ? `maxCtc=${getValues('ctcMax')}` : ''}`,
      `${getValues('ctcCurrency') ? `minCurrency=${getValues('ctcCurrency')}` : ''}`,
      `${getValues('currentLocation') ? `currentLocation=${getValues('currentLocation')}` : ''}`,
      `${getValues('functionalArea') ? `functionalAreaId=${getValues('functionalArea')}` : ''}`,
      `${getValues('designation') ? `designation=${getValues('designation')}` : ''}`,
      `${getValues('noticePeriod') ? `noticePeriod=${getValues('noticePeriod')}` : ''}`,
      `${getValues('ugQualificationType') ? `ugType=${getValues('ugQualificationType')}` : ''}`,
      `${getValues('ugQualification') ? `ugQualificationId=${getValues('ugQualification')}` : ''}`,
      `${
        getValues('ugSpecialization') ? `ugSpecilizationId=${getValues('ugSpecialization')}` : ''
      }`,
      `${getValues('ugCourseType') ? `ugCourseType=${getValues('ugCourseType')}` : ''}`,
      `${getValues('ugYear') ? `ugYearOfGraduation=${getValues('ugYear')}` : ''}`,
      `${getValues('pgQualificationType') ? `pgType=${getValues('pgQualificationType')}` : ''}`,
      `${getValues('pgQualification') ? `pgQualificationId=${getValues('pgQualification')}` : ''}`,
      `${
        getValues('pgSpecialization') ? `pgSpecilizationId=${getValues('pgSpecialization')}` : ''
      }`,
      `${getValues('pgCourseType') ? `pgCourseType=${getValues('pgCourseType')}` : ''}`,
      `${getValues('pgYear') ? `pgYearOfGraduation=${getValues('pgYear')}` : ''}`,
      `${getValues('gender') ? `gender=${getValues('gender')}` : ''}`,
      `${getValues('maritalStatus') ? `maritalStatus=${getValues('maritalStatus')}` : ''}`,
      `${getValues('candidateAgeMin') ? `minAge=${getValues('candidateAgeMin')}` : ''}`,
      `${getValues('candidateAgeMax') ? `maxAge=${getValues('candidateAgeMax')}` : ''}`,
      `${getValues('createdFrom') ? `createdFrom=${getValues('createdFrom')}` : ''}`,
      `${getValues('createdTo') ? `createdTo=${getValues('createdTo')}` : ''}`,
      `${params.order ? `order=${params.order}` : ''}`,
      `${params.sortby ? `sortby=${params.sortby}` : ''}`,
    ]
      .filter((ea: any) => ea !== '')
      .join('&');
    if (searcharr && searcharr.length) {
      getAllListData(`${CVBANKENDPOINT.cvBank}?organizationId=${orgdy}&${searcharr}`, true)
        .then((res: any) => {
          setCommonstate(res.data);
          setLoader(false);
        })
        .catch((err) => {
          if (err === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
          setLoader(false);
        });
    }
  }, [params]);
  return (
    <Grid container>
      {loader && <Loader />}
      <Grid item md={12} sm={12} xs={12}>
        <Typography component={'div'} className='breadcrumbs d-flex-a'>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link underline='hover' color='inherit' href='/'>
              <img src={HomeIcon} className='home' alt='Home' />
            </Link>
            <Link underline='hover' color='inherit'>
              <img src={ArrowIcon} alt='arrow' className='arrows' />
              CV Bank
            </Link>
          </Breadcrumbs>
        </Typography>

        <Typography component={'div'} className='d-flex-a flex-sb title-add-box'>
          <Typography className='page-title'>CV Search</Typography>
          <Typography component={'div'} className='d-flex-a'>
            <Button className='cancel-btn mr-16' onClick={() => resetFilters()}>
              {' '}
              Reset
            </Button>
            <Button className='s-add-btn' form={'billing-form'} type={'submit'}>
              Search
            </Button>
          </Typography>
        </Typography>
        <Typography component={'div'} className='main-content'>
          <Typography component={'div'} className='white-box'>
            <Grid container>
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                {expand.basicSearch &&
                expand.employment &&
                expand.education &&
                expand.additional ? (
                  <div
                    style={{
                      color: '#5F2EE5',
                      cursor: 'pointer',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                    onClick={() =>
                      expandAll({
                        basicSearch: false,
                        employment: false,
                        education: false,
                        additional: false,
                      })
                    }>
                    Close All
                  </div>
                ) : (
                  <div
                    style={{
                      color: '#5F2EE5',
                      cursor: 'pointer',
                      fontSize: '12px',
                      fontWeight: '500',
                    }}
                    onClick={() =>
                      expandAll({
                        basicSearch: true,
                        employment: true,
                        education: true,
                        additional: true,
                      })
                    }>
                    Expand All
                  </div>
                )}
              </Grid>
            </Grid>
            <Accordion
              expanded={expand.basicSearch}
              onChange={() => expandAll({ basicSearch: expand.basicSearch ? false : true })}>
              <AccordionSummary
                className='accordion'
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel1-content'
                id='panel1-header'>
                BASIC SEARCH
              </AccordionSummary>
              <AccordionDetails>
                <form
                  className='private-form'
                  id={'billing-form'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <Grid container columnSpacing={5}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Keyword</Typography>
                        <Controller
                          control={control}
                          name={'keyword'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Enter Any Keyword'
                                error={errors?.keyword?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Keyword Type</Typography>
                        <Controller
                          control={control}
                          name={'keywordType'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                name={name}
                                className='custom-input'
                                value={value ? value : null}
                                placeHolder='Select'
                                options={[
                                  {
                                    label: 'All',
                                    value: 'all',
                                  },
                                  {
                                    label: 'Any',
                                    value: 'any',
                                  },
                                ]}
                                error={errors?.keywordType?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Mobile</Typography>
                        <Controller
                          control={control}
                          name={'mobile'}
                          render={({ field: { onChange, value } }) => {
                            return (
                              <CustomPhoneInput
                                placeholder='+91 00000-00000'
                                inputClass={'phone-input'}
                                initialCountry={'in'}
                                specialLabel={''}
                                searchClass={'search-custom-class'}
                                value={value ? value : null}
                                enableSearch={true}
                                searchNotFound={'No Country Found'}
                                inputStyle={{
                                  width: '100%',
                                  padding: '9px 11px',
                                  paddingLeft: '60px',
                                  borderRadius: '6px',
                                  color: '#344051',
                                  fontSize: '16px',
                                  fontFamily: 'Poppins-Regular',
                                  boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)',
                                }}
                                onChange={(value, data) => {
                                  const { dialCode } = data;
                                  setValue('countryCode', `${dialCode}`);
                                  onChange(value);
                                }}
                                error={errors.mobile?.message ? errors.mobile?.message : ''}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12} sx={{ display: 'flex' }}>
                      <Grid container columnSpacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>Experience</Typography>
                            <Controller
                              control={control}
                              name={'experienceMin'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomSelect
                                    value={value ? value : null}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Min.'
                                    options={experienceYear}
                                    error={errors?.experienceMin?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <div style={{ height: '25px' }} />
                            <Controller
                              control={control}
                              name={'experienceMax'}
                              render={({ field: { onChange, value, name } }) => {
                                const max = watch('experienceMin')
                                  ? experienceYear.filter(
                                      (re: any) =>
                                        Number(re.value) >= Number(watch('experienceMin')),
                                    )
                                  : experienceYear;
                                return (
                                  <CustomSelect
                                    value={value ? value : null}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Max.'
                                    options={max}
                                    error={errors?.experienceMax?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12} sx={{ display: 'flex' }}>
                      <Grid container columnSpacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>CTC</Typography>
                            <Controller
                              control={control}
                              name={'ctcMin'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    className='custom-input'
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    placeHolder='Min. CTC'
                                    type={'number'}
                                    error={errors?.ctcMin?.message}
                                    customComponent={
                                      <Box
                                        sx={{
                                          minWidth: 120,
                                          position: 'absolute',
                                          right: '-40px',
                                          transform: 'scale(0.9)',
                                        }}>
                                        <Controller
                                          control={control}
                                          name='ctcCurrency'
                                          render={({ field: { value, name } }) => {
                                            return (
                                              <NativeSelect
                                                value={value}
                                                name={name}
                                                onChange={(e: any) => {
                                                  setValue('ctcCurrency', e.target.value);
                                                }}
                                                className='position-currency-option'
                                                IconComponent={ExpandMoreRoundedIcon}
                                                sx={{
                                                  border: 'none !important',
                                                  minHeight: '20px !important',
                                                  '&::before': {
                                                    borderBottom: 'transparent !important',
                                                  },
                                                  '& > select': {
                                                    minWidth: '40px !important',
                                                    border: 'none !important',
                                                    outline: 'none !important',
                                                    marginRight: '8px',
                                                  },
                                                }}>
                                                <option value={'inr'}>INR</option>
                                                <option value={'usd'}>USD</option>
                                                <option value={'aed'}>AED</option>
                                              </NativeSelect>
                                            );
                                          }}
                                        />
                                      </Box>
                                    }
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <div style={{ height: '25px' }} />
                            <Controller
                              control={control}
                              name={'ctcMax'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomInput
                                    className='custom-input'
                                    onChange={onChange}
                                    value={value}
                                    name={name}
                                    placeHolder='Max. CTC'
                                    type={'number'}
                                    error={errors?.ctcMax?.message}
                                    customComponent={
                                      <Box
                                        sx={{
                                          minWidth: 120,
                                          position: 'absolute',
                                          right: '-40px',
                                          transform: 'scale(0.9)',
                                        }}>
                                        <Controller
                                          control={control}
                                          name='ctcCurrency'
                                          render={({ field: { value, name } }) => {
                                            return (
                                              <NativeSelect
                                                value={value}
                                                name={name}
                                                onChange={(e: any) => {
                                                  setValue('ctcCurrency', e.target.value);
                                                }}
                                                className='position-currency-option'
                                                IconComponent={ExpandMoreRoundedIcon}
                                                sx={{
                                                  border: 'none !important',
                                                  minHeight: '20px !important',
                                                  '&::before': {
                                                    borderBottom: 'transparent !important',
                                                  },
                                                  '& > select': {
                                                    minWidth: '40px !important',
                                                    border: 'none !important',
                                                    outline: 'none !important',
                                                    marginRight: '8px',
                                                  },
                                                }}>
                                                <option value={'inr'}>INR</option>
                                                <option value={'usd'}>USD</option>
                                                <option value={'aed'}>AED</option>
                                              </NativeSelect>
                                            );
                                          }}
                                        />
                                      </Box>
                                    }
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Current Location</Typography>
                        <Controller
                          control={control}
                          name={'currentLocation'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Current Location'
                                error={errors?.currentLocation?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expand.employment}
              onChange={() => expandAll({ employment: expand.employment ? false : true })}>
              <AccordionSummary
                className='accordion'
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel2-content'
                id='panel2-header'>
                EMPLOYMENT
              </AccordionSummary>
              <AccordionDetails>
                <form
                  className='private-form'
                  id={'billing-form'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <Grid container columnSpacing={5}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Functional Area</Typography>
                        <Controller
                          control={control}
                          name={'functionalArea'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='Select'
                                options={functionalArea}
                                error={errors?.functionalArea?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Designation</Typography>
                        <Controller
                          control={control}
                          name={'designation'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='Select'
                                options={designation}
                                error={errors?.designation?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Notice Period</Typography>
                        <Controller
                          control={control}
                          name={'noticePeriod'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='Select'
                                options={noticePeriod}
                                error={errors?.noticePeriod?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expand.education}
              onChange={() => expandAll({ education: expand.education ? false : true })}>
              <AccordionSummary
                className='accordion'
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                EDUCATION
              </AccordionSummary>
              <AccordionDetails>
                <form
                  className='private-form'
                  id={'billing-form'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <Grid container columnSpacing={5}>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>UG Qualification</Typography>
                        <Controller
                          control={control}
                          name='ugQualificationType'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  if (watch('ugQualificationType') === 'any') {
                                    setValue('ugQualification', '');
                                    setValue('ugSpecialization', '');
                                  }
                                  if (watch('ugQualificationType') === 'notCompulsory') {
                                    setValue('ugQualification', '');
                                    setValue('ugSpecialization', '');
                                    setValue('ugCourseType', '');
                                    setValue('ugYear', '');
                                  }
                                }}>
                                <FormControlLabel
                                  value='any'
                                  control={<Radio />}
                                  label='Any'
                                  checked={value === 'any'}
                                />
                                <FormControlLabel
                                  value='specific'
                                  control={<Radio />}
                                  label='Specific'
                                  checked={value === 'specific'}
                                />
                                <FormControlLabel
                                  value='notCompulsory'
                                  control={<Radio />}
                                  label='Not Compulsory'
                                  checked={value === 'notCompulsory'}
                                />
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>UG Qualification</Typography>
                        <Controller
                          control={control}
                          name={'ugQualification'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                disabled={
                                  watch('ugQualificationType') === 'any'
                                    ? true
                                    : watch('ugQualificationType') === 'notCompulsory'
                                      ? true
                                      : false
                                }
                                className='custom-input'
                                placeHolder='Select'
                                options={ugQualification}
                                error={errors?.ugQualification?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>UG Specialization</Typography>
                        <Controller
                          control={control}
                          name={'ugSpecialization'}
                          render={({ field: { onChange, value, name } }) => {
                            let selectData: any = [];
                            if (watch('ugQualification')) {
                              selectData = specialization.filter(
                                (re: any) => re.degreeId == watch('ugQualification'),
                              );
                            }
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                disabled={
                                  watch('ugQualificationType') === 'any'
                                    ? true
                                    : watch('ugQualificationType') === 'notCompulsory'
                                      ? true
                                      : false
                                }
                                className='custom-input'
                                placeHolder='Select'
                                options={selectData}
                                error={errors?.ugSpecialization?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>UG Course Type</Typography>
                        <Controller
                          control={control}
                          name={'ugCourseType'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                className='custom-input'
                                placeHolder='Select'
                                disabled={
                                  watch('ugQualificationType') === 'notCompulsory' ? true : false
                                }
                                options={[
                                  { label: 'Regular', value: 'regular' },
                                  { label: 'Correspondence', value: 'correspondence' },
                                ]}
                                error={errors?.ugCourseType?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>UG Year of Graduation</Typography>
                        <Controller
                          control={control}
                          name={'ugYear'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                disabled={
                                  watch('ugQualificationType') === 'notCompulsory' ? true : false
                                }
                                className='custom-input'
                                placeHolder='UG Year of Graduation'
                                error={errors?.ugYear?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography className='bdiveder' />
                    </Grid>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>PG Qualification</Typography>
                        <Controller
                          control={control}
                          name='pgQualificationType'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                  if (watch('pgQualificationType') === 'any') {
                                    setValue('pgQualification', '');
                                    setValue('pgSpecialization', '');
                                  }
                                  if (watch('pgQualificationType') === 'notCompulsory') {
                                    setValue('pgQualification', '');
                                    setValue('pgSpecialization', '');
                                    setValue('pgCourseType', '');
                                    setValue('pgYear', '');
                                  }
                                }}>
                                <FormControlLabel
                                  value='any'
                                  control={<Radio />}
                                  label='Any'
                                  checked={value === 'any'}
                                />
                                <FormControlLabel
                                  value='specific'
                                  control={<Radio />}
                                  label='Specific'
                                  checked={value === 'specific'}
                                />
                                <FormControlLabel
                                  value='notCompulsory'
                                  control={<Radio />}
                                  label='Not Compulsory'
                                  checked={value === 'notCompulsory'}
                                />
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>PG Qualification</Typography>
                        <Controller
                          control={control}
                          name={'pgQualification'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                disabled={
                                  watch('pgQualificationType') === 'any'
                                    ? true
                                    : watch('pgQualificationType') === 'notCompulsory'
                                      ? true
                                      : false
                                }
                                className='custom-input'
                                placeHolder='Select'
                                options={pgQualification}
                                error={errors?.pgQualification?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>PG Specialization</Typography>
                        <Controller
                          control={control}
                          name={'pgSpecialization'}
                          render={({ field: { onChange, value, name } }) => {
                            let selectData: any = [];
                            if (watch('pgQualification')) {
                              selectData = specialization.filter(
                                (re: any) => re.degreeId == watch('pgQualification'),
                              );
                            }
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                disabled={
                                  watch('pgQualificationType') === 'any'
                                    ? true
                                    : watch('pgQualificationType') === 'notCompulsory'
                                      ? true
                                      : false
                                }
                                className='custom-input'
                                placeHolder='Select'
                                options={selectData}
                                error={errors?.pgSpecialization?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>PG Course Type</Typography>
                        <Controller
                          control={control}
                          name={'pgCourseType'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomSelect
                                value={value ? value : null}
                                name={name}
                                disabled={
                                  watch('pgQualificationType') === 'notCompulsory' ? true : false
                                }
                                className='custom-input'
                                placeHolder='Select'
                                options={[
                                  { label: 'Regular', value: 'regular' },
                                  { label: 'Correspondence', value: 'correspondence' },
                                ]}
                                error={errors?.pgCourseType?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>PG Year of Graduation</Typography>
                        <Controller
                          control={control}
                          name={'pgYear'}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <CustomInput
                                value={value}
                                name={name}
                                disabled={
                                  watch('pgQualificationType') === 'notCompulsory' ? true : false
                                }
                                className='custom-input'
                                placeHolder='PG Year of Graduation'
                                error={errors?.pgYear?.message}
                                onChange={(e) => {
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                </form>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expand.additional}
              onChange={() => expandAll({ additional: expand.additional ? false : true })}>
              <AccordionSummary
                className='accordion'
                expandIcon={<ExpandMoreIcon />}
                aria-controls='panel3-content'
                id='panel3-header'>
                ADDITIONAL
              </AccordionSummary>
              <AccordionDetails>
                <form
                  className='private-form'
                  id={'billing-form'}
                  onSubmit={handleSubmit(onSubmitForm)}>
                  <Grid container columnSpacing={5}>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Gender</Typography>
                        <Controller
                          control={control}
                          name='gender'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel
                                  value='all'
                                  control={<Radio />}
                                  label='All'
                                  checked={value === 'all'}
                                />
                                <FormControlLabel
                                  value='male'
                                  control={<Radio />}
                                  label='Male'
                                  checked={value === 'male'}
                                />
                                <FormControlLabel
                                  value='female'
                                  control={<Radio />}
                                  label='Female'
                                  checked={value === 'female'}
                                />
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12}>
                      <Typography component={'div'} className='custom-field'>
                        <Typography component={'p'}>Marital Status</Typography>
                        <Controller
                          control={control}
                          name='maritalStatus'
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <RadioGroup
                                className='custom-radio'
                                row
                                aria-labelledby=''
                                name={name}
                                value={value}
                                onChange={(e) => {
                                  onChange(e);
                                }}>
                                <FormControlLabel
                                  value='any'
                                  control={<Radio />}
                                  label='Any'
                                  checked={value === 'any'}
                                />
                                <FormControlLabel
                                  value='single'
                                  control={<Radio />}
                                  label='Single'
                                  checked={value === 'single'}
                                />
                                <FormControlLabel
                                  value='married'
                                  control={<Radio />}
                                  label='Married'
                                  checked={value === 'married'}
                                />
                                {/* <FormControlLabel
                                  value='separated'
                                  control={<Radio />}
                                  label='Separated'
                                  checked={value === 'separated'}
                                /> */}
                              </RadioGroup>
                            );
                          }}
                        />
                      </Typography>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12} sx={{ display: 'flex' }}>
                      <Grid container columnSpacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>Candidate Age</Typography>
                            <Controller
                              control={control}
                              name={'candidateAgeMin'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <CustomSelect
                                    value={value ? value : null}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Min.'
                                    options={age}
                                    error={errors?.candidateAgeMin?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <div style={{ height: '25px' }} />
                            <Controller
                              control={control}
                              name={'candidateAgeMax'}
                              render={({ field: { onChange, value, name } }) => {
                                const max = watch('candidateAgeMin')
                                  ? age.filter(
                                      (re: any) =>
                                        Number(re.value) >= Number(watch('candidateAgeMin')),
                                    )
                                  : age;
                                return (
                                  <CustomSelect
                                    value={value ? value : null}
                                    name={name}
                                    className='custom-input'
                                    placeHolder='Max.'
                                    options={max}
                                    error={errors?.candidateAgeMax?.message}
                                    onChange={(e) => {
                                      onChange(e);
                                    }}
                                  />
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item md={5} sm={12} xs={12} sx={{ display: 'flex' }}>
                      <Grid container columnSpacing={3}>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>Created From</Typography>
                            <Controller
                              control={control}
                              name={'createdFrom'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <>
                                    <TextField
                                      style={{ marginTop: '0px !important' }}
                                      value={value}
                                      name={name}
                                      id='date'
                                      type='date'
                                      placeholder={'Enter Year'}
                                      className={`clientfilter-time-from-to ${
                                        errors.createdFrom && errors.createdFrom.message
                                          ? 'clientfilter-time-from-to-error'
                                          : ''
                                      }`}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: { max: currentDate },
                                      }}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                    {errors &&
                                      errors.createdFrom &&
                                      errors?.createdFrom?.message && (
                                        <div className='Custom-error '>
                                          {String(errors?.createdFrom?.message)}
                                        </div>
                                      )}
                                  </>
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                        <Grid item md={6} sm={12} xs={12}>
                          <Typography component={'div'} className='custom-field'>
                            <Typography component={'p'}>Created To</Typography>
                            <Controller
                              control={control}
                              name={'createdTo'}
                              render={({ field: { onChange, value, name } }) => {
                                return (
                                  <>
                                    <TextField
                                      style={{ marginTop: '0px !important' }}
                                      value={value}
                                      name={name}
                                      id='date'
                                      type='date'
                                      placeholder={'Enter Year'}
                                      className={`clientfilter-time-from-to ${
                                        errors.createdTo && errors.createdTo.message
                                          ? 'clientfilter-time-from-to-error'
                                          : ''
                                      }`}
                                      InputLabelProps={{
                                        shrink: true,
                                      }}
                                      InputProps={{
                                        inputProps: {
                                          min: getValues('createdFrom'),
                                          max: currentDate,
                                        },
                                      }}
                                      onChange={(e) => {
                                        onChange(e);
                                      }}
                                    />
                                    {errors && errors.createdTo && errors?.createdTo?.message && (
                                      <div className='Custom-error '>
                                        {String(errors?.createdTo?.message)}
                                      </div>
                                    )}
                                  </>
                                );
                              }}
                            />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </form>
              </AccordionDetails>
            </Accordion>
            {commonstate && commonstate.length > 0 ? (
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          width: 'calc(100% - 93px)',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Code
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('code', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('code', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Name
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('name', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('name', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Mobile
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('mobile', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('mobile', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Email
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('email', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('email', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Created On
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdAt', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdAt', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Status
                          {/* <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdBy', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdBy', 'desc')}
                            />
                          </span> */}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Total Exp.
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('exp', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('exp', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          Current Location
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('location', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('location', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Current Company
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('currentCompany', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('currentCompany', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Current Designation
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('designation', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('designation', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Current Functional Area
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('functionalArea', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('functionalArea', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row: any) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              // verticalAlign: 'baseline',
                              marginLeft: '50px',
                              marginRight: '43px',
                              width: 'calc(100% - 93px)',
                            }}>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                minWidth: '120px',
                              }}
                              onClick={() => {
                                gotoNavigate(
                                  `${AdminPrivateRoutes.RESUMES.RESUMESVIEW}?id=${row.id}`,
                                );
                              }}>
                              <Typography
                                component={'p'}
                                sx={{
                                  cursor: 'pointer',
                                  color: '#5F2EE5',
                                  fontSize: '14px !important',
                                }}>
                                CT-{row && row.code ? row.code : '-'}
                              </Typography>
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '120px' }}>
                              <TableTooltip
                                value={
                                  row && row.personal && !_.isEmpty(row.personal)
                                    ? `${row.personal.firstName} ${row.personal.lastName}`
                                    : '-'
                                }></TableTooltip>
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '140px',
                              }}>
                              {row && row.personal && row.personal.phoneNumber
                                ? `+${row.personal.countryCode} ${row.personal.phoneNumber}`
                                : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                maxWidth: '250px',
                                minWidth: '120px',
                                hypens: 'auto',
                              }}>
                              <TableTooltip
                                value={
                                  row && row.personal && row && row.personal.email
                                    ? row.personal.email
                                    : '-'
                                }></TableTooltip>
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                maxWidth: '200px',
                                minWidth: '100px',
                                hypens: 'auto',
                              }}>
                              {row && row.personal.createdAt
                                ? moment(row.personal.createdAt).format('DD-MM-YYYY')
                                : ' '}
                            </TableCell>

                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '200px',
                                hyphens: 'auto',
                              }}>
                              {row?.approvalStatus === ResumeApprovalStatus.Draft
                                ? 'Draft'
                                : row?.approvalStatus === ResumeApprovalStatus.InReview
                                  ? 'CRM Validation Pending'
                                  : currentResumeStatus(row?.resumeStatus || '--')}
                              {/* {row && row?.approvalStatus ? row?.approvalStatus : '-'} */}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '120px',
                                hyphens: 'auto',
                              }}>
                              {(row && row?.personal.expYears) || row?.personal.expMonths
                                ? `${row.personal.expYears}(Y) ${row.personal.expMonths}(M)`
                                : '-'}
                            </TableCell>
                            <TableCell
                              // align='center'
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '150px',
                                hyphens: 'auto',
                              }}>
                              <TableTooltip
                                value={
                                  row && row?.personal?.presentLocation
                                    ? row?.personal?.presentLocation
                                    : '-'
                                }
                              />
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                              {/* <TableTooltip
                                value={
                                  row && row.experience && !_.isEmpty(row.experience)
                                    ? row?.experience[0]?.companyName
                                    : '-'
                                }></TableTooltip> */}
                              {'-'}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                              {/* {row && row?.experience ? row?.experience[0]?.designation : '-'} */}
                              {'-'}{' '}
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '100px' }}>
                              {'-'}
                            </TableCell>
                            <TableCell align='center'>
                              <img
                                src={ThreedotIcon}
                                alt='ThreedotIcon'
                                onClick={(e: any) => {
                                  handleClick(e, row);
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Data Found</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
            ) : (
              ''
            )}
          </Typography>
        </Typography>
      </Grid>
      <Popover
        className='profile-drop'
        id={paid}
        anchorEl={panchorEl}
        open={openPop}
        onClose={handlePoperClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        style={{ width: '210px !important' }}>
        <Typography component={'div'} className='menu-box'>
          <Typography
            className='menu-item'
            onClick={downloadResume}
            style={{ padding: '0px 0px 0px 15px' }}>
            <img src={Download} alt='Download' />
            Download Resume
          </Typography>
          <Typography
            className='menu-item'
            onClick={viewResume}
            style={{ padding: '0px 0px 0px 15px' }}>
            <img src={View} alt='View' />
            View Resume
          </Typography>
          {selectedResume?.resumeStatus.find((e: any) => RejetedStatus.includes(e.status)) && (
            <Typography
              className='menu-item'
              onClick={() => {
                setValuesCopy('oldResumeId', selectedResume?.id);
                setCopyModalOpen(true);
              }}
              style={{ padding: '0px 0px 0px 15px', color: '#EE4B22' }}>
              <img src={Copy} alt='View' />
              Copy
            </Typography>
          )}
        </Typography>
      </Popover>
      <Modal open={iscopyModalOpen} onClose={handleCopyModalClose}>
        <Box className='logout-modal p-32'>
          <Typography
            component={'div'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              marginBottom: '1rem',
            }}>
            <Typography sx={{ fontSize: '20px', fontWeight: '600' }}>{'Copy Resume'}</Typography>
            <Typography className='text-right'>
              <img
                src={CloseIcon}
                alt='close'
                style={{ cursor: 'pointer' }}
                onClick={handleCopyModalClose}
              />
            </Typography>
          </Typography>
          <form id='Copy-Resume' className='private-form' onSubmit={handleCopySubmit(onCopySubmit)}>
            <Grid container>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Client<span>*</span>
                  </Typography>
                  <Controller
                    control={controlCopy}
                    name='clientId'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={ClientData}
                          name={name}
                          value={value}
                          onChange={(e) => {
                            onChange(e);
                            setSelectedClientId(e.target.value);
                            setPositionData([]);
                            setValue('positionId', '');
                          }}
                          placeHolder='Select'
                          error={errorsCopy.clientId?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <Typography component={'p'}>
                    Position<span>*</span>
                  </Typography>
                  <Controller
                    control={controlCopy}
                    name='positionId'
                    render={({ field: { onChange, value, name } }) => {
                      return (
                        <CustomSelect
                          labelKey={'label'}
                          valueKey={'value'}
                          options={positionData}
                          name={name}
                          value={value ? value : null}
                          onChange={(e) => {
                            onChange(e);
                          }}
                          placeHolder='Select'
                          error={errorsCopy.positionId?.message}
                        />
                      );
                    }}
                  />
                </Typography>
              </Grid>
              <Grid item md={12} sm={12} xs={12}>
                <Typography
                  component={'div'}
                  sx={{ justifyContent: 'flex-end' }}
                  className='d-flex-a'>
                  <Button className='cancel-btn mr-16' onClick={handleCopyModalClose}>
                    Cancel
                  </Button>
                  <Button form={'Copy-Resume'} className='s-add-btn' type='submit'>
                    Submit
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </Grid>
  );
};
export default CvBank;
