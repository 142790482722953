import React from 'react';
import Paginate from 'components/Pagination/Pagination';
import { BarChart, Bar, XAxis, YAxis, Tooltip, ResponsiveContainer, LabelList } from 'recharts';

const CustomLegend = ({ type }: { type: string }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
      {type === 'CvStatus' ? (
        <>
          {[
            { color: '#357AF6', label: 'CV Sent to Shortlisted %' },
            { color: '#FF4C4C', label: 'CV Sent to Interviewed %' },
            { color: '#28A745', label: 'Opening worked total Billed %' },
          ].map((item) => (
            <div
              key={item.label}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 20,
                borderRadius: '5px',
                padding: '10px',
                backgroundColor: '#F2F2F7',
              }}>
              <div
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: item.color,
                  marginRight: 5,
                  borderRadius: '20%',
                }}
              />
              <span style={{ color: '#000', fontWeight: 'bold', marginRight: 5 }}>
                {item.label}
              </span>
            </div>
          ))}
        </>
      ) : (
        <>
          {[
            { color: '#357AF6', label: '0-10' },
            { color: '#FF4C4C', label: '10-30' },
            { color: '#28A745', label: '30-50' },
            { color: '#673147', label: '50-100' },
            { color: '#FFFF00', label: 'Above 100' },
          ].map((item) => (
            <div
              key={item.label}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 20,
                borderRadius: '5px',
                padding: '10px',
                backgroundColor: '#F2F2F7',
              }}>
              <div
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: item.color,
                  marginRight: 5,
                  borderRadius: '20%',
                }}
              />
              <span style={{ color: '#000', fontWeight: 'bold', marginRight: 5 }}>
                {item.label}
              </span>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const CustomBarChart = ({ data, type, graphTotal, setParams }: any) => {
  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      graphSkip: sk,
      graphTake: tk,
    }));
  }

  // Custom function for conditional label rendering
  const renderCustomLabel = (props: any) => {
    const { x, y, width, height, value } = props;
    if (value === 0) return null; // Don't render the label if value is 0
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill='white'
        textAnchor='middle'
        dominantBaseline='middle'
        fontSize={12}>
        {value}
      </text>
    );
  };

  // Tooltip mapping
  const tooltipMapping: any = {
    blue: '0-10',
    red: '10-30',
    green: '30-50',
    purple: '50-100',
    yellow: 'Above 100',
  };

  // Custom Tooltip
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const { dataKey, value } = payload[0];
      return (
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            border: '1px solid #ccc',
            padding: '10px',
            borderRadius: '5px',
          }}>
          <p style={{ margin: 0, fontWeight: 'bold' }}>{tooltipMapping[dataKey]}</p>
          <p style={{ margin: 0 }}>Value: {value}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <>
      {type === 'CvStatus' ? (
        <div style={{ width: '100%', height: '450px', margin: '20px 0', flexWrap: 'wrap' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              layout='vertical'
              data={data}
              margin={{ top: 20, right: 30, left: 100, bottom: 50 }} // Increased bottom margin
              barSize={22}>
              <XAxis
                type='number'
                domain={[0, 30]}
                tickLine={false}
                label={{
                  value: '% against CVs Sent',
                  position: 'insideBottom',
                  dy: 20,
                  style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
                }}
              />
              <YAxis type='category' dataKey='name' width={150} tickLine={false} />
              <Tooltip />
              <Bar dataKey='cvSentShortlistedPercentage' fill='#357AF6' stackId='a'>
                <LabelList dataKey='cvSentShortlistedPercentage' content={renderCustomLabel} />
              </Bar>
              <Bar dataKey='cvSentInterviewedPercentage' fill='#FF4C4C' stackId='a'>
                <LabelList dataKey='cvSentInterviewedPercentage' content={renderCustomLabel} />
              </Bar>
              <Bar dataKey='openingWorkedToBilledPercentage' fill='#28A745' stackId='a'>
                <LabelList dataKey='openingWorkedToBilledPercentage' content={renderCustomLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      ) : (
        <div style={{ width: '100%', height: '450px', margin: '20px 0', flexWrap: 'wrap' }}>
          <ResponsiveContainer width='100%' height='100%'>
            <BarChart
              layout='vertical'
              data={data}
              margin={{ top: 20, right: 30, left: 100, bottom: 50 }} // Increased bottom margin
              barSize={22}>
              <XAxis
                type='number'
                domain={[0, 30]}
                tickLine={false}
                label={{
                  value: 'No. of Openings',
                  position: 'insideBottom',
                  dy: 20,
                  style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
                }}
              />
              <YAxis
                type='category'
                dataKey='name'
                width={150}
                tickLine={false}
                label={{
                  value: 'Company List',
                  angle: -90,
                  position: 'insideLeft',
                  style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar dataKey='blue' fill='#357AF6' stackId='a'>
                <LabelList dataKey='blue' content={renderCustomLabel} />
              </Bar>
              <Bar dataKey='red' fill='#FF4C4C' stackId='a'>
                <LabelList dataKey='red' content={renderCustomLabel} />
              </Bar>
              <Bar dataKey='green' fill='#28A745' stackId='a'>
                <LabelList dataKey='green' content={renderCustomLabel} />
              </Bar>
              <Bar dataKey='purple' fill='#673147' stackId='a'>
                <LabelList dataKey='purple' content={renderCustomLabel} />
              </Bar>
              <Bar dataKey='yellow' fill='#FFFF00' stackId='a'>
                <LabelList dataKey='yellow' content={renderCustomLabel} />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      <div>
        <CustomLegend type={type} />
        {type === 'CvStatus' ? null : (
          <Paginate total={graphTotal} DefaultPerPage={10} report={true} setfn={handlePag} />
        )}
      </div>
    </>
  );
};

export default CustomBarChart;
