import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import Paginate from 'components/Pagination/Pagination';

const TaktimeGraph = ({ data, graphTotal, setParams }: any) => {
  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      graphSkip: sk,
      graphTake: tk,
    }));
  }

  return (
    <div style={{ width: '100%', height: 450 }}>
      <ResponsiveContainer width='100%' height={360}>
        <BarChart
          data={data}
          margin={{
            top: 20,
            right: 20,
            left: 10,
            bottom: 30,
          }}
          barCategoryGap='15%'>
          <CartesianGrid strokeDasharray='3 3' />
          <XAxis dataKey='name' tick={{ fontSize: 12, fontWeight: 'bold' }} interval={0} />
          <YAxis
            domain={[0, 'dataMax + 1']} // Dynamically calculate the Y-axis max
            tickCount={6} // Recharts will try to fit 6 ticks
            allowDecimals={false} // No decimal ticks
            label={{
              value: 'TAKT Time Days',
              angle: -90,
              position: 'insideLeft',
              style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
            }}
          />
          <Tooltip />
          <Bar dataKey='value' fill='#357AF6' barSize={30}>
            <LabelList
              dataKey='value'
              position='inside'
              fill='#fff'
              fontSize={12}
              fontWeight='bold'
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
      <div style={{ display: 'flex', marginTop: '30px', justifyContent: 'center' }}>
        <Paginate total={graphTotal} DefaultPerPage={10} report={true} setfn={handlePag} />
      </div>
    </div>
  );
};

export default TaktimeGraph;
