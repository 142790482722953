import React, { useCallback, useState } from 'react';
import Refresh from 'assets/png/reset.svg';
import Preview from 'assets/png/preview.svg';
import { Button, Grid, Typography } from '@mui/material';
import DateTimepicker from 'components/CustomDateRangePicker';
// import CloseIcon from '@mui/icons-material/Close';
import CustomSelect from 'components/CustomSelect/customSelect';
import { ReportFilterSchema } from 'utils/ValidatorSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { BusinessConversion, CvStatus, openingHandle, Productivity, TaktTime } from 'utils/helper';
import { ReportFilterType } from 'types';

export const ReportFilter = ({
  setParams,
  setRoleData,
  branchData,
  employeeData,
  setEmployeeName,
  tableData,
  graphData,
  setPreviewOpen, // params,
}: any) => {
  const [key, setKey] = useState(0); // key to re-render DateRangePicker
  const Url: any = window?.location?.pathname;
  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<ReportFilterType>({
    criteriaMode: 'all',
    defaultValues: {
      mergedDate: [],
      fromDate: '',
      toDate: '',
      type: '',
      role: '',
      employee: [],
      branch: '',
      report: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(ReportFilterSchema),
  });
  const resetFilters = useCallback(() => {
    setParams((prev: any) => ({
      ...prev,
      fromDate: '',
      mergedDate: [],
      toDate: '',
      type: null,
      role: null,
      employee: null,
      branchId: null,
      report: null,
    }));
    reset();
    setKey((prevKey) => prevKey + 1);
    tableData('');
    graphData('');
  }, [reset]);
  const onSubmit = (data: any) => {
    setParams((prev: any) => ({
      ...prev,
      fromDate: data.mergedDate?.[0]
        ? moment(new Date(data.mergedDate[0])).format('YYYY-MM-DD')
        : null,
      toDate: data.mergedDate?.[1]
        ? moment(new Date(data.mergedDate[1])).format('YYYY-MM-DD')
        : null,
      type: data.type || null,
      role: data.role || null,
      employee: data.employee || null,
      branchId: data.branch || null,
      report: data.report || null,
      graphSkip: 0,
      graphTake: 10,
      tableSkip: 0,
      tableTake: 10,
    }));
  };

  return (
    <Typography
      component={'div'}
      className='d-flex-a flex-sb title-add-box'
      sx={{ padding: '14px 15px 14px 50px', justifyContent: 'unset' }}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className='private-form'
        style={{ display: 'contents' }}>
        <Grid container columnSpacing={2}>
          <Grid item md={2} sm={12} xs={12}>
            <Controller
              control={control}
              name='mergedDate'
              defaultValue={undefined}
              render={({ field: { onChange, value, name } }) => {
                return (
                  <DateTimepicker
                    onChange={(e) => {
                      onChange(e);
                    }}
                    value={value ? value : []}
                    name={name}
                    error={errors.mergedDate?.message}
                    key={key}
                  />
                );
              }}
            />
          </Grid>
          <Grid item md={1.4} sm={12} xs={12} sx={{ paddingLeft: '20px !important' }}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '100%', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='type'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={
                        Url.includes('opening-handle')
                          ? openingHandle
                          : Url.includes('cv-status')
                            ? CvStatus
                            : Url.includes('takt-time')
                              ? TaktTime
                              : Url.includes('productivity')
                                ? Productivity
                                : Url.includes('business-conversion')
                                  ? BusinessConversion
                                  : []
                      }
                      name={name}
                      report={true}
                      isClearable={true}
                      value={value ? value : null}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      placeHolder='Category'
                      error={errors.type?.message}
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.4} sm={12} xs={12} sx={{ paddingLeft: '20px !important' }}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '100%', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='role'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[
                        { label: 'Recruiter', value: 'recruiter' },
                        { label: 'CRM', value: 'crm' },
                        { label: 'Team Leader', value: 'teamleader' },
                      ]}
                      name={name}
                      isClearable={true}
                      report={true}
                      value={value ? value : null}
                      error={errors.role?.message}
                      onChange={(e: { target: { value: any } }) => {
                        setRoleData(e.target.value);
                        if (e.target.value !== value) {
                          setValue('employee', []);
                        }
                        onChange(e);
                      }}
                      placeHolder='Role'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.4} sm={12} xs={12} sx={{ paddingLeft: '20px !important' }}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '100%', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='employee'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={employeeData}
                      name={name}
                      report={true}
                      isClearable={false}
                      value={value ? value : null}
                      isMulti={true}
                      error={errors.employee?.message}
                      onChange={(e: { target: { value: string | any[] } }) => {
                        const result: any = [];
                        if (e.target.value) {
                          employeeData.forEach((item: { value: any; fullName: any }) => {
                            if (e.target.value.includes(item.value)) {
                              result.push(item.fullName);
                            }
                            setEmployeeName(result.map((name: any) => `(${name})`));
                          });

                          setParams((prev: any) => ({
                            ...prev,
                            graphSkip: 0,
                            graphTake: 10,
                            tableSkip: 0,
                            tableTake: 10,
                          }));
                        }
                        onChange(e);
                      }}
                      placeHolder='Employee'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.4} sm={12} xs={12} sx={{ paddingLeft: '20px !important' }}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '100%', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='branch'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={branchData}
                      name={name}
                      report={true}
                      isClearable={false}
                      error={errors.branch?.message}
                      value={value ? value : null}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      placeHolder='Branch'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1.4} sm={12} xs={12} sx={{ paddingLeft: '20px !important' }}>
            <Typography
              component={'div'}
              className='custom-field'
              sx={{ width: '100%', marginBottom: '6px' }}>
              <Controller
                control={control}
                name='report'
                defaultValue={undefined}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <CustomSelect
                      labelKey={'label'}
                      valueKey={'value'}
                      options={[
                        { label: 'Table', value: 'table' },
                        { label: 'Graph', value: 'graph' },
                        { label: 'Tabel-Graph', value: 'all' },
                      ]}
                      name={name}
                      report={true}
                      isClearable={false}
                      value={value ? value : null}
                      error={errors.report?.message}
                      onChange={(e: any) => {
                        onChange(e);
                      }}
                      placeHolder='Type'
                    />
                  );
                }}
              />
            </Typography>
          </Grid>
          <Grid item md={1} sm={12} xs={12}>
            <Button className='s-add-btn' type='submit' sx={{ textTransform: 'none !important' }}>
              {'Apply'}
            </Button>
          </Grid>
          <Grid
            item
            md={2}
            sm={12}
            xs={12}
            sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-start' }}>
            <img
              src={Refresh}
              alt='Reset'
              style={{ height: '30px', width: '30px', cursor: 'pointer' }}
              onClick={resetFilters}
            />
            <img
              src={Preview}
              alt='Preview'
              style={{ height: '30px', width: '30px', marginLeft: '20px', cursor: 'pointer' }}
              onClick={() => setPreviewOpen(true)}
            />
          </Grid>
        </Grid>
      </form>
    </Typography>
  );
};
