/* eslint-disable @typescript-eslint/no-unused-vars */
import BillingValue from 'assets/dashboardsvgicon/BillingValue';
import CandidateInterviewed from 'assets/dashboardsvgicon/CandidateInterviewed';
import CandidateInterviewRejected from 'assets/dashboardsvgicon/CandidateInterviewRejected';
import CandidateJoined from 'assets/dashboardsvgicon/CandidateJoined';
import CVRejected from 'assets/dashboardsvgicon/CVRejected';
import CVSent from 'assets/dashboardsvgicon/CVSent';
import CVShortlisted from 'assets/dashboardsvgicon/CVShortlisted';
import FeedbackAwaited from 'assets/dashboardsvgicon/FeedbackAwaited';
import OfferRejected from 'assets/dashboardsvgicon/OfferRejected';
import OpeningsBilled from 'assets/dashboardsvgicon/OpeningsBilled';
import OpeningsWorked from 'assets/dashboardsvgicon/OpeningsWorked';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getAllListData } from 'services/CommonServices';
import { DASHBOARD_ENDPOINT } from 'utils/Constant';
import { AccessDenied } from 'utils/helper';
import { DashboardFilterDrawer } from './DashboardFilter/DashboardFilterDrawer';
import moment from 'moment';
import { useDebounce } from 'hooks';

export const CategoryCount = ({ setLd, option, setdweropen, dweropen, setVisible }: any) => {
  const Today = new Date();
  const navigate = useNavigate();
  const Defaultparam = {
    from: moment(Today).format('YYYY-MM-DD'),
    to: moment(Today).format('YYYY-MM-DD'),
    type: option.toLowerCase(),
    reportType: null,
    clientId: null,
    dateChange: false,
  };
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const dwerclose = () => setdweropen(false);
  const debouncedParams = useDebounce(params, 500);
  const [CategoryCardData, setCategoryCardData] = React.useState<any>([]);
  const CategoryCountData = () => {
    setLd(true);
    const searcharr = [
      `${option ? `type=${option.toLowerCase()}` : null}`,
      `${debouncedParams.fromDate ? `fromDate=${debouncedParams.fromDate}` : null}`,
      `${debouncedParams.toDate ? `toDate=${debouncedParams.toDate}` : null}`,
      `${debouncedParams.clientId ? `clientId=${debouncedParams.clientId}` : null}`,
      `${debouncedParams.reportType ? `reportType=${debouncedParams.reportType}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    getAllListData(
      `${DASHBOARD_ENDPOINT.Category}${Object.values({ ...params }).length > 0 && '?'}${searcharr}`,
      true,
    )
      .then((val: any) => {
        setCategoryCardData([
          {
            title: 'Openings Worked',
            score: Number(val.data.openingsWorked),
            className: 'openings-worked',
          },
          {
            title: 'CV Sent',
            score: Number(val.data.cvSent),
            className: 'cv-sent',
          },
          {
            title: 'CV Shortlisted',
            score: Number(val.data.cvShortlisted),
            className: 'cv-shortlisted',
          },
          {
            title: 'Candidate Interviewed',
            score: Number(val.data.candidateInterviewed),
            className: 'Candidate-interviewed',
          },
          {
            title: 'Candidate Joined',
            score: Number(val.data.candidateJoined),
            className: 'candidate-joined',
          },
          {
            title: 'Billing Value',
            score: Number(val.data.billingValue),
            className: 'billing-value',
          },
          {
            title: 'Feedback Awaited',
            score: Number(val.data.feedbackAwaited),
            className: 'feedback-awaited',
          },
          {
            title: 'CV Rejected',
            score: Number(val.data.cvRejected),
            className: 'cv-rejected',
          },
          {
            title: 'Candidate Interview Rejected',
            score: Number(val.data.candidateInterviewRejected),
            className: 'Candidate-interview-rejected',
          },
          {
            title: 'Candidate Offered',
            score: Number(val.data.candidateOffered),
            className: 'candidate-offered',
          },

          {
            title: 'Offer Rejected',
            score: Number(val.data.offerRejected),
            className: 'offer-rejected',
          },

          {
            title: 'Openings Billed',
            score: Number(val.data.openingsBilled),
            className: 'openings-billed',
          },
        ]);
        setLd(false);
        setdweropen(false);
      })
      .catch((err) => {
        if (err === 'Forbidden resource') {
          toast.error(AccessDenied);
          navigate('/dashboard');
        }
        setLd(false);
      });
  };
  React.useEffect(() => {
    CategoryCountData();
  }, [debouncedParams, option]);
  return (
    <div className='top-card-container'>
      <div className='card-container'>
        {CategoryCardData.map((er: any) => {
          return (
            <>
              <div className={`cards ${er && er.className ? er.className : ''}`}>
                <div className={'image'}>
                  {er.title === 'Openings Worked' ? (
                    <OpeningsWorked />
                  ) : er.title === 'CV Sent' ? (
                    <CVSent />
                  ) : er.title === 'CV Shortlisted' ? (
                    <CVShortlisted />
                  ) : er.title === 'Candidate Interviewed' ? (
                    <CandidateInterviewed />
                  ) : er.title === 'Candidate Joined' ? (
                    <CandidateJoined />
                  ) : er.title === 'Billing Value' ? (
                    <BillingValue />
                  ) : er.title === 'Feedback Awaited' ? (
                    <FeedbackAwaited />
                  ) : er.title === 'CV Rejected' ? (
                    <CVRejected />
                  ) : er.title === 'Candidate Interview Rejected' ? (
                    <CandidateInterviewRejected />
                  ) : er.title === 'Candidate Offered' ? (
                    <CVShortlisted />
                  ) : er.title === 'Offer Rejected' ? (
                    <OfferRejected />
                  ) : er.title === 'Openings Billed' ? (
                    <OpeningsBilled />
                  ) : null}
                </div>
                <div className='text'>
                  <div className='heading'>{er.title}</div>
                  {er.title === 'Billing Value' ? (
                    <div className='description'>
                      <TableTooltip
                        value={new Intl.NumberFormat('en-IN', {
                          style: 'currency',
                          currency: 'INR',
                          minimumFractionDigits: 2,
                        })
                          .format(er.score)
                          .replace('₹', '₹ ')}
                      />
                    </div>
                  ) : (
                    <div className='description'>{er.score}</div>
                  )}
                </div>
              </div>
            </>
          );
        })}
      </div>
      <DashboardFilterDrawer
        setParams={setParams}
        isOpen={dweropen}
        closeDrawer={dwerclose}
        setLoading={setLd}
        setVisible={setVisible}
        option={option}
      />
    </div>
  );
};
