/* eslint-disable react/prop-types */
import React from 'react';
import CloseIcon from 'assets/png/close.svg';
import { Box, Button, Grid, Modal, TextField, Typography } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { Controller, useForm } from 'react-hook-form';
import { CommonFilter } from 'utils/ValidatorSchema';
import moment from 'moment';

export const CommomFilter = ({
  handleOpen,
  handleClose,
  setParams,
  params,
  setVisible,
  getApi,
  type,
}: any) => {
  const Today = new Date();
  const oneWeekBefore = new Date(Today);
  oneWeekBefore.setDate(Today.getDate() - 7);
  const getFirstDateThreeMonthsAgo = () => {
    const currentDate = new Date();
    currentDate.setDate(1);
    currentDate.setMonth(currentDate.getMonth() - 6);
    return currentDate.toISOString().split('T')[0];
  };
  const { handleSubmit, control, setValue } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      from: '',
      to: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(CommonFilter),
  });
  const onSubmit = (data: any) => {
    setParams((prev: any) => ({
      ...prev,
      from: data.from ? data.from : null,
      to: data.to ? data.to : null,
      dataChange: true,
    }));
    setVisible(false);
  };
  const Clear = () => {
    setParams((prev: any) => ({
      ...prev,
      from:
        type === 'financial'
          ? moment(getFirstDateThreeMonthsAgo()).format('YYYY-MM-DD')
          : type === 'producivity' || type === 'businessConversion'
            ? moment().clone().subtract(1, 'months').format('YYYY-MM-DD')
            : moment(oneWeekBefore).format('YYYY-MM-DD'),
      to: moment(Today).format('YYYY-MM-DD'),
      dataChange: false,
    }));
    setVisible(true);
    getApi();
  };
  React.useEffect(() => {
    if (params) {
      if (params?.dataChange) {
        setValue('from', params.from);
        setValue('to', params.to);
        setVisible(false);
      }
    }
  }, [params]);
  return (
    <Modal
      open={handleOpen}
      onClose={close}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'>
      <Box className='logout-modal'>
        <Typography className='text-right pointer'>
          <img src={CloseIcon} alt='close' onClick={handleClose} />
        </Typography>
        <form
          id={'common-form'}
          className='private-form clientfilter-form'
          onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item md={5} sm={12} xs={12} sx={{ width: '100%' }}>
              <Typography component={'div'} className='custom-field' sx={{ width: '100%' }}>
                <Typography component={'p'}>From Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='from'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        defaultValue={value}
                        className='clientfilter-time-from-to'
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={5} sm={12} xs={12}>
              <Typography component={'div'} className='custom-field'>
                <Typography component={'p'}>To Date</Typography>
                {/* <TextField className="custom-input" id="standard-basic" placeholder="" variant="standard" /> */}
                <Controller
                  control={control}
                  defaultValue={''}
                  name='to'
                  render={({ field: { onChange, value } }) => {
                    return (
                      <TextField
                        id='date'
                        type='date'
                        className='clientfilter-time-from-to'
                        defaultValue={value}
                        onChange={(e) => {
                          onChange(e);
                        }}
                        // className={classes.textField}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    );
                  }}
                />
              </Typography>
            </Grid>
            <Grid item md={2} sm={12} xs={12}>
              <div
                className='view-all'
                style={{ justifyContent: 'end', marginTop: '10px' }}
                onClick={Clear}>
                Clear Filter
              </div>
              <Typography className='d-flex-ja' style={{ marginTop: '8px ' }}>
                <Button
                  className='l-save-btn'
                  form={'common-form'}
                  type={'submit'}
                  style={{ width: '80px' }}>
                  Apply
                </Button>
              </Typography>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Modal>
  );
};
