import { Checkbox, FormControlLabel, Grid, styled, Typography } from '@mui/material';
import CustomInput from 'components/CustomInput/customInput';
// import AddIcon from 'assets/iconcomponents/addicon';
// import Deleteimgicon from 'assets/png/Deleteblack.svg';
import { Controller } from 'react-hook-form';
import Add from 'assets/png/AddEducation.png';
import Delete from 'assets/png/DeleteExp.png';
// import CustomDatePicker from 'components/MonthRange';

const CheckIcon = styled('span')(({ theme }) => ({
  borderRadius: 4,
  width: 16,
  height: 16,
  outline: '1px solid #EE4B22',
  backgroundColor: theme.palette.mode === 'dark' ? '#fff' : '#fff',
  '.Mui-focusVisible &': {
    outline: '1px auto #EE4B22',
    outlineOffset: 1,
  },
  'input:hover ~ &': {
    backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#ffffff',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: theme.palette.mode === 'dark' ? 'rgba(57,75,89,.5)' : 'rgba(206,217,224,.5)',
  },
}));

const CheckedIcon = styled(CheckIcon)({
  backgroundColor: '#EE4B22',
  '&:before': {
    display: 'block',
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#EE4B22',
  },
});
export const ExperienceDetails = ({
  fields,
  append,
  remove,
  //   replace,
  control,
  //   reset,
  setValue,
  //   setError,
  //   watch,
  getValues,
  errors,
  trigger,
  setRemoveId,
  removeId,
}: any) => {
  const removeSpecialization = (index: number, id: string) => {
    if (id) {
      removeId.push(id);
      setRemoveId([...removeId]);
    }
    remove(index);
  };
  return (
    <div className='mt-15'>
      {fields && fields.length >= 0
        ? fields.map((item: any, index: number) => {
          return (
            <div key={item.id}>
              <Grid container columnSpacing={4.25}>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Company Name <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`experienceData.${index}.companyName`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Company Name'
                            error={
                              errors?.experienceData &&
                              errors?.experienceData?.[index] &&
                              errors?.experienceData?.[index]?.companyName?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Designation <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`experienceData.${index}.designation`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Designation'
                            error={
                              errors?.experienceData &&
                              errors?.experienceData?.[index] &&
                              errors?.experienceData?.[index]?.designation?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Grid item md={12} sm={12} xs={12}>
                      <Typography component={'p'}>
                        Employment Period <span>*</span>
                      </Typography>
                      <Typography component={'p'} style={{ display: 'flex', gap: '10px' }}>
                        <Controller
                          name={`experienceData.${index}.fromDate`}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              // <CustomDatePicker
                              //   className='custom-datepicker'
                              //   views='MM/YYYY'
                              //   name={name}
                              //   onChange={(e: any) => {
                              //     onChange(e);
                              //   }}
                              //   value={value}
                              //   error={
                              //     errors?.experienceData &&
                              //     errors?.experienceData?.[index] &&
                              //     errors?.experienceData?.[index]?.fromDate?.message
                              //   }
                              // />

                              <CustomInput
                                value={value}
                                name={name}
                                className='custom-input'
                                placeHolder='From'
                                error={
                                  errors?.experienceData &&
                                  errors?.experienceData?.[index] &&
                                  errors?.experienceData?.[index]?.fromDate?.message
                                }
                                onChange={(e) => {
                                  trigger(`experienceData.${index}.fromDate`);
                                  onChange(e);
                                }}
                              />
                            );
                          }}
                          control={control}
                        />
                        <Typography component={'p'}>
                          <Controller
                            name={`experienceData.${index}.toDate`}
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomInput
                                  value={value}
                                  name={name}
                                  disabled={
                                    getValues(`experienceData.${index}.tillDate`) === true
                                      ? true
                                      : false
                                  }
                                  className='custom-input'
                                  placeHolder='To'
                                  error={
                                    errors?.experienceData &&
                                    errors?.experienceData?.[index] &&
                                    errors?.experienceData?.[index]?.toDate?.message
                                  }
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                />
                              );
                            }}
                            control={control}
                          />
                          {index === 0 ? (
                            <Typography
                              component={'p'}
                              style={{ display: 'flex', marginTop: '10px' }}>
                              <Controller
                                name={`experienceData.${index}.tillDate`}
                                render={({ field: { onChange, value, name } }) => (
                                  <FormControlLabel
                                    label=''
                                    control={
                                      <Checkbox
                                        className='custom-checkbox'
                                        value={value}
                                        name={name}
                                        checked={getValues(`experienceData.${index}.tillDate`)}
                                        sx={{
                                          '&:hover': { bgcolor: 'transparent' },
                                        }}
                                        color='default'
                                        checkedIcon={<CheckedIcon />}
                                        icon={<CheckIcon />}
                                        onChange={(e) => {
                                          setValue(`experienceData.${index}.toDate`, '');
                                          onChange(e);
                                          trigger(`experienceData.${index}.toDate`);
                                        }}
                                      />
                                    }
                                  />
                                )}
                                control={control}
                              />
                              Till Date
                            </Typography>
                          ) : (
                            ''
                          )}
                        </Typography>
                      </Typography>
                    </Grid>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={4.25}>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Areas of Expertise <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`experienceData.${index}.specialization`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Areas of Expertise'
                            error={
                              errors?.experienceData &&
                              errors?.experienceData?.[index] &&
                              errors?.experienceData?.[index]?.specialization?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Project / Certificate Details (optional)
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`experienceData.${index}.projectAndCertifications`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Project / Certificate Details'
                            error={
                              errors?.experienceData &&
                              errors?.experienceData?.[index] &&
                              errors?.experienceData?.[index]?.projectAndCertifications?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              <Grid container columnSpacing={4.25}>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Location <span>*</span>
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`experienceData.${index}.location`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Location'
                            error={
                              errors?.experienceData &&
                              errors?.experienceData?.[index] &&
                              errors?.experienceData?.[index]?.location?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
                <Grid item md={8} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Other Vital Information(Position Specific)
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`experienceData.${index}.vitalInformation`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Vital Information'
                            error={
                              errors?.experienceData &&
                              errors?.experienceData?.[index] &&
                              errors?.experienceData?.[index]?.vitalInformation?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid>
              {index === 0 ? 
              <Grid container columnSpacing={4.25}>
                <Grid item md={4} sm={12} xs={12}>
                  <Typography component={'div'} className='custom-field'>
                    <Typography component={'p'}>
                      Reporting To {index === 0 ? <span>*</span> : ''}
                    </Typography>
                    <Controller
                      control={control}
                      defaultValue={''}
                      name={`experienceData.${index}.reportingTo`}
                      render={({ field: { onChange, value, name } }) => {
                        return (
                          <CustomInput
                            value={value}
                            name={name}
                            className='custom-input'
                            placeHolder='Enter Reporting'
                            error={
                              errors?.experienceData &&
                              errors?.experienceData?.[index] &&
                              errors?.experienceData?.[index]?.reportingTo?.message
                            }
                            onChange={(e) => {
                              onChange(e);
                            }}
                          />
                        );
                      }}
                    />
                  </Typography>
                </Grid>
              </Grid> : ''}
              <Grid
                item
                md={12}
                sm={12}
                xs={12}
                sx={{
                  display: 'flex',
                  marginTop: '10px',
                  marginBottom: '30px',
                  fontSize: '14px',
                }}>
                <span
                  style={{
                    cursor: 'pointer',
                    marginRight: '24px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  onClick={() => {
                    append({
                      companyName: '',
                      designation: '',
                      fromDate: '',
                      toDate: '',
                      location: '',
                      reportingTo: '',
                      specialization: '',
                      projectAndCertifications: '',
                      vitalInformation: '',
                      tillDate: false,
                    });
                  }}>
                  <img
                    src={Add}
                    alt='Add'
                    style={{
                      marginRight: '5px',
                      marginBottom: '2px',
                    }}
                  />
                  Add Experience
                </span>
                {index !== 0 ? (
                  <div>
                    <span
                      style={{
                        cursor: 'pointer',
                        marginRight: '24px',
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: '14px',
                      }}
                      onClick={() => {
                        removeSpecialization(index, item.rowId);
                      }}>
                      <img
                        src={Delete}
                        alt='delete'
                        style={{
                          marginRight: '5px',
                          marginBottom: '5px',
                        }}
                      />
                      Delete Experience
                    </span>
                  </div>
                ) : (
                  <></>
                )}
              </Grid>
            </div>
          );
        })
        : ''}
    </div>
  );
};
