import {
  Box,
  Breadcrumbs,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import HomeIcon from 'assets/png/dashboardactive.svg';
import ArrowIcon from 'assets/png/breadarrow.svg';
import SearchIcon from 'assets/png/nsearch.svg';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { To, useNavigate } from 'react-router-dom';
import { getAllListData, patchData } from 'services/CommonServices';
import { useEffect, useRef, useState } from 'react';
import { useDebounce } from 'hooks';
import { PositionENDPOINT } from 'utils/Constant';
import ConfirmDialog from 'components/ConfirmDialog/ConfirmDialog';
import Loader from 'components/loader';
import { useAppSelector } from 'store/hooks';
import UP from 'assets/png/up.svg';
import DOWN from 'assets/png/down.svg';
import Paginate from 'components/Pagination/Pagination';
import { AdminPrivateRoutes } from 'routes/routeConstans';
import PreIcon from 'assets/png/pre.svg';
import approve from 'assets/png/joined.svg';
import reject from 'assets/png/not-joined.svg';
import ClientSuccessIcon from 'assets/png/clientsuccessicon.png';
import ClientFailIcon from 'assets/png/rejectCrm.png';
import NCAdedIcon from 'assets/iconcomponents/noclientsadded';
import Funnelimg from 'assets/png/FilterFunnel.svg';
import moment from 'moment';
import _ from 'lodash';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import { PositionFilterDrawer } from '../../PositionFilter/PositionFilterDrawer';

const Defaultparam = {
  search: '',
  order: '',
  skip: 0,
  sortby: '',
  status: '',
  take: 50,
  from: '',
  to: '',
  crm: '',
  organizationId: '',
  address: '',
  pincode: '',
  type: '',
};

const CrmChangeApproval = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const {
    userInfo: { organizationId: orgdy, id: Useruniqueid },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [commonstate, setcommonstate] = useState<any[]>([]);
  const [isRejectionModalOpened, setRejectionModalOpen] = useState({
    approvalStatus: '',
    open: false,
  });
  const [pagetotal, setpagetotal] = useState<number>(0);
  const [params, setParams] = useState<any>({ ...Defaultparam });
  const [Ld, setLd] = useState(false);
  const debouncedParams = useDebounce(params, 500);
  const [confirmOpen, setConfirmOpen] = useState<any>({
    show: false,
    id: '',
  });
  const [scrollPosition, setScrollPosition] = useState(0);
  const containerRef = useRef(null) as any;
  // Function to handle scrolling when the button is clicked
  const handleScroll = (scrollAmount: number) => {
    // Calculate the new scroll position
    const newScrollPosition = scrollPosition + scrollAmount;
    // Update the state with the new scroll position
    if (newScrollPosition >= 0 && newScrollPosition <= 800) {
      setScrollPosition(newScrollPosition);
      const element = containerRef.current;
      // Access the container element and set its scrollLeft property
      element.scrollLeft = newScrollPosition;
    }
  };
  const handleCloseModel = () => {
    setRejectionModalOpen((prev: any) => ({
      ...prev,
      open: false,
      approvalStatus: '',
    }));
  };
  const gotoNavigate = (link: To) => {
    navigate(link);
  };

  const listdataapi = () => {
    const searcharr = [
      `${orgdy ? `organizationId=${orgdy}` : null}`,
      `${debouncedParams.search ? `search=${encodeURIComponent(debouncedParams.search)}` : null}`,
      `${debouncedParams.from ? `from=${debouncedParams.from}` : null}`,
      `${debouncedParams.to ? `to=${debouncedParams.to}` : null}`,
      `${debouncedParams.crm ? `crm=${debouncedParams.crm}` : null}`,
      `${debouncedParams.order ? `order=${debouncedParams.order}` : null}`,
      `${debouncedParams.sortby ? `sortby=${debouncedParams.sortby}` : null}`,
      `${Useruniqueid ? `l1Reporting=${Useruniqueid}` : null}`,
      `${
        debouncedParams.skip
          ? `skip=${debouncedParams.skip}`
          : debouncedParams.take
            ? 'skip=0'
            : null
      }`,
      `${debouncedParams.take ? `take=${debouncedParams.take}` : null}`,
    ]
      .filter((ea: any) => ea !== 'null')
      .join('&');
    setLd(true);
    getAllListData(
      `${PositionENDPOINT.CRMChange}${
        Object.values({ ...params }).length > 0 && '?'
      }${searcharr}&approvalStatus=pending`,
      true,
    )
      .then((val: any) => {
        if (val && val.data && val.data.length > 0) {
          setcommonstate([...val.data]);
          setpagetotal(Number(val.total));
          // val.totalInReview ? setapprovetotal(Number(val.totalInReview)):'';
        } else {
          setcommonstate([]);
        }
        setLd(!true);
      })
      .catch(() => {
        setLd(!true);
      });
  };

  useEffect(() => {
    listdataapi();
  }, [debouncedParams]);

  const sortablecliks: (sor: string, ord: string) => void = (sor: string, ord: string) => {
    setParams((prev: any) => ({
      ...prev,
      sortby: sor ? sor : null,
      order: ord ? ord : null,
    }));
  };

  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      skip: sk,
      take: tk,
    }));
  }
  // function handleFilterPag(filteredobj1: any) {
  //   setParams((prev: any) => ({
  //     ...prev,
  //     ...filteredobj1,
  //   }));
  // }
  const approveRejectClient = (approvalStatus: string, id: string) => {
    patchData(
      '',
      { approvalStatus: approvalStatus },
      `${PositionENDPOINT.CRMChange}/approval/${id}`,
      true,
    )
      .then(() => {
        listdataapi();
        setRejectionModalOpen((prev: any) => ({
          ...prev,
          open: true,
          approvalStatus: approvalStatus,
        }));
        // navigate(AdminPrivateRoutes.MyPlan.MyPlanVIEW);
      })
      .catch((e: any) => console.log(e));
  };
  const [dweropen, setdweropen] = useState(false);
  const dweropn = () => setdweropen(true);
  const dwerclose = () => setdweropen(false);
  return (
    <>
      <Grid container>
        <Grid item md={12} sm={12} xs={12}>
          <Typography component={'div'} className='breadcrumbs d-flex-a'>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link underline='hover' color='inherit' href='/'>
                <img src={HomeIcon} className='home' alt='Home' />
              </Link>
              <Typography
                color='text.primary'
                sx={{ cursor: 'pointer' }}
                onClick={() => gotoNavigate(AdminPrivateRoutes.POSITIONS.POSITIONSVIEW)}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> Positions
              </Typography>
              <Typography color='text.primary' sx={{ cursor: 'pointer' }}>
                {' '}
                <img src={ArrowIcon} alt='arrow' className='arrows' /> CRM Change Approval
              </Typography>
            </Breadcrumbs>
          </Typography>

          <Typography
            component={'div'}
            className='d-flex-a flex-sb title-add-box'
            sx={{ paddingRight: '56px' }}>
            <Typography className='page-title'>CRM Change Approval</Typography>
          </Typography>
          <Typography component={'div'} className='main-content'>
            <Typography component={'div'} className='white-box'>
              <Typography component={'div'} className='d-flex-cc flex-sb'>
                <TextField
                  className='custom-search'
                  placeholder='Search'
                  id='outlined-start-adornment'
                  value={params.search}
                  sx={{ width: '320px' }}
                  onChange={(e: any) =>
                    setParams((prev: any) => ({
                      ...prev,
                      search: e.target.value,
                      skip: 0,
                      take: 50,
                    }))
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <img src={SearchIcon} alt='search' />
                      </InputAdornment>
                    ),
                    endAdornment: params.search ? (
                      <InputAdornment position='end'>
                        <IconButton
                          aria-label='toggle password visibility'
                          edge='end'
                          onClick={() => {
                            setParams(() => ({ ...Defaultparam, search: '' }));
                          }}>
                          <CloseIcon style={{ height: '20px', width: '20px' }} />
                        </IconButton>
                      </InputAdornment>
                    ) : null,
                  }}
                />
                <Typography
                  component={'div'}
                  className='d-flex-cc flex-sb d-flex-nwrp filterfunnel-con'
                  onClick={dweropn}>
                  <img src={Funnelimg} alt='filter funnel icon' />
                  <span className='filterfunnel'>More Filters</span>
                </Typography>
              </Typography>
              <Typography component={'div'} className='mt-32' sx={{ position: 'relative' }}>
                <Button onClick={() => handleScroll(-200)} className='pre-btn'>
                  <img src={PreIcon} alt='' />
                </Button>
                <Button onClick={() => handleScroll(200)} className='next-btn'>
                  <img src={PreIcon} alt='' style={{ transform: 'rotate(180deg)' }} />
                </Button>
                <TableContainer component={Paper} className='sb-table2' ref={containerRef}>
                  <Table sx={{ minWidth: 650 }} className='custom-table' aria-label='simple table'>
                    <TableHead>
                      <TableRow
                        sx={{
                          marginLeft: '50px',
                          marginRight: '43px',
                          width: 'calc(100% - 93px)',
                        }}>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Job Title
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('title', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('title', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Client
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('client', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('client', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Created by
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('totalOpenins', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('totalOpenins', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Info
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('crm', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('crm', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Remarks
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('recruiter', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('recruiter', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Modified on
                          <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('createdBy', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('createdBy', 'desc')}
                            />
                          </span>
                        </TableCell>
                        <TableCell align='center' sx={{ whiteSpace: 'nowrap' }}>
                          Status
                          {/* <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('approvalStatus', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('approvalStatus', 'desc')}
                            />
                          </span> */}
                        </TableCell>
                        <TableCell sx={{ whiteSpace: 'nowrap' }}>
                          Action
                          {/* <span className='sorting'>
                            <img
                              src={UP}
                              alt='asc'
                              className='up'
                              onClick={() => sortablecliks('user', 'asc')}
                            />
                            <img
                              src={DOWN}
                              alt='desc'
                              className='down'
                              onClick={() => sortablecliks('user', 'desc')}
                            />
                          </span> */}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {commonstate && commonstate.length > 0 ? (
                        commonstate.map((row) => (
                          <TableRow
                            key={row.id}
                            sx={{
                              '&:last-child td, &:last-child th': { border: 0 },
                              // verticalAlign: 'baseline',
                              marginLeft: '50px',
                              marginRight: '43px',
                              width: 'calc(100% - 93px)',
                            }}>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                whiteSpace: 'nowrap',
                                minWidth: '120px',
                              }}>
                              <div>
                                <TableTooltip
                                  style={{ color: '#5F2EE5' }}
                                  value={row && row.position.title ? `${row.position.title}` : '-'}
                                  position={true}></TableTooltip>
                              </div>
                            </TableCell>
                            <TableCell sx={{ textTransform: 'capitalize', minWidth: '110px' }}>
                              <TableTooltip
                                value={
                                  row &&
                                  row.position.client &&
                                  !_.isEmpty(row.position.client.corporateName)
                                    ? `${row.position.client.corporateName}    `
                                    : '-'
                                }></TableTooltip>
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '100px',
                                textAlign: 'center !important',
                              }}>
                              {row && row.createdByUser
                                ? `${row.createdByUser.firstName} ${row.createdByUser.lastName}`
                                : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                maxWidth: '500px',
                                minWidth: '100px',
                                hypens: 'auto',
                              }}>
                              Current CRM :
                              {row?.crm
                                ? `${row?.crm?.user?.firstName} ${row?.crm?.user
                                    ?.lastName}(${moment(row?.from).format('DD-MM-YYYY')})`
                                : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                maxWidth: '200px',
                                minWidth: '100px',
                                hypens: 'auto',
                              }}>
                              {row?.remarks ? row?.remarks : '-'}
                            </TableCell>
                            <TableCell
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '100px',
                                hyphens: 'auto',
                              }}>
                              {row?.updatedAt ? moment(row?.updatedAt).format('DD-MM-YYYY') : '-'}
                            </TableCell>
                            <TableCell
                              align='center'
                              sx={{
                                textTransform: 'capitalize',
                                minWidth: '200px',
                                hyphens: 'auto',
                              }}>
                              {
                                <button className='position-view-btn awaitingApprovalCRM'>
                                  <span>Awaiting Approval</span>
                                </button>
                              }
                            </TableCell>
                            <TableCell
                              align='center'
                              sx={{
                                minWidth: '100px',
                                hyphens: 'auto',
                              }}>
                              <span>
                                <img
                                  style={{ height: '25px', width: '25px' }}
                                  src={approve}
                                  alt='approve'
                                  className='pointer'
                                  onClick={() => {
                                    approveRejectClient('approved', row.id);
                                  }}
                                />{' '}
                              </span>
                              <span>
                                <img
                                  style={{ height: '25px', width: '25px' }}
                                  src={reject}
                                  alt='reject'
                                  className='pointer'
                                  onClick={() => {
                                    approveRejectClient('rejected', row.id);
                                  }}
                                />
                              </span>
                            </TableCell>
                          </TableRow>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={8} align={'center'}>
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                marginBottom: '43px',
                              }}>
                              <NCAdedIcon />
                              <Typography className='no-client'>No Position added</Typography>
                            </div>
                          </td>
                        </tr>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Typography>
              <Paginate total={pagetotal} setfn={handlePag} DefaultPerPage={50} />
            </Typography>
          </Typography>
        </Grid>
        <PositionFilterDrawer
          setParams={setParams}
          isOpen={dweropen}
          closeDrawer={dwerclose}
          // callback={handleFilterPag}
          setcommonstate={setcommonstate}
          setLoading={setLd}
          setpagetotal={setpagetotal}
          listdataapi={listdataapi}
        />
        <ConfirmDialog
          title='Confirm'
          floatingBtnShow={true}
          open={confirmOpen.show}
          close={() =>
            setConfirmOpen({
              show: false,
              id: '',
            })
          }
          visibleClose={true}
          onConfirm={(act: any) =>
            act === 'yes'
              ? ''
              : setConfirmOpen({
                  show: false,
                  id: '',
                })
          }
          autoCloseDisable={true}>
          {'Are you sure do you want to delete?'}
        </ConfirmDialog>
        <Modal
          open={isRejectionModalOpened.open}
          onClose={handleCloseModel}
          aria-labelledby='modal-modal-title'
          aria-describedby='modal-modal-description'>
          <Box className='logout-modal' style={{ paddingLeft: '0px', paddingRight: '0px' }}>
            <Typography
              className='d-flex-ja mb-24'
              sx={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
              <img
                src={
                  isRejectionModalOpened.approvalStatus === 'approved'
                    ? ClientSuccessIcon
                    : ClientFailIcon
                }
                alt='client success'
              />
              <Typography className='client-sucess-con'>
                <Typography className='title' style={{ marginBottom: '9px' }}>
                  {isRejectionModalOpened.approvalStatus === 'approved'
                    ? 'CRM Change Successfully Approved!'
                    : 'CRM Change Rejected'}
                </Typography>
              </Typography>
              <Button className='client-success-modal-btn' onClick={handleCloseModel}>
                Ok
              </Button>
            </Typography>
            <CloseIcon
              onClick={handleCloseModel}
              style={{
                cursor: 'pointer',
                height: '20px',
                width: '20px',
                position: 'absolute',
                top: '16px',
                right: '16px',
              }}
            />
          </Box>
        </Modal>
      </Grid>
      {Ld && <Loader />}
    </>
  );
};

export default CrmChangeApproval;
