import './index.scss';
import { PieChart, Pie, Cell, Tooltip } from 'recharts';

const CustomTooltip = ({ active, payload, coordinate }: any) => {
  if (active && payload && payload.length) {
    const { name, value } = payload[0].payload; // Get name and value from the payload

    // Calculate tooltip position based on coordinates
    const { x, y } = coordinate;

    return (
      <div
        style={{
          position: 'absolute', // Position tooltip absolutely
          left: x + 30, // Offset to the right of the cursor (adjust as needed)
          top: y - 20, // Offset above the cursor (adjust as needed)
          backgroundColor: 'white', // White background
          padding: '8px',
          borderRadius: '4px',
          color: 'black', // Black text
          fontSize: '14px',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)', // Optional shadow for better visibility
          pointerEvents: 'none', // Prevent mouse interactions with the tooltip
          transform: 'translate(-50%, -100%)', // Adjust positioning for better visuals
        }}>
        <p style={{ margin: 0 }}>
          <strong>{name}</strong>
        </p>
        <p style={{ margin: 0 }}>
          Value: <strong>{value}</strong>
        </p>
      </div>
    );
  }
  return null;
};

export default function Donutreactchart({ resumeData, interview }: any) {
  const total = resumeData.reduce(
    (accumulator: any, currentItem: { value: any }) => accumulator + currentItem.value,
    0,
  );
  return (
    <div className={interview ? 'donutchartcon-in' : 'donutchartcon'}>
      <PieChart width={215} height={215}>
        <Pie
          data={resumeData}
          cx={107.5}
          cy={107.5}
          innerRadius={60}
          outerRadius={80}
          fill='#8884d8'
          paddingAngle={5}
          dataKey='value'
          nameKey='name'>
          {resumeData.map((entry: any, index: number) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {/* Custom Tooltip with white background and black text */}
        <Tooltip content={<CustomTooltip />} />
      </PieChart>
      <div className='totalcon'>
        <div className='total'>Total</div>
        <div className='value d-flex-cc'>{total}</div>
      </div>
    </div>
  );
}
