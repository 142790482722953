import { Navigate, Route, Routes } from 'react-router-dom';
import { AdminPrivateRoutes as AdminPrivateRoutesDirectives } from './routeConstans';
import FunctionalArea from 'pages/Admin/settings/functionalarea';
import Branches from 'pages/Admin/settings/branches';
import BranchesAdd from 'pages/Admin/settings/branches/Add';
import QualificationAdd from 'pages/Admin/settings/qualification/Add';
import PrivateLayout from 'pages/Admin';
import Qualification from 'pages/Admin/settings/qualification';
import FunctionalAreaAdd from 'pages/Admin/settings/functionalarea/Add';
import Myprofile from 'pages/private/Myprofile';
import ChangePassword from 'pages/private/ChangePassword';
import Designation from 'pages/Admin/settings/designation';
import DesignationAdd from 'pages/Admin/settings/designation/Add';
import Roles from 'pages/Admin/settings/roles';
import RoleAdd from 'pages/Admin/settings/roles/Add';
import Users from './../pages/Admin/settings/Users/index';
import UsersAdd from 'pages/Admin/settings/Users/Add';
import DailyLoginDetails from 'pages/Admin/settings/dailylogindetails';
import ProductAdministrator from 'pages/Admin/settings/ProductAdministrator';
import ProductAdministratorAdd from 'pages/Admin/settings/ProductAdministrator/Add';
import EmailTemplate from 'pages/Admin/settings/emailtemplate';
import Clients from 'pages/Admin/client/clients';
import ClientsAdd from 'pages/Admin/client/clients/Add';
import CLIENTAPPROVE from 'pages/Admin/client/clients/Approve';
import Dashboard from 'pages/private/Dashboard';
import Positions from 'pages/Admin/position';
import AddPositions from 'pages/Admin/position/Add';
import ViewPositions from 'pages/Admin/position/viewposition/viewposition';
import APPROVEPOSITION from 'pages/Admin/position/approveposition/approveposition';
import MyPlan from 'pages/Admin/Myplan';
import AddMyplan from 'pages/Admin/Myplan/AddMyPlan';
import ResumeList from 'pages/Admin/Resume/List';
import ResumeView from 'pages/Admin/Resume/View';
import AddResumes from 'pages/Admin/Resume/AddResume';
import { InterviewList } from 'pages/Admin/Interview/List';
import { InterviewView } from 'pages/Admin/Interview/View';
import ClientViewDetails from 'pages/Admin/client/clients/ClientViewDetail';
import Billing from 'pages/Admin/Billing';
import BillingView from 'pages/Admin/Billing/View';
import BillingAdd from 'pages/Admin/Billing/Add';
import BillingApprove from 'pages/Admin/Billing/Approve';
import AppoveLeaveDetails from 'pages/Admin/MyLeave/ApproveLeaveDetails';
import { MailboxView } from 'pages/Admin/MailBox/View';
import { MailboxList } from 'pages/Admin/MailBox/List';
import ViewLeaveList from 'pages/Admin/MyLeave/ViewLeaveList';
import CvBank from 'pages/Admin/CvBank';
import CrmChangeApproval from 'pages/Admin/position/Add/CrmChangeApproval';
import { HolidayList } from 'pages/Admin/settings/leaves/holiday';
import HolidayAdd from 'pages/Admin/settings/leaves/holiday/Add';
import { IndustryAdd } from 'pages/Admin/settings/industry/Add';
import { IndustryList } from 'pages/Admin/settings/industry';
import AddEvents from 'pages/Admin/MyEvents/Add';
import ViewEvents from 'pages/Admin/MyEvents/ViewAllEvents';
import MyEvents from 'pages/Admin/MyEvents';
import LeavePolicy from 'pages/Admin/settings/leavePolicy';
import AddLeavePolicy from 'pages/Admin/settings/leavePolicy/AddLeavePolicy';
import { ImportLeaveList } from 'pages/Admin/settings/leaves/importLeaves';
import { PaymentMail } from 'pages/Admin/PaymentMail';
import PaymentMailView from 'pages/Admin/PaymentMail/View';
import { ClientAgreement } from 'pages/Admin/ClientAgreement';
import { ClientAgreementView } from 'pages/Admin/ClientAgreement/View';
import { VersionHistoryList } from 'pages/Admin/VersionHistory';
import { VersionHistoryAdd } from 'pages/Admin/VersionHistory/ADD';
import { VersionHistoryView } from 'pages/Admin/VersionHistory/View';
import { useAppSelector } from 'store/hooks';
// import { DummyDashboard } from 'pages/private/Dashboard/DummyDashboard';
import { OpeningHandle } from 'pages/Admin/Reports/OpeningHandle';
import { CvStatus } from 'pages/Admin/Reports/CvStatus';
import { BusinessConversion } from 'pages/Admin/Reports/BusinessConversion';
import { Productivity } from 'pages/Admin/Reports/Productivity';
import { TakTime } from 'pages/Admin/Reports/TakTime';
import CtcSlab from 'pages/Admin/settings/CtcSlab';
import CtcSlabAdd from 'pages/Admin/settings/CtcSlab/Add';

const AdminPrivateRoutes = () => {
  // const dashBoard = process.env.REACT_APP_ENV === 'DEVELOPMENT';
  const { menuList }: any = useAppSelector((store: any) => store.userLogin);
  const Setting = menuList?.find((e: any) => e?.moduleName === 'Settings');
  const BillingScr = menuList?.find((e: any) => e.moduleName === 'Billing');
  const ClinetScr = menuList?.find((e: any) => e.moduleName === 'Client');
  const branchRoute = Setting?.screens?.find((a: any) => a.screenName === 'Branches');
  const dailyLoginRoute = Setting?.screens?.find(
    (a: any) => a.screenName === 'Daily Login Details',
  );
  const desigantionRoute = Setting?.screens?.find((a: any) => a.screenName === 'Designation');
  const emailTempRoute = Setting?.screens?.find((a: any) => a.screenName === 'Email Templates');
  const functionalAreaRoute = Setting?.screens?.find(
    (a: any) => a.screenName === 'Functional Area',
  );
  const CTCSlab = Setting?.screens?.find((a: any) => a.screenName === 'CTC Slab');
  const industryRoute = Setting?.screens?.find((a: any) => a.screenName === 'Industry');
  const leavePolicyRoute = Setting?.screens?.find((a: any) => a.screenName === 'Leave Policy');
  const holidayRoute = Setting?.screens?.find((a: any) => a.screenName === 'Holiday');
  const importLeaveRoute = Setting?.screens?.find((a: any) => a.screenName === 'Import Leaves');
  const productAdminRoute = Setting?.screens?.find((a: any) => a.screenName === 'Product Admin');
  const qualificationRoute = Setting?.screens?.find((a: any) => a.screenName === 'Qualification');
  const roleRoute = Setting?.screens?.find((a: any) => a?.screenName === 'Roles');
  const userRoute = Setting?.screens?.find((a: any) => a.screenName === 'Users');
  const billingAddRoute = BillingScr?.screens?.find((a: any) => a.screenName === 'Create Billing');
  const billingListRoute = BillingScr?.screens?.find((a: any) => a.screenName === 'View Billing');
  const clientAddRoute = ClinetScr?.screens?.find((a: any) => a.screenName === 'Add Client');
  const clientListRoute = ClinetScr?.screens?.find((a: any) => a.screenName === 'View Client');
  const clientAprvRoute = ClinetScr?.screens?.find((a: any) => a.screenName === 'Approve Client');
  const billingAprveRoute = BillingScr?.screens?.find(
    (a: any) => a.screenName === 'Approve Billing',
  );
  const cvBankRoute = menuList
    ?.find((e: any) => e.moduleName === 'CV Bank')
    ?.screens.find((a: any) => a.screenName === 'CV Bank');
  const interviewListRoute = menuList
    ?.find((e: any) => e.moduleName === 'Interview')
    ?.screens.find((a: any) => a.screenName === 'View Interview Schedule');
  const mailBoxRoute = menuList
    ?.find((e: any) => e.moduleName === 'Mail BOX')
    ?.screens.find((a: any) => a.screenName === 'Sent Items');
  const eventsAddRoute = menuList
    ?.find((e: any) => e.moduleName === 'My Events')
    ?.screens.find((a: any) => a.screenName === 'Add Event');
  const eventsListRoute = menuList
    ?.find((e: any) => e.moduleName === 'My Events')
    ?.screens.find((a: any) => a.screenName === 'View Event');
  const myLeaveListRoute = menuList
    ?.find((e: any) => e.moduleName === 'My Leaves')
    ?.screens.find((a: any) => a.screenName === 'View Leave');
  const myLeaveAprvRoute = menuList
    ?.find((e: any) => e.moduleName === 'My Leaves')
    ?.screens.find((a: any) => a.screenName === 'Approve Leave');
  const positionAddRoute = menuList
    ?.find((e: any) => e.moduleName === 'Position')
    ?.screens.find((a: any) => a.screenName === 'Add Position');
  const positionListRoute = menuList
    ?.find((e: any) => e.moduleName === 'Position')
    ?.screens.find((a: any) => a.screenName === 'View Position');
  const positionAprvRoute = menuList
    ?.find((e: any) => e.moduleName === 'Position')
    ?.screens.find((a: any) => a.screenName === 'Approve Position');
  const resumeAddRoute = menuList
    ?.find((e: any) => e.moduleName === 'Resume')
    ?.screens.find((a: any) => a.screenName === 'Add Resume');
  const resumeListRoute = menuList
    ?.find((e: any) => e.moduleName === 'Resume')
    ?.screens.find((a: any) => a.screenName === 'View Resume');
  const paymentMailRoute = menuList
    ?.find((e: any) => e.moduleName === 'Payment Mail')
    ?.screens.find((a: any) => a.screenName === 'View Payment Mail');
  const clientAgreementRoute = menuList
    ?.find((e: any) => e.moduleName === 'Client Agreement')
    ?.screens.find((a: any) => a.screenName === 'View Client Agreement');

  const {
    SETTINGS: {
      FUNCTIONAL_AREA,
      BRANCHES,
      QUALIFICATION,
      Designation: DzgonRoute,
      DailyLoginDetails: DailyLoginRoute,
      ROLE,
      USERS,
      PRODUCTADMINISTRATOR,
      EMAILTEMPLATE,
      LEAVE,
      INDUSTRY,
      CTC,
    },
    POSITIONS: { POSITIONSADD, POSITIONSAPPROVE, POSITIONSVIEW, VIEWPOSITIONS, CRMAPPROVAL },
    VERSIONHISTORY: {
      VERSIONHISTORYLIST,
      VERSIONHISTORYADD,
      VERSIONHISTORYEDIT,
      VERSIONHISTORYVIEW,
    },
    RESUMES: {
      RESUMESLIST: RESUMELIST,
      // RESUMESADD: RESUMEADD,
      RESUMESVIEW: RESUMEVIEW,
      RESUMESEDIT: RESUMEEDIT,
    },
    INTERVIEW: { INTERVIEWLIST, INTERVIEWVIEW },
    MAILBOX: { MAILBOXLIST, MAILBOXVIEW },
    PAYMENTMAIL: { PAYMENTMAILLIST, PAYMENTMAILVIEW },
    CLIENTAGREEMENT: { CLIENTAGREEMENTLIST, CLIENTAGREEMENTVIEW },
    BILLING: { BILLINGLIST, BILLINGADD, BILLINGVIEW, BILLINGAPPROVE, BILLINGEDIT },
    MyPlan: { MyPlanVIEW, MyPlanADD },
    MyLeave: { ApproveDetails, ViewList },
    REPORTS: { OPENINGHANDLE, CVSTATUS, TAKTIME, PRODUCTIVITY, BUSINESSCONVERSION },
    CLIENTS: { CLIENTADD, CLIENTVIEW, CLIENTAPPROVE: CLIENTAPRUV, CLIENTVIEWDETAILS },
    PROFILE,
    CHANGEPASSWOD,
    DASHBOARD,
    MyEvents: { EventsAdd, EventsEdit, EventsView, EventsList },
    CvBank: { CvBankDetails },
  } = AdminPrivateRoutesDirectives;
  return (
    <Routes>
      <Route path='/' element={<Navigate to={DASHBOARD} replace />} />
      <Route
        path={DASHBOARD}
        element={
          <PrivateLayout path={DASHBOARD}>
            {/* {dashBoard ? <Dashboard /> : <DummyDashboard />} */}
            <Dashboard />
          </PrivateLayout>
        }
      />
      <Route
        path={PROFILE}
        element={
          <PrivateLayout path={PROFILE}>
            <Myprofile />
          </PrivateLayout>
        }
      />
      <Route
        path={CHANGEPASSWOD}
        element={
          <PrivateLayout path={CHANGEPASSWOD}>
            <ChangePassword />
          </PrivateLayout>
        }
      />
      <Route
        path={MyPlanVIEW}
        element={
          <PrivateLayout path={MyPlanVIEW}>
            <MyPlan />
          </PrivateLayout>
        }
      />
      <Route
        path={MyPlanADD}
        element={
          <PrivateLayout path={MyPlanADD}>
            <AddMyplan />
          </PrivateLayout>
        }
      />
      {positionListRoute && (
        <Route
          path={VIEWPOSITIONS}
          element={
            <PrivateLayout path={VIEWPOSITIONS}>
              <ViewPositions />
            </PrivateLayout>
          }
        />
      )}
      {/* <Route
        path={VIEWPOSITIONS}
        element={
          <PrivateLayout path={VIEWPOSITIONS}>
            <ViewPositions />
          </PrivateLayout>
        }
      /> */}
      {positionListRoute && (
        <Route
          path={POSITIONSVIEW}
          element={
            <PrivateLayout path={POSITIONSVIEW}>
              <Positions />
            </PrivateLayout>
          }
        />
      )}
      {positionAddRoute && (
        <Route
          path={POSITIONSADD}
          element={
            <PrivateLayout path={POSITIONSADD}>
              <AddPositions />
            </PrivateLayout>
          }
        />
      )}
      {positionAprvRoute && (
        <Route
          path={POSITIONSAPPROVE}
          element={
            <PrivateLayout path={POSITIONSAPPROVE}>
              <APPROVEPOSITION />
            </PrivateLayout>
          }
        />
      )}
      <Route
        path={CRMAPPROVAL}
        element={
          <PrivateLayout path={CRMAPPROVAL}>
            <CrmChangeApproval />
          </PrivateLayout>
        }
      />
      {paymentMailRoute && (
        <Route
          path={PAYMENTMAILLIST}
          element={
            <PrivateLayout path={PAYMENTMAILLIST}>
              <PaymentMail />
            </PrivateLayout>
          }
        />
      )}
      {paymentMailRoute && (
        <Route
          path={PAYMENTMAILVIEW}
          element={
            <PrivateLayout path={PAYMENTMAILVIEW}>
              <PaymentMailView />
            </PrivateLayout>
          }
        />
      )}
      {clientAgreementRoute && (
        <Route
          path={CLIENTAGREEMENTLIST}
          element={
            <PrivateLayout path={CLIENTAGREEMENTLIST}>
              <ClientAgreement />
            </PrivateLayout>
          }
        />
      )}
      {clientAgreementRoute && (
        <Route
          path={CLIENTAGREEMENTVIEW}
          element={
            <PrivateLayout path={CLIENTAGREEMENTVIEW}>
              <ClientAgreementView />
            </PrivateLayout>
          }
        />
      )}
      <Route
        path={VERSIONHISTORYLIST}
        element={
          <PrivateLayout path={VERSIONHISTORYLIST}>
            <VersionHistoryList />
          </PrivateLayout>
        }
      />
      <Route
        path={VERSIONHISTORYADD}
        element={
          <PrivateLayout path={VERSIONHISTORYADD}>
            <VersionHistoryAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${VERSIONHISTORYEDIT}/:editId`}
        element={
          <PrivateLayout path={VERSIONHISTORYEDIT}>
            <VersionHistoryAdd />
          </PrivateLayout>
        }
      />
      <Route
        path={`${VERSIONHISTORYVIEW}/:editId`}
        element={
          <PrivateLayout path={VERSIONHISTORYVIEW}>
            <VersionHistoryView />
          </PrivateLayout>
        }
      />
      {resumeListRoute && (
        <Route
          path={RESUMELIST}
          element={
            <PrivateLayout path={RESUMELIST}>
              <ResumeList />
            </PrivateLayout>
          }
        />
      )}
      {resumeListRoute && (
        <Route
          path={RESUMEVIEW}
          element={
            <PrivateLayout path={RESUMEVIEW}>
              <ResumeView />
            </PrivateLayout>
          }
        />
      )}
      {/* {resumeAddRoute && (
        <Route
          path={RESUMEADD}
          element={
            <PrivateLayout path={RESUMEADD}>
              <AddResumes />
            </PrivateLayout>
          }
        />
      )} */}
      {resumeAddRoute && (
        <Route
          path={RESUMEEDIT}
          element={
            <PrivateLayout path={RESUMEEDIT}>
              <AddResumes />
            </PrivateLayout>
          }
        />
      )}
      {interviewListRoute && (
        <Route
          path={INTERVIEWLIST}
          element={
            <PrivateLayout path={INTERVIEWLIST}>
              <InterviewList />
            </PrivateLayout>
          }
        />
      )}
      {interviewListRoute && (
        <Route
          path={INTERVIEWVIEW}
          element={
            <PrivateLayout path={INTERVIEWVIEW}>
              <InterviewView />
            </PrivateLayout>
          }
        />
      )}
      {mailBoxRoute && (
        <Route
          path={MAILBOXLIST}
          element={
            <PrivateLayout path={MAILBOXLIST}>
              <MailboxList />
            </PrivateLayout>
          }
        />
      )}
      {mailBoxRoute && (
        <Route
          path={MAILBOXVIEW}
          element={
            <PrivateLayout path={MAILBOXVIEW}>
              <MailboxView />
            </PrivateLayout>
          }
        />
      )}
      {billingListRoute && (
        <Route
          path={BILLINGLIST}
          element={
            <PrivateLayout path={BILLINGLIST}>
              <Billing />
            </PrivateLayout>
          }
        />
      )}
      {billingAddRoute && (
        <Route
          path={BILLINGADD}
          element={
            <PrivateLayout path={BILLINGADD}>
              <BillingAdd />
            </PrivateLayout>
          }
        />
      )}
      {billingAprveRoute && (
        <Route
          path={BILLINGEDIT}
          element={
            <PrivateLayout path={BILLINGEDIT}>
              <BillingAdd />
            </PrivateLayout>
          }
        />
      )}
      {billingListRoute && (
        <Route
          path={BILLINGVIEW}
          element={
            <PrivateLayout path={BILLINGVIEW}>
              <BillingView />
            </PrivateLayout>
          }
        />
      )}
      {billingAprveRoute && (
        <Route
          path={BILLINGAPPROVE}
          element={
            <PrivateLayout path={BILLINGAPPROVE}>
              <BillingApprove />
            </PrivateLayout>
          }
        />
      )}
      {eventsAddRoute && (
        <Route
          path={EventsAdd}
          element={
            <PrivateLayout path={EventsAdd}>
              <AddEvents />
            </PrivateLayout>
          }
        />
      )}
      {eventsAddRoute && (
        <Route
          path={`${EventsEdit}/:editId`}
          element={
            <PrivateLayout path={EventsEdit}>
              <AddEvents />
            </PrivateLayout>
          }
        />
      )}
      {eventsListRoute && (
        <Route
          path={EventsView}
          element={
            <PrivateLayout path={EventsView}>
              <ViewEvents />
            </PrivateLayout>
          }
        />
      )}
      {eventsListRoute && (
        <Route
          path={EventsList}
          element={
            <PrivateLayout path={EventsList}>
              <MyEvents />
            </PrivateLayout>
          }
        />
      )}
      {cvBankRoute && (
        <Route
          path={CvBankDetails}
          element={
            <PrivateLayout path={CvBankDetails}>
              <CvBank />
            </PrivateLayout>
          }
        />
      )}
      {clientListRoute && (
        <Route
          path={CLIENTVIEW}
          element={
            <PrivateLayout path={CLIENTVIEW}>
              <Clients />
            </PrivateLayout>
          }
        />
      )}
      {clientAddRoute && (
        <Route
          path={CLIENTADD}
          element={
            <PrivateLayout path={CLIENTADD}>
              <ClientsAdd />
            </PrivateLayout>
          }
        />
      )}
      {clientAprvRoute && (
        <Route
          path={CLIENTAPRUV}
          element={
            <PrivateLayout path={CLIENTAPRUV}>
              <CLIENTAPPROVE />
            </PrivateLayout>
          }
        />
      )}
      {clientListRoute && (
        <Route
          path={CLIENTVIEWDETAILS}
          element={
            <PrivateLayout path={CLIENTVIEWDETAILS}>
              <ClientViewDetails />
            </PrivateLayout>
          }
        />
      )}
      {functionalAreaRoute && (
        <Route
          path={FUNCTIONAL_AREA.FUNCTIONALAREALIST}
          element={
            <PrivateLayout path={FUNCTIONAL_AREA.FUNCTIONALAREALIST}>
              <FunctionalArea />
            </PrivateLayout>
          }
        />
      )}
      {functionalAreaRoute && (
        <Route
          path={FUNCTIONAL_AREA.FUNCTIONALAREAADD}
          element={
            <PrivateLayout path={FUNCTIONAL_AREA.FUNCTIONALAREAADD}>
              <FunctionalAreaAdd />
            </PrivateLayout>
          }
        />
      )}
      {functionalAreaRoute && (
        <Route
          path={FUNCTIONAL_AREA.FUNCTIONALAREAEDIT}
          element={
            <PrivateLayout path={FUNCTIONAL_AREA.FUNCTIONALAREAEDIT}>
              <FunctionalAreaAdd />
            </PrivateLayout>
          }
        />
      )}
      {desigantionRoute && (
        <Route
          path={DzgonRoute.DesignationLIST}
          element={
            <PrivateLayout path={DzgonRoute.DesignationLIST}>
              <Designation />
            </PrivateLayout>
          }
        />
      )}
      {desigantionRoute && (
        <Route
          path={DzgonRoute.DesignationADD}
          element={
            <PrivateLayout path={DzgonRoute.DesignationADD}>
              <DesignationAdd />
            </PrivateLayout>
          }
        />
      )}
      {desigantionRoute && (
        <Route
          path={DzgonRoute.DesignationEDIT}
          element={
            <PrivateLayout path={DzgonRoute.DesignationEDIT}>
              <DesignationAdd />
            </PrivateLayout>
          }
        />
      )}
      {branchRoute && (
        <Route
          path={BRANCHES.BRANCHESLIST}
          element={
            <PrivateLayout path={BRANCHES.BRANCHESLIST}>
              <Branches />
            </PrivateLayout>
          }
        />
      )}
      {branchRoute && (
        <Route
          path={BRANCHES.BRANCHESADD}
          element={
            <PrivateLayout path={BRANCHES.BRANCHESADD}>
              <BranchesAdd />
            </PrivateLayout>
          }
        />
      )}
      {branchRoute && (
        <Route
          path={`${BRANCHES.BRANCHESEDIT}/:editId`}
          element={
            <PrivateLayout path={BRANCHES.BRANCHESEDIT}>
              <BranchesAdd />
            </PrivateLayout>
          }
        />
      )}
      {qualificationRoute && (
        <Route
          path={QUALIFICATION.QUALIFICATIONLIST}
          element={
            <PrivateLayout path={QUALIFICATION.QUALIFICATIONLIST}>
              <Qualification />
            </PrivateLayout>
          }
        />
      )}
      {qualificationRoute && (
        <Route
          path={QUALIFICATION.QUALIFICATIONADD}
          element={
            <PrivateLayout path={QUALIFICATION.QUALIFICATIONADD}>
              <QualificationAdd />
            </PrivateLayout>
          }
        />
      )}
      {qualificationRoute && (
        <Route
          path={`${QUALIFICATION.QUALIFICATIONEDIT}/:editId`}
          element={
            <PrivateLayout path={QUALIFICATION.QUALIFICATIONEDIT}>
              <QualificationAdd />
            </PrivateLayout>
          }
        />
      )}
      {userRoute && (
        <Route
          path={USERS.USERSLIST}
          element={
            <PrivateLayout path={USERS.USERSLIST}>
              <Users />
            </PrivateLayout>
          }
        />
      )}
      {userRoute && (
        <Route
          path={USERS.USERSADD}
          element={
            <PrivateLayout path={USERS.USERSADD}>
              <UsersAdd />
            </PrivateLayout>
          }
        />
      )}
      {userRoute && (
        <Route
          path={`${USERS.USERSEDIT}/:editId`}
          element={
            <PrivateLayout path={USERS.USERSEDIT}>
              <UsersAdd />
            </PrivateLayout>
          }
        />
      )}
      {holidayRoute && (
        <Route
          path={LEAVE.HOLIDAY.HOLIDAYLIST}
          element={
            <PrivateLayout path={LEAVE.HOLIDAY.HOLIDAYLIST}>
              <HolidayList />
            </PrivateLayout>
          }
        />
      )}
      {holidayRoute && (
        <Route
          path={LEAVE.HOLIDAY.HOLIDAYADD}
          element={
            <PrivateLayout path={LEAVE.HOLIDAY.HOLIDAYADD}>
              <HolidayAdd />
            </PrivateLayout>
          }
        />
      )}
      {holidayRoute && (
        <Route
          path={`${LEAVE.HOLIDAY.HOLIDAYEDIT}/:editId`}
          element={
            <PrivateLayout path={LEAVE.HOLIDAY.HOLIDAYEDIT}>
              <HolidayAdd />
            </PrivateLayout>
          }
        />
      )}
      {leavePolicyRoute && (
        <Route
          path={LEAVE.LEAVEPOLICY.LIST}
          element={
            <PrivateLayout path={LEAVE.LEAVEPOLICY.LIST}>
              <LeavePolicy />
            </PrivateLayout>
          }
        />
      )}
      {leavePolicyRoute && (
        <Route
          path={LEAVE.LEAVEPOLICY.ADD}
          element={
            <PrivateLayout path={LEAVE.LEAVEPOLICY.ADD}>
              <AddLeavePolicy />
            </PrivateLayout>
          }
        />
      )}
      {leavePolicyRoute && (
        <Route
          path={`${LEAVE.LEAVEPOLICY.EDIT}/:editId`}
          element={
            <PrivateLayout path={LEAVE.LEAVEPOLICY.EDIT}>
              <AddLeavePolicy />
            </PrivateLayout>
          }
        />
      )}
      {importLeaveRoute && (
        <Route
          path={LEAVE.IMPORTLEAVE.IMPORTLEAVELIST}
          element={
            <PrivateLayout path={LEAVE.IMPORTLEAVE.IMPORTLEAVELIST}>
              <ImportLeaveList />
            </PrivateLayout>
          }
        />
      )}
      {industryRoute && (
        <Route
          path={INDUSTRY.INDUSTRYLIST}
          element={
            <PrivateLayout path={INDUSTRY.INDUSTRYLIST}>
              <IndustryList />
            </PrivateLayout>
          }
        />
      )}
      {industryRoute && (
        <Route
          path={INDUSTRY.INDUSTRYADD}
          element={
            <PrivateLayout path={INDUSTRY.INDUSTRYADD}>
              <IndustryAdd />
            </PrivateLayout>
          }
        />
      )}
      {industryRoute && (
        <Route
          path={`${INDUSTRY.INDUSTRYEDIT}/:editId`}
          element={
            <PrivateLayout path={INDUSTRY.INDUSTRYEDIT}>
              <IndustryAdd />
            </PrivateLayout>
          }
        />
      )}
      {roleRoute && (
        <Route
          path={ROLE.ROLELIST}
          element={
            <PrivateLayout path={ROLE.ROLELIST}>
              <Roles />
            </PrivateLayout>
          }
        />
      )}
      {dailyLoginRoute && (
        <Route
          path={DailyLoginRoute}
          element={
            <PrivateLayout path={DailyLoginRoute}>
              <DailyLoginDetails />
            </PrivateLayout>
          }
        />
      )}
      {emailTempRoute && (
        <Route
          path={EMAILTEMPLATE.EMAILTEMPLATELIST}
          element={
            <PrivateLayout path={EMAILTEMPLATE.EMAILTEMPLATELIST}>
              <EmailTemplate />
            </PrivateLayout>
          }
        />
      )}
      {roleRoute && (
        <Route
          path={ROLE.ROLEADD}
          element={
            <PrivateLayout path={ROLE.ROLEADD}>
              <RoleAdd />
            </PrivateLayout>
          }
        />
      )}
      {roleRoute && (
        <Route
          path={`${ROLE.ROLEEDIT}/:editId`}
          element={
            <PrivateLayout path={ROLE.ROLEEDIT}>
              <RoleAdd />
            </PrivateLayout>
          }
        />
      )}
      {productAdminRoute && (
        <Route
          path={PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORLIST}
          element={
            <PrivateLayout path={PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORLIST}>
              <ProductAdministrator />
            </PrivateLayout>
          }
        />
      )}
      {productAdminRoute && (
        <Route
          path={PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORADD}
          element={
            <PrivateLayout path={PRODUCTADMINISTRATOR.PRODUCTADMINISTRATORADD}>
              <ProductAdministratorAdd />
            </PrivateLayout>
          }
        />
      )}
      {productAdminRoute && (
        <Route
          path={`${PRODUCTADMINISTRATOR.PRODUCTADMINISTRATOREDIT}/:editId`}
          element={
            <PrivateLayout path={PRODUCTADMINISTRATOR.PRODUCTADMINISTRATOREDIT}>
              <ProductAdministratorAdd />
            </PrivateLayout>
          }
        />
      )}
      {CTCSlab && (
        <Route
          path={CTC.CTCVIEW}
          element={
            <PrivateLayout path={CTC.CTCVIEW}>
              <CtcSlab />
            </PrivateLayout>
          }
        />
      )}
      {CTCSlab && (
        <Route
          path={CTC.CTCADD}
          element={
            <PrivateLayout path={CTC.CTCADD}>
              <CtcSlabAdd />
            </PrivateLayout>
          }
        />
      )}
      {CTCSlab && (
        <Route
          path={`${CTC.CTCEDIT}/:editId`}
          element={
            <PrivateLayout path={CTC.CTCEDIT}>
              <CtcSlabAdd />
            </PrivateLayout>
          }
        />
      )}
      {myLeaveAprvRoute && (
        <Route
          path={ApproveDetails}
          element={
            <PrivateLayout path={ApproveDetails}>
              <AppoveLeaveDetails />
            </PrivateLayout>
          }
        />
      )}
      {myLeaveListRoute && (
        <Route
          path={ViewList}
          element={
            <PrivateLayout path={ViewList}>
              <ViewLeaveList />
            </PrivateLayout>
          }
        />
      )}
      <Route
        path={OPENINGHANDLE}
        element={
          <PrivateLayout path={OPENINGHANDLE}>
            <OpeningHandle />
          </PrivateLayout>
        }
      />
      <Route
        path={CVSTATUS}
        element={
          <PrivateLayout path={CVSTATUS}>
            <CvStatus />
          </PrivateLayout>
        }
      />
      <Route
        path={TAKTIME}
        element={
          <PrivateLayout path={TAKTIME}>
            <TakTime />
          </PrivateLayout>
        }
      />
      <Route
        path={BUSINESSCONVERSION}
        element={
          <PrivateLayout path={BUSINESSCONVERSION}>
            <BusinessConversion />
          </PrivateLayout>
        }
      />
      <Route
        path={PRODUCTIVITY}
        element={
          <PrivateLayout path={PRODUCTIVITY}>
            <Productivity />
          </PrivateLayout>
        }
      />
      <Route path='*' element={<Navigate to='/' />} />
    </Routes>
  );
};

export default AdminPrivateRoutes;
