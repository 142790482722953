import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  LabelList,
} from 'recharts';
import Paginate from 'components/Pagination/Pagination';

const CustomLegend = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
      {/* Legend Items */}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
          marginRight: 20,
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#357AF6',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>CV Sent to Shortlisted (%)</span>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
          marginRight: 20,
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#0af0e4',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>CV Sent to Interviewed (%)</span>
      </div>

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          backgroundColor: '#F2F2F7',
          borderRadius: '5px',
          padding: '10px',
          marginRight: 20,
        }}>
        <div
          style={{
            width: 12,
            height: 12,
            backgroundColor: '#673147',
            marginRight: 5,
            borderRadius: '20%',
          }}
        />
        <span style={{ color: '#000', fontWeight: 'bold' }}>Openings Work to Billed (%)</span>
      </div>
    </div>
  );
};

const StackedBarChart = ({ data, graphTotal, setParams, type }: any) => {
  function handlePag(sk: number, tk: number) {
    setParams((prev: any) => ({
      ...prev,
      graphSkip: sk,
      graphTake: tk,
    }));
  }
  return (
    <>
      <div style={{ margin: '0 auto' }}>
        <ResponsiveContainer width='100%' height={360}>
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 20,
              left: 10,
              bottom: 30,
            }}
            barCategoryGap='5%' // Reduces the gap between the bar groups
          >
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='month' />
            <YAxis
              label={{
                value: '% against CVs Sent',
                angle: -90,
                position: 'insideLeft',
                style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
              }}
              domain={[0, (dataMax: number) => Math.ceil(dataMax / 100) * 100]}
              interval={0} // Force all ticks to be displayed
              tickFormatter={(value) => value}
            />
            <Tooltip />
            <Bar
              dataKey='cvSentShortlistedPercentage'
              stackId='a'
              fill='#357AF6'
              barSize={30}
              name='CV Sent to Shortlisted (%)'>
              <LabelList
                dataKey='cvSentShortlistedPercentage'
                position='center'
                style={{ fill: '#fff', fontWeight: 'bold' }}
              />
            </Bar>
            <Bar
              dataKey='cvSentInterviewedPercentage'
              stackId='a'
              fill='#0af0e4'
              barSize={30}
              name='CV Sent to Interviewed (%)'>
              <LabelList
                dataKey='cvSentInterviewedPercentage'
                position='center'
                style={{ fill: '#fff', fontWeight: 'bold' }}
              />
            </Bar>
            <Bar
              dataKey='openingWorkedToBilledPercentage'
              stackId='a'
              fill='#673147'
              barSize={30}
              name='Openings Work to Billed (%)'>
              <LabelList
                dataKey='openingWorkedToBilledPercentage'
                position='center'
                style={{ fill: '#fff', fontWeight: 'bold' }}
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div>
        <CustomLegend /> {/* Custom legend */}
        {type === 'CvStatus' ? (
          ''
        ) : (
          <Paginate total={graphTotal} DefaultPerPage={10} report={true} setfn={handlePag} />
        )}
      </div>
    </>
  );
};

export default StackedBarChart;
