import React, { useState } from 'react';
import { DateRangePicker } from '../DateRangePicker';
import dayjs, { Dayjs } from 'dayjs';
import CloseIcon from '@mui/icons-material/Close';
// import CancelIcon from '@mui/icons-material/Cancel';
import './customDateRangePicker.scss';
import moment from 'moment';
import { FormHelperText, IconButton, InputAdornment, TextField } from '@mui/material';

interface CustomDateRangePickerProps {
  onChange?: (dates: (Dayjs | null)[] | null, dateStrings: [string, string]) => void;
  placeholder?: string;
  definedRanges?: Array<{ label: string; startDate: Date; endDate: Date }>;
  value?: any;
  name?: string;
  error?: string;
}

const CustomDateRangePicker: React.FC<CustomDateRangePickerProps> = ({
  onChange,
  placeholder = 'From Date - To Date',
  value,
  name,
  error,
  definedRanges = [
    {
      label: 'Last 7 days',
      startDate: dayjs().subtract(6, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 14 days',
      startDate: dayjs().subtract(13, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 30 days',
      startDate: dayjs().subtract(29, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 3 months',
      startDate: dayjs().subtract(3, 'months').subtract(1, 'days').toDate(),
      endDate: new Date(),
    },
    {
      label: 'Last 12 months',
      startDate: dayjs().subtract(12, 'months').subtract(1, 'days').toDate(),
      endDate: new Date(),
    },
  ],
}) => {
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(0); // key to re-render DateRangePicker

  const handleRangeChange = (range: { startDate?: Date; endDate?: Date }) => {
    if (onChange && range.startDate && range.endDate) {
      onChange(
        [dayjs(range.startDate), dayjs(range.endDate)],
        [dayjs(range.startDate).format('YYYY-MM-DD'), dayjs(range.endDate).format('YYYY-MM-DD')],
      );
    }
    setOpen(false);
  };

  const toggle = () => setOpen(!open);

  const clearDateRange = () => {
    setKey((prevKey) => prevKey + 1); // Update the key to re-render DateRangePicker
    onChange && onChange([], ['', '']);
  };

  return (
    <div className='custom-date-picker-wrapper'>
      <div className='input-with-icon'>
        <TextField
          type='text'
          value={
            value && value?.length > 0
              ? `${moment(new Date(value[0])).format('YYYY-MM-DD')} - ${moment(
                  new Date(value[1]),
                ).format('YYYY-MM-DD')}`
              : []
          }
          name={name}
          onClick={toggle}
          className='custom-date-input1'
          placeholder={placeholder}
          InputProps={{
            endAdornment:
              value && value.length ? (
                <InputAdornment
                  position='end'
                  sx={{
                    marginLeft: 0,
                  }}>
                  <IconButton
                    sx={{
                      padding: '8px 8px 8px 0px',
                    }}
                    aria-label='toggle password visibility'
                    edge='end'
                    onClick={clearDateRange}>
                    <CloseIcon style={{ height: '20px', width: '20px' }} />
                  </IconButton>
                </InputAdornment>
              ) : null,
          }} // Spread additional inputProps here
        />
        {/* {value && value.length ? (
          <IconButton onClick={clearDateRange} size='small'>
            <CancelIcon />
          </IconButton>
        ) : null} */}
      </div>
      <DateRangePicker
        initialDateRange={value ? value : []}
        key={key} // Key to re-render the component
        open={open}
        toggle={toggle}
        onChange={handleRangeChange}
        definedRanges={definedRanges}
        maxDate={new Date()}
        wrapperClassName='custom-date-range-picker'
      />
      {error && (
        <FormHelperText
          style={{
            color: 'red',
            margin: '5px 0px 0px 0px',
            fontSize: '12px',
            fontFamily: 'Poppins-Medium',
            fontWeight: '400',
          }}>
          {error}
        </FormHelperText>
      )}
    </div>
  );
};

export default CustomDateRangePicker;
