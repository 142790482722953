import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  Legend,
  LabelList,
} from 'recharts';
import Paginate from 'components/Pagination/Pagination';

const CustomBarChartNew = ({ data, graphTotal, setParams }: any) => {
  // Color mapping for specific date ranges
  const rangeToColor: Record<string, string> = {
    'Sep-2024 to Nov-2024': '#357AF6', // Blue
    'Dec-2024 to Feb-2025': '#FF4C4C', // Red
  };

  // Pagination handler
  const handlePaginate = (skip: number, take: number) => {
    setParams((prev: any) => ({
      ...prev,
      graphSkip: skip,
      graphTake: take,
    }));
  };

  // const transformedData = [
  //   { corporateName: 'Testclient 1', 'Sep-2024 to Nov-2024': 4, 'Dec-2024 to Feb-2025': 7 },
  //   { corporateName: 'Testclient 2', 'Sep-2024 to Nov-2024': 5, 'Dec-2024 to Feb-2025': 7 },
  //   { corporateName: 'Testclient 3', 'Sep-2024 to Nov-2024': 10, 'Dec-2024 to Feb-2025': 1 },
  // ];

  // Extract all keys for Bar components, excluding "corporateName"
  const barKeys = Object.keys(data[0] || {}).filter((key) => key !== 'corporateName');

  return (
    <>
      <div style={{ width: '100%', height: '450px', margin: '0 auto' }}>
        <ResponsiveContainer width='100%' height='100%'>
          <BarChart
            layout='vertical'
            data={data}
            margin={{ top: 20, right: 30, left: 50, bottom: 50 }}
            barSize={22}>
            <CartesianGrid strokeDasharray='3 3' />
            <YAxis
              type='category'
              dataKey='corporateName'
              width={150}
              tick={{ fontSize: 12 }}
              label={{
                value: 'Company List',
                angle: -90,
                position: 'insideLeft',
                style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
              }}
            />
            <XAxis
              type='number'
              tick={{ fontSize: 12 }}
              label={{
                value: 'No. of Openings',
                position: 'insideBottom',
                dy: 20,
                style: { textAnchor: 'middle', fontWeight: 'bold', fill: '#333' },
              }}
            />
            <Tooltip formatter={(value: number) => `${value} Openings`} />
            <Legend wrapperStyle={{ bottom: 0 }} />
            {barKeys.map((key) => (
              <Bar
                key={key}
                dataKey={key}
                fill={rangeToColor[key] || `#${Math.floor(Math.random() * 16777215).toString(16)}`}
                stackId='a'>
                <LabelList dataKey={key} position='inside' fill='white' />{' '}
                {/* Set text color to white */}
              </Bar>
            ))}
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Pagination Component */}
      <Paginate total={graphTotal} DefaultPerPage={10} report={true} setfn={handlePaginate} />
    </>
  );
};

export default CustomBarChartNew;
