import { Tooltip, Typography } from '@mui/material';
import { getAllListData } from 'services/CommonServices';
import Photo from 'assets/png/photo.svg';

const PngIcon = ({ url, setLoader }: any) => {
  const exportPng = () => {
    setLoader(true);
    getAllListData(url, true)
      .then((res) => {
        res.forEach((file: any) => {
          const blob = new Blob([Uint8Array.from(file.buffer.data)], { type: 'image/png' });
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = file.fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          URL.revokeObjectURL(url);
        });
        setLoader(false);
      })
      .catch(() => setLoader(false));
  };
  return (
    <Typography onClick={exportPng} className='pointer' style={{ cursor: 'pointer' }}>
      <Tooltip title='Export to png'>
        <img src={Photo} alt={'Export to excel'} style={{ width: '30px', marginLeft: '20px' }} />
      </Tooltip>
    </Typography>
  );
};

export default PngIcon;
