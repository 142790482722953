import { Box, Typography, Grid, Button, Modal } from '@mui/material';
import React, { useCallback, useRef, useState } from 'react';
import CloseIcon from 'assets/png/close.svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccessDenied, fileTypewordPDF } from 'utils/helper';
import { resumeUploadSchema } from 'utils/ValidatorSchema';
import { useNavigate } from 'react-router-dom';
import CustomSelect from 'components/CustomSelect/customSelect';
import Loader from 'components/loader';
import TableTooltip from 'components/Tooltip/Tabletooltip';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import { addNewData, getAllListData, getSingleData } from 'services/CommonServices';
// import { alertOptions } from 'utils/Config';
import { ClientsENDPOINT, PositionENDPOINT, ResumesENDPOINT } from 'utils/Constant';
import { useAppSelector } from 'store/hooks';
import Fileupload from 'assets/png/upload-cloud.svg';
import Wordpng from 'assets/png/Word.png';
import Deletebucket from 'assets/png/Deletebucket.svg';
import _ from 'lodash';
import PDFpng from 'assets/png/pngimg.png';

const alertOptions: any = {
  zIndex: 9999,
  autoClose: 3000,
  closeOnClick: true,
  draggable: true,
  hideProgressBar: false,
  pauseOnHover: true,
  position: 'top-right',
  progress: undefined,
};
const AddResumeNew = ({
  setResumeUploadOpen,
  resumeUploadopen,
  reupload,
  setReupload,
  id,
  listdataapi,
  setResumeRowData,
}: any) => {
  const {
    menuList,
    userInfo: { organizationId: orgdy, id: userId },
  }: any = useAppSelector((store: any) => store.userLogin);
  const navigate = useNavigate();
  const [loader, setLoader] = useState<any>(false);
  const [fileUpload, setfileupload] = useState<any>([]);
  const hiddenFileInput: any = useRef(null);
  const [ClientData, setClientData] = useState<any>([]);
  const [positionData, setPositionData] = useState<any>([]);

  //   const [searchParams]: any = useSearchParams();
  const {
    control: control,
    handleSubmit: handleSubmit,
    // reset: resetForm,
    setValue,
    setError,
    // getValues,
    watch,
    formState: { errors },
  } = useForm<any>({
    criteriaMode: 'all',
    defaultValues: {
      fileUpload: '',
      positionId: '',
      clientId: '',
      id: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    resolver: yupResolver(resumeUploadSchema),
  });

  const onSubmitForm = (data: any) => {
    if (!fileUpload) {
      setError('fileUpload', {
        type: 'manual',
        message: 'File is required',
      });
    } else {
      setLoader(true);
      toast.success('Resume parsing is in progress', alertOptions);
      const formdata: any = new FormData();
      if (fileUpload) {
        fileUpload.map((res: any) => {
          return formdata.append('resumes', res);
        });
      }
      formdata.append('clientId', data.clientId);
      formdata.append('positionId', data.positionId);
      data.id ? formdata.append('id', data.id) : '';

      addNewData(
        formdata,
        ResumesENDPOINT.resumeUpload,
        {
          'Content-Type': 'multipart/form-data',
        },
        true,
      )
        .then(() => {
          setLoader(false);
          setResumeUploadOpen({ open: false, id: '' });
          listdataapi();
          handleClose();
        })
        .catch((e) => {
          setLoader(false);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            navigate('/dashboard');
          }
        });
    }
  };
  const onDrop = useCallback((acceptedFiles: any) => {
    onFileChange(acceptedFiles);
    // eslint-disable-next-line prettier/prettier, react-hooks/exhaustive-deps
  }, []);
  const onFileChange = (file: any) => {
    if (file[0] && file[0].size) {
      if (fileTypewordPDF.includes(file[0].type)) {
        let fileSize = 0;
        fileSize = Math.round(file[0].size / 1024);
        if (fileSize > 2048) {
          setError('fileUpload', {
            type: 'manual',
            message: 'File size should be less than 2MB',
          });
          return;
        }
      } else {
        setError('fileUpload', {
          type: 'manual',
          message: 'Invalid file type. Only DOCX and DOC are allowed.',
        });
        return;
      }
    }
    setError('fileUpload', {
      type: 'manual',
      message: '',
    });

    setfileupload((pre: any) => [...file, ...pre]);
    hiddenFileInput.current.value = '';
  };

  function handleRemove(ixn: number) {
    setfileupload((val: any) => val.filter((o: any, i: number) => ixn !== i));
  }
  const handledelete = () => {
    setfileupload('');
  };
  const handleClose = () => {
    setResumeRowData({});
    setfileupload('');
    setReupload(false);
    setResumeUploadOpen({ open: false, id: '' });
  };
  React.useEffect(() => {
    if (watch('clientId')) {
      getAllListData(
        `${PositionENDPOINT.position}?organizationId=${orgdy}&clientId=${watch('clientId')}&${
          menuList
            ?.find((e: any) => e.moduleName === 'My Plan')
            ?.screens?.find((a: any) => a.screenName === 'Create Plan')
            ? `taskUserId=${userId}`
            : `recruiterId=${userId}`
        }`,
      )
        .then((res: any) => {
          const options: any = [];
          res &&
            res.data.map((e: any) => {
              options.push({ label: e.title, value: e.id });
            });
          setPositionData(options);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [watch('clientId')]);
  React.useEffect(() => {
    getAllListData(
      `${ClientsENDPOINT.Client}?organizationId=${orgdy}&${
        menuList
          ?.find((e: any) => e.moduleName === 'My Plan')
          ?.screens?.find((a: any) => a.screenName === 'Create Plan')
          ? `taskUserId=${userId}`
          : `recruiterId=${userId}`
      }&approvalStatus=approved`,
    )
      .then((res: any) => {
        const options: any = [];
        res &&
          res.data.map((e: any) => {
            options.push({ label: e.corporateName, value: e.id });
          });
        setClientData(options);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const getInitialStatus = () => {
    if ((id && reupload) || resumeUploadopen.id) {
      setLoader(true);
      getSingleData(id || resumeUploadopen.id, ResumesENDPOINT.resumeList)
        .then((resp: any) => {
          if (resp && !_.isEmpty(resp.data)) {
            setValue('clientId', resp?.data[0]?.client?.id);
            setValue('positionId', resp?.data[0]?.position?.id);
            setValue('id', resp?.data[0]?.id);
            setfileupload([resp?.data[0]?.file]);
          }
          setLoader(false);
        })
        .catch((e: any) => {
          setLoader(false);
          if (e === 'Forbidden resource') {
            toast.error(AccessDenied);
            // navigate('/dashboard');
          }
        });
    }
  };
  React.useEffect(() => {
    getInitialStatus();
  }, []);

  return (
    <>
      <Modal
        sx={{ zIndex: 1300 }}
        open={resumeUploadopen}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'>
        <Box
          className='email-modal'
          sx={{
            width: '600px !important',
            minHeight: '500px !important',
          }}>
          {loader && <Loader style={{ height: '100%' }} />}
          <Typography component={'div'} className='d-flex flex-end '>
            <img src={CloseIcon} alt='close' style={{ cursor: 'pointer' }} onClick={handleClose} />
          </Typography>
          <Typography className='d-flex-cc'>
            <Typography className='l-title'>
              {reupload ? 'Re-Upload Resume' : 'Add Resume'}
            </Typography>
          </Typography>
          <div>
            <Grid container className='mt-10'>
              <Grid item md={12} sm={12} xs={12}>
                <Typography component={'div'} className='custom-field'>
                  <form
                    className='private-form'
                    id={'uploadResume'}
                    onSubmit={handleSubmit(onSubmitForm)}>
                    <Grid container columnSpacing={4.25}>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Client <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='clientId'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={ClientData}
                                  name={name}
                                  value={value}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.clientId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Position For <span>*</span>
                          </Typography>
                          <Controller
                            control={control}
                            defaultValue={''}
                            name='positionId'
                            render={({ field: { onChange, value, name } }) => {
                              return (
                                <CustomSelect
                                  labelKey={'label'}
                                  valueKey={'value'}
                                  options={positionData}
                                  name={name}
                                  value={value ? value : null}
                                  onChange={(e) => {
                                    onChange(e);
                                  }}
                                  placeHolder='Select'
                                  error={errors.positionId?.message}
                                />
                              );
                            }}
                          />
                        </Typography>
                      </Grid>
                      <Grid item md={12} sm={12} xs={12}>
                        <Typography component={'div'} className='custom-field'>
                          <Typography component={'p'}>
                            Upload files <span>*</span>
                          </Typography>
                          <Dropzone onDrop={onDrop}>
                            {({ getRootProps, getInputProps }) => {
                              return (
                                <div
                                  {...getRootProps({
                                    className: `dropzone container ${
                                      errors && errors?.fileUpload && errors?.fileUpload?.message
                                        ? 'container-position-error'
                                        : ''
                                    } container-position pointer`,
                                    onClick: () => {
                                      hiddenFileInput.current?.click();
                                    },
                                  })}>
                                  <div>
                                    <input {...getInputProps()} />
                                    <p className='img-container'>
                                      <img src={Fileupload} alt='file upload' />
                                    </p>
                                    <div className='fil-uplod-desc'>
                                      <p className='first-heading'>
                                        <span className='frst-span' role='button'>
                                          Click to upload
                                        </span>{' '}
                                        <span className='drago'>or drag and drop</span>
                                      </p>
                                      <p className='docx'>DOC, DOCX, PDF (up to 2MB)</p>
                                    </div>
                                  </div>
                                </div>
                              );
                            }}
                          </Dropzone>
                          {errors?.fileUpload && (
                            <Typography component={'span'} className='Custom-error'>
                              {errors.fileUpload.message?.toString()}
                            </Typography>
                          )}
                        </Typography>

                        {reupload ? (
                          <div>
                            {fileUpload && fileUpload.length > 0 ? (
                              <Grid item md={12} sm={12} xs={12} style={{ display: 'inherit' }}>
                                <Typography
                                  component={'div'}
                                  className='custom-field file-view-box'>
                                  <Typography
                                    component={'p'}
                                    className='first-children first-green-children'>
                                    <div className='image-container'>
                                      <span className='image image-success'>
                                        {fileUpload[0]?.type === 'application/pdf' ||
                                        fileUpload[0]?.mimetype === 'application/pdf' ? (
                                          <img
                                            src={PDFpng}
                                            alt='PDFpng'
                                            style={{ height: '20px', width: '20px' }}
                                          />
                                        ) : (
                                          <img src={Wordpng} alt='Wordpng' />
                                        )}
                                      </span>
                                      {/* <span className={success ? 'texted texted-success' : 'texted'}> */}
                                      <span className={'texted texted-success'}>
                                        <TableTooltip
                                          value={
                                            fileUpload && fileUpload[0]
                                              ? fileUpload[0]?.name
                                                ? fileUpload[0]?.name
                                                : fileUpload[0].fileName
                                                  ? fileUpload[0].fileName
                                                  : ''
                                              : ''
                                          }
                                          positionfile={true}></TableTooltip>
                                      </span>
                                    </div>
                                    <div
                                      className='Crossicon Crossicon-deletebucket'
                                      onClick={handledelete}>
                                      <img src={Deletebucket} alt='Crossicon' />
                                    </div>
                                  </Typography>
                                </Typography>
                              </Grid>
                            ) : (
                              ''
                            )}
                          </div>
                        ) : (
                          <Grid
                            container
                            columnSpacing={2}
                            style={{ overflowY: 'auto', maxHeight: '150px' }}>
                            {fileUpload && fileUpload.length > 0
                              ? fileUpload.map((re: any, index: number) => {
                                  return (
                                    <Grid item md={6} sm={12} xs={12} key={index}>
                                      <Typography
                                        component={'div'}
                                        className='custom-field file-view-box'>
                                        <Typography
                                          component={'p'}
                                          className='first-children first-green-children'>
                                          <div className='image-container'>
                                            <span className='image image-success'>
                                              {re?.type === 'application/pdf' ||
                                              re?.mimetype === 'application/pdf' ? (
                                                <img
                                                  src={PDFpng}
                                                  alt='PDFpng'
                                                  style={{ height: '20px', width: '20px' }}
                                                />
                                              ) : (
                                                <img src={Wordpng} alt='Wordpng' />
                                              )}
                                            </span>
                                            <span className={'texted texted-success'}>
                                              <TableTooltip
                                                value={
                                                  re
                                                    ? re?.name
                                                      ? re?.name
                                                      : re.fileName
                                                        ? re.fileName
                                                        : ''
                                                    : ''
                                                }
                                                positionfile={true}></TableTooltip>
                                            </span>
                                          </div>
                                          <div
                                            className='Crossicon Crossicon-deletebucket'
                                            onClick={() => handleRemove(index)}>
                                            <img src={Deletebucket} alt='Crossicon' />
                                          </div>
                                        </Typography>
                                      </Typography>
                                    </Grid>
                                  );
                                })
                              : ''}
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </form>
                </Typography>
              </Grid>
            </Grid>
            <Typography className='d-flex-cc mt-25'>
              <Button className='l-cancel-btn mr-16' onClick={handleClose}>
                Cancel
              </Button>
              <Button className='l-save-btn' type='submit' form={'uploadResume'}>
                Submit
              </Button>
            </Typography>
          </div>
        </Box>
      </Modal>
    </>
  );
};
export default AddResumeNew;
